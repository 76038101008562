<template>
    <div class="videos-categories-component">
      <div class="page-header">
        <div class="row align-items-center header">
          <div class="col-1">
            <div class="title">
              <router-link to="/admin/shows/episodes">Episodes </router-link>/
            </div>
            <!-- <div class="subtitle">
              Scenes are videos episodes that belongs to shows in the platform
            </div> -->
          </div>
          <div class="col-9 d-flex">
            <b-form-input
                size="sm"
                id="inline-form-input-query"
                class="flex-grow-1"
                placeholder="Search..."
                v-model="searchQuery"
              ></b-form-input>

              <b-form-select class="mx-2 search-selection" v-model="searchSelection" size="sm" :options="searchOptions"></b-form-select>

              <b-button @click.prevent="searchEpisodes" size="sm" variant="danger">Find</b-button>
          </div>
          <div class="col-2">
            <b-btn to="/admin/shows/episodes/new" variant="danger" >Create Episode</b-btn>
          </div>
        </div>
      </div>
  
      <div class="page-content">

        <div v-if="loading" class="text-center">
          <b-spinner type="grow" variant="danger" label="Spinning"></b-spinner>
        </div>

        <b-row v-else-if="!loading && episodes && episodes.length>0">

          <b-col v-for="episode of episodes" :key="episode._id" sm md="3">
            <!-- {{ scene }} -->
            <b-card
              :img-src="getEpisodeImage(episode.images.landscape)"
              img-alt="Image"
              img-top
              class="mb-3">
            <div class="card-heading" v-b-tooltip.hover :title="episode.title.en">
              {{getEpisodeTitle(episode.title.en)}}
            </div>

            <div class="d-flex flex-column card-text">
              <div>
                Id: {{ episode._id }}
              </div>
              <div>
                Show Id: {{ episode.show_id }}
              </div>
              <div>
                Published On: {{ episode.published_at }}
              </div>
              <div class="card-state" :class="episode.published? 'published' : 'unpublished'" >
                {{ episode.published? 'Published' : 'Not Published' }}
              </div>
              <div>
                Ads {{ episode.disable_ads? 'disabled' : 'enabled' }}
              </div>
            </div>
              


            <div class="d-flex justify-content-between mt-2">
              <b-btn variant="danger" @click="openEpisodeEdit(episode._id)" >Edit</b-btn>
              <b-btn variant="danger" @click="checkBeforeUnpublish(episode)">
                {{ episode.published ? 'Unpublish' : 'Publish' }}
              </b-btn>
              <b-btn variant="danger" @click="deleteUnpublishedEpisode(episode)">Delete</b-btn>
            </div>

            </b-card>
          </b-col>
        </b-row>

        <div v-else class="text-center">
          <h4>No Episodes, Create One!</h4>
        </div>

        <b-pagination v-if="pages > 1" align="right" size="md" :total-rows="count" v-model="current" :per-page="limit" @change="getMoreEpisodes"></b-pagination>
      </div>
    </div>
  </template>

<script>
import ShowValidation from '../Validation'

export default {
name:'ShowEpisodesAll',
async mounted(){
  this.getEpisodes()
},
data(){
  return{
    loading:false,
    searchSelection: 'title',
    searchOptions:[{text:'Title', value:'title'}, {text:'Show Id', value:'showId'} ],
    searchQuery:'',
    limit: 20,
    pages: 1,
    current: 1,
    count: 0,
    scenes: [],
    episodes: []
  }
},
methods:{
  async searchEpisodes(){
    this.loading=true
    const searchResponse = await this.$http.get(`/show-episodes/search?q=${this.searchQuery}&type=${this.searchSelection}`)
    console.log('sr', searchResponse);
    if(searchResponse.success){
      this.episodes = searchResponse.data.items
      this.count = searchResponse.data.count
      this.pages = Math.ceil(searchResponse.data.count / this.limit)
      this.loading=false
    }
  },
  openEpisodeEdit(episodeId){
    this.$router.push(`/admin/shows/episodes/edit/${episodeId}`)
  },
  async checkBeforeUnpublish(episode){
      const action = !episode.published
      // if being unpublished
      if(!action){
        const isRemoved = await this.$http.put(`/video-section/remove-resource/show-episode/${episode._id}`)
        if(!isRemoved.success){
              this.$notify({
                group: 'notifications',
                title: 'Cannot unpublish the episode',
                text: 'Failed to remove episode from video section',
                type: 'error'
              })
              return false
        }
      }
      
      this.togglePublish(episode)
    },
    togglePublish(episode){
      const url = `/show-episodes/${episode._id}/publish`
      const action = !episode.published

      // if being published
      if(action){
        const metadataIsValid = ShowValidation.validationForPublishedShowEpisodes(episode)

        if(!metadataIsValid){
          this.$notify({
            group: 'notifications',
            title: 'Incomplete Metadata',
            text: 'All information is required for a published episode.',
            type: 'error'
          })
          return
        }
      }

      this.$http.put(url, {published: !episode.published}).then(() => {
        episode.published = !episode.published
        this.$notify({
            group: 'notifications',
            title: 'Episode updated!',
            text: '',
            type: 'success'
          })
      })
    },
    async deleteUnpublishedEpisode(episode){
      if(episode.published){
        this.$notify({
            group: 'notifications',
            title: 'You must unpublish the show before deleting it',
            text: '',
            type: 'error'
          })
          return
      }
      const url = `/show-episodes/${episode._id}`
      const deleted= await this.$http.delete(url)
      if(deleted.success){
        this.$notify({
            group: 'notifications',
            title: 'episode deleted!',
            text: '',
            type: 'success'
          })
          this.current = 1
          this.getEpisodes()
      }
    },
  getEpisodeTitle(title){
    if(title.length>0){
      return title
    }
    return 'Untitled'
  },
  getEpisodeImage(imageUrl){
    if(imageUrl.length>0){
      return imageUrl
    }
    return 'https://placehold.co/1920x850'
  },
  getMoreEpisodes(page){
      this.current = page
      this.getEpisodes()
  },
  async getEpisodes(){
    const sceneRes = await this.$http.get('/show-episodes',{params: {limit: this.limit , page: this.current, sortBy: null}})
    console.log('sres', sceneRes);
    this.episodes = sceneRes.data.items
    this.count = sceneRes.data.count
    this.pages = Math.ceil(sceneRes.data.count / this.limit)
  }
}
}
</script>

<style scoped>
.search-selection{
  width: auto;
}
.card-heading{
  font-weight: 600;
  color: black;
  font-size: 16px;
  padding-bottom: 4px; 
}

.card-heading{
  max-width: 100%;
  width: 100%;
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.card-state.published{
  color: green;
  font-weight: bold;
}
.card-state.unpublished{
  color: red;
  font-weight: bold;
}
.card-text{
  color: grey;
  font-size: 12px;
}
</style>