<template>
  <h1>Oauth2 Test Page</h1>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'Oauth2',
  computed: {
    ...mapGetters('oauth2', ['redirectUrl'])
  },
  data () {
    return {
      vendor: null,
      code: null
    }
  },
  mounted () {
    this.vendor = this.$route.params.vendor || null
    this.code = this.$route.query.code || null

    this.updateVendorToken()
  },
  methods: {
    ...mapActions('oauth2', ['requestToken', 'updateAuthData']),
    async updateVendorToken () {
      try {
        const res = this.requestToken(this.code)

        if (res) {
          const redirectUrl = localStorage.getItem('OAUTH2_REDIRECT_URL')
          localStorage.removeItem('OAUTH2_REDIRECT_URL')
          await this.updateAuthData({
            needAuth: false,
            authMessage: null,
            alreadyChecked: true
          })
          this.$router.push(redirectUrl)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
