import Http from '@/components/share/http'

const httpClient = new Http()

export default {
  namespaced: true,
  state: {
    vendor: 'simplecast',
    needAuth: false,
    authMessage: null,
    alreadyChecked: false,
    redirectUrl: ''
  },
  actions: {
    openLoginDialog: async ({ getters, commit }, payload) => {
      const response = await httpClient.get(`/oauth2/${getters.vendor}`)

      if (!response.success) {
        console.log(response.message)
        return false
      }

      localStorage.setItem('OAUTH2_REDIRECT_URL', payload.redirectUrl)
      document.location.href = response.data.url
    },

    requestToken: async ({ getters }, payload) => {
      const response = await httpClient.post(`/oauth2/${getters.vendor}`, { code: payload })

      if (!response.success) {
        console.log(response.message)
        return false
      }

      return true
    },

    refreshToken: async ({ getters, dispatch }) => {
      if (!getters.isAlreadyChecked) {
        const response = await httpClient.post('/oauth2/refresh')
        let needAuth = false
        let authMessage = null

        if (!response.success) {
          needAuth = true
          authMessage = response.message
        }

        dispatch('updateAuthData', { needAuth, authMessage, alreadyChecked: true })
      }
    },

    updateAuthData: ({ commit }, payload) => {
      commit('setAlreadyChecked', payload.alreadyChecked)
      commit('setNeedAuth', payload.needAuth)
      commit('setAuthMessage', payload.authMessage)
    }
  },
  mutations: {
    setNeedAuth: (state, payload) => {
      state.needAuth = payload
    },
    setAuthMessage: (state, payload) => {
      state.authMessage = payload
    },
    setAlreadyChecked: (state, payload) => {
      state.alreadyChecked = payload
    },
    setRedirectUrl: (state, payload) => {
      state.redirectUrl = payload
    }
  },
  getters: {
    vendor: state => state.vendor,
    needAuth: state => state.needAuth,
    authMessage: state => state.authMessage,
    isAlreadyChecked: state => state.alreadyChecked,
    redirectUrl: state => state.redirectUrl,
  }
}
