class ShowValidations{
    /**
     * @param {object} showMetadata refers to show.meta object in the database
     */
    metadataValidationForPublishedShows(showMetadata){
        return (showMetadata.titleEnglish!==undefined && showMetadata.titleEnglish!=='') &&
        (showMetadata.descriptionEnglish!==undefined && showMetadata.descriptionSpanish!=='') &&
        (showMetadata.slug!==undefined && showMetadata.slug!=='')
        
    }

    slugIsUnique(showSlug, slugList){
        return slugList.indexOf(showSlug) === -1
    }
    
    /**
     * @param {object} showMetadata refers to show.meta object in the database
     */
    imageValidationForPublishedShows(showMetadata){
        return showMetadata.exploreImagery && showMetadata.exploreImagery['2xImageURL'] !=='' &&  showMetadata.exploreImagery['3xImageURL']  !=='' &&  showMetadata.exploreImagery['mdpiImageURL'] !=='' && showMetadata.exploreImagery['hdpiImageURL'] !=='' && showMetadata.exploreImagery['xhdpiImageURL'] !=='' && showMetadata.exploreImagery['xxhdpiImageURL'] !=='' && showMetadata.exploreImagery['xxxhdpiImageURL']!=='' &&

        showMetadata.detailViewImagery && showMetadata.detailViewImagery['2xImageURL'] !=='' && showMetadata.detailViewImagery ['3xImageURL']  !=='' &&  showMetadata.detailViewImagery['mdpiImageURL'] !=='' && showMetadata.detailViewImagery['hdpiImageURL'] !=='' && showMetadata.detailViewImagery['xhdpiImageURL'] !=='' && showMetadata.detailViewImagery['xxhdpiImageURL'] !=='' && showMetadata.detailViewImagery['xxxhdpiImageURL']!=='' &&

        showMetadata.cardImagery && showMetadata.cardImagery['2xImageURL']!=='' &&  showMetadata.cardImagery['3xImageURL']!=='' && 

        showMetadata.landing && showMetadata.landing['1xImageURL']!=='' && showMetadata.landing['2xImageURL']!=='' &&  showMetadata.landing['3xImageURL']!=='' &&

        showMetadata.portrait && showMetadata.portrait['1xImageURL']!=='' && showMetadata.portrait['2xImageURL']!=='' &&  showMetadata.portrait['3xImageURL']!==''
    }

    metadataValidationForUnpublishedShows(showMetadata){
        return (showMetadata.titleEnglish!==undefined && showMetadata.titleEnglish!=='') &&
        (showMetadata.slug!==undefined && showMetadata.slug!=='')
    }

    validationForPublishedShowEpisodes(episode){
        return (episode.title.en!==undefined && episode.title.es!=='') &&
        (episode.description.en!==undefined && episode.description.es!=='') &&
        (episode.images.square!=='' && episode.images.portrait!=='' && episode.images.landscape!=='') &&
        (episode.video.id!=='' && episode.video.url!=='') && (episode.duration>0)
        
    }
}

module.exports = new ShowValidations()