<template>
  <div>
    <label for="scn-image" class="scene-image">
        {{ options.hint }}
    </label>
    <b-form-file
      id="scn-image"
      v-model="file1"
      accept="image/jpeg, image/png"
      :state="Boolean(file1)"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
    ></b-form-file>

    <b-img v-if="options && options.src" :src="options.src"></b-img>
  </div>
</template>

<script>
export default {
    name: 'ShowEpisodeImageUploader',
    props:['options'],
    data() {
      return {
        file1: null
      }
    }
  }
</script>

<style scoped>
.scene-image{
    font-weight: 500;
}
</style>