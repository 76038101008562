<template>
  <div>
    <b-modal id="importShow" ref="importShow" ok-title="Select" size="lg" title="Remote Podcasts"
             @ok="emitSelected" @cancel="closeModal" @close="closeModal">
      <div class="modal-wrapper">
        <b-form-row>
          <b-col>
            <div class="layout-picker mt-2">
              <div class="cards-wrapper mt-2">
                <div class="search-wrapper">
                  <input type="text" placeholder="Search Remote Podcasts" v-model="searchText" @keyup.13="search"/>
                  <div class="btn btn-search-card" @click="search">
                    <i class="fas fa-search"></i>
                  </div>
                </div>
                <ul class="playlistUpdated-list" style="min-height: 400px">
                  <li v-for="item in podcasts"
                      v-bind:key="item._id"
                      v-bind:class="{selected: selected && selected.id === item.id}"
                      @click="selectItem(item)">
                    <div class="playlists-list-card-img">
                      <img :src="item.image_url" alt="" v-if="item.image_url" />
                      <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-else/>
                    </div>
                    <div class="playlist-info">
                      <div class="playlist-title">{{ item.title }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
        </b-form-row>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'PodcastsRemote',
  data: () => {
    return {
      searchText: '',
      podcasts: [],
      selected: null
    }
  },
  mounted () {
    this.openModal()
    this.search()
  },
  methods: {
    openModal () {
      this.$refs.importShow.show()
    },
    closeModal () {
      this.$refs.importShow.hide()
      this.$emit('close')
    },
    search () {
      this.$http.post('/podcasts/integrations/simplecast/search', { search: this.searchText })
          .then((res) => {
            this.podcasts = res.data.items
          })
          .catch((err) => {
            console.log(err)
          })
    },
    selectItem (item) {
      this.selected = item
    },

    emitSelected ($event) {
      if(!this.selected) {
        $event.preventDefault()
        return this.$notify({
          group: 'notifications',
          title: 'Error',
          text: 'You must select a podcast!!',
          type: 'error'
        })
      }

      this.$emit('selected', this.selected)
    }
  }
}
</script>
