<template>
  <div class="podcast-item-wrapper">
    <div class="item-header">
      <div class="row align-items-center">
        <div class="col-12 col-md-7">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 pl-0">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'podcast-all' }">Podcasts</router-link>
              </li>
              <li class="breadcrumb-item active">Create Show</li>
            </ol>
          </nav>
        </div>
        <div class="col-12 col-md-5 text-right actions">
          <button class="btn page-action" @click="createShow">
            <i class="fas fa-check"></i>
            Create
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-8 offset-2">
        <div class="mt-3">
          <div class="search-wrapper podcast-form">
            <label>Remote Show ID <span class="required">*</span></label>

            <b-input-group size="sm">
              <b-form-input placeholder="Select a podcast from Simplecast Platform" v-model="podcast.vendor.showId" :disabled="true"></b-form-input>
              <b-input-group-append>
                <button class="btn" @click="openRemoteModal">
                  Select
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <div class="mt-3" v-if="podcast.vendor.showId">
          <podcast-form :podcast="podcast"></podcast-form>
        </div>
      </div>
    </div>

    <podcasts-remote v-if="showRemoteModal" @close="closeRemoteModal" @selected="handleRemoteSelected($event)"></podcasts-remote>

  </div>
</template>
<script>
import PodcastForm from "@/components/admin/podcasts/PodcastForm.vue";
import PodcastsRemote from "@/components/admin/podcasts/PodcastsRemote.vue";

export default {
  name: 'PodcastNew',
  components: { PodcastForm, PodcastsRemote },
  data: () => {
    return {
      podcast: {
        title: '',
        published: false,
        publishDate: null,
        unPublishDate: null,
        meta: {
          updatedAt: null,
          createdAt: null,
          titleEnglish: '',
          titleSpanish: '',
          secondaryTitleEnglish: '',
          secondaryTitleSpanish: '',
          descriptionEnglish: '',
          descriptionSpanish: '',
          authors: '',
          slug: '',
          type: 'podcast',
          devices: [
            'ios',
            'android',
            'web',
            'appletv',
            'androidtv'
          ],
          order: '',
          hideTitle: true,
          isFeatured: false,
          isSponsored: false,
          podcastID: '',
          facebookShareImageLink: '',
          companionAdStationId: '',
          iOSVideoInterstitialAdUnitId: '',
          iOSImageInterstitialAdUnitId: '',
          androidVideoInterstitialAdUnitId: '',
          androidImageInterstitialAdUnitId: '',
          hasCompanionAdMidroll: false,
          hasCompanionAdPreroll: false,
          inclusionLocations: [],
          exploreImagery: {
            '2xImageURL': null,
            '3xImageURL': null,
            mdpiImageURL: null,
            hdpiImageURL: null,
            xhdpiImageURL: null,
            xxhdpiImageURL: null,
            xxxhdpiImageURL: null
          },
          detailViewImagery: {
            '2xImageURL': null,
            '3xImageURL': null,
            mdpiImageURL: null,
            hdpiImageURL: null,
            xhdpiImageURL: null,
            xxhdpiImageURL: null,
            xxxhdpiImageURL: null
          },
          cardImagery: {
            '2xImageURL': null,
            '3xImageURL': null
          }
        },
        vendor: {
          showId: null
        }
      },
      showRemoteModal: false
    }
  },
  methods: {
    openRemoteModal () {
      this.showRemoteModal = true
    },

    closeRemoteModal() {
      this.showRemoteModal = false
    },

    handleRemoteSelected(data) {
      this.podcast.vendor.showId = data.id
      this.podcast.meta.titleEnglish = data.title
      this.podcast.meta.titleSpanish = data.title
      this.podcast.meta.descriptionEnglish = data.description
      this.podcast.meta.descriptionSpanish = data.description
      this.podcast.meta.slug = data.slug
      this.closeRemoteModal()
    },

    createShow () {
      if (!this.podcast.vendor.showId) {
        this.$notify({
          group: 'notifications',
          title: 'Missing Remote Show ID :(',
          text: '',
          type: 'error'
        })
        return
      }

      if (this.podcast.meta.titleEnglish === '') {
        this.$notify({
          group: 'notifications',
          title: 'Missing podcast English Title :(',
          text: '',
          type: 'error'
        })
        return
      }

      if (this.podcast.meta.titleSpanish === '') {
        this.$notify({
          group: 'notifications',
          title: 'Missing podcast Spanish Title :(',
          text: '',
          type: 'error'
        })
        return
      }

      if (this.podcast.meta.descriptionEnglish === '') {
        this.$notify({
          group: 'notifications',
          title: 'Missing podcast English Description :(',
          text: '',
          type: 'error'
        })
        return
      }

      if (this.podcast.meta.descriptionSpanish === '') {
        this.$notify({
          group: 'notifications',
          title: 'Missing podcast Spanish Description :(',
          text: '',
          type: 'error'
        })
        return
      }

      if (this.podcast.meta.slug === '') {
        this.$notify({
          group: 'notifications',
          title: 'Missing podcast slug :(',
          text: '',
          type: 'error',
          duration: -1
        })
        return
      }

      this.$http.post('/podcasts', { podcast: this.podcast })
          .then((res) => {
            if (!res.success) {
              return this.$notify({
                group: 'notifications',
                title: res.message,
                text: '',
                type: 'error'
              })
            }

            this.$notify({
              group: 'notifications',
              title: 'Show created successfully :)',
              text: '',
              type: 'success'
            })

            this.$router.push({ name: 'podcast-edit', params: { id: res.data.id } })

          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$notify({
                group: 'notifications',
                title: `${err.response.data} :(`,
                text: '',
                type: 'error'
              })
            } else {
              console.log(err.response)
            }
          })
    },
  }
}
</script>
<style lang="scss">
.search-wrapper {
  button {
    font-weight: 600;

    &:hover {
      color: #fff;
    }
  }
}
</style>
