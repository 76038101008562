import { render, staticRenderFns } from "./MomentsForm.vue?vue&type=template&id=1ef1c713&scoped=true"
import script from "./MomentsForm.vue?vue&type=script&lang=js"
export * from "./MomentsForm.vue?vue&type=script&lang=js"
import style0 from "./MomentsForm.vue?vue&type=style&index=0&id=1ef1c713&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ef1c713",
  null
  
)

export default component.exports