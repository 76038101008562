<template>
  <div class="podcast-item-wrapper" v-if="podcast">
    <div class="item-header">
      <div class="row align-items-center">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 pl-0">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'podcast-all' }">Podcasts</router-link>
              </li>
              <li class="breadcrumb-item active">
                <router-link :to="{ name: 'podcast-edit', params: {id: podcast._id} }">{{ podcast.title }}
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Episodes</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <div class="row align-items-center mt-3">
      <div class="col-4">
        <div class="search-wrapper">
          <b-input-group size="sm">
            <b-form-input placeholder="Search episodes" v-model="searchData.query" @keyup.13="search"></b-form-input>
            <b-input-group-append>
              <button class="btn" @click="search">
                <i class="fas fa-search"></i>
              </button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <div class="col-8 text-right">
        <b-form-checkbox-group
            v-model="searchData.status"
            :options="statuses"
            value-field="code"
            text-field="label"
            disabled-field="notEnabled"
            switches
            @change="load"
        ></b-form-checkbox-group>
      </div>
    </div>

    <hr/>

    <div class="row mt-2 align-items-center" v-if="episodes && episodes.length > 0">
      <div class="col font-weight-bold">
        {{ pagination.from }} - {{ pagination.to }} / {{ pagination.total }}
      </div>
      <div class="col text-right">
        <div class="d-flex justify-content-end align-items-center">
          <div class="mr-1">
            <button class="btn btn-sm" :disabled="loading || pagination.pages.current === 1" @click="prev">
              <i class="fas fa-chevron-left"></i>
            </button>
          </div>
          <div>
            <span class="font-weight-bold"> {{ pagination.pages.current }} / {{ pagination.pages.total }}</span>
          </div>
          <div class="ml-1">
            <button class="btn btn-sm" :disabled="loading || pagination.pages.current >= pagination.pages.total"
                    @click="next">
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-12">
        <div class="loading-wrapper" v-if="loading">
          <LoaderInline/>
        </div>

        <ul class="list" v-if="episodes && episodes.length > 0">
          <li v-for="(episode, index) in episodes" v-bind:key="episode._id">
            <div class="episode-img" v-if="episode.image_url">
              <img :src="episode.image_url" alt=""/>
            </div>
            <div class="episode-img" v-else-if="podcast.remote && podcast.remote.image_url">
              <img :src="podcast.remote.image_url" alt=""/>
            </div>
            <div class="episode-controls-wrapper">
              <div class="icon-wrapper icon-wrapper-play" v-if="episode.audio_url">
                <i class="fas fa-pause" v-if="currentPlay[`${index}`] === true" @click="stopAudio()"></i>
                <i class="fas fa-play" v-else @click="playAudio(index)"></i>
              </div>
              <div class="player">
                <div class="episode-title">{{ episode.title }}</div>
                <div class="episode-date">
                  <strong>Duration:</strong> {{ secondsToMinutes(episode.duration) }}
                  <template v-if="episode.published_date">- <strong>Publish Date:</strong>
                    {{ formatEpisodeDate(episode.published_date) }}
                  </template>
                  <span v-if="episode.number">- <strong>Episode Number: </strong> {{ episode.number }}</span></div>
                <div class="episode-status">
                  <episode-status :status="episode.status"></episode-status>
                </div>
              </div>
              <div class="wavesurfer" :id="`wavesurfer_${index}`"
                   v-bind:class="{show: currentPlay[`${index}`] === true}"></div>
            </div>
          </li>
        </ul>
        <template v-if="episodes.length === 0 && !loading">
          <b-alert v-if="searchData.query || (searchData.status && searchData.status.length > 0)" show
                   class="text-center">Cannot find episodes matching
            with the search criteria.
          </b-alert>
          <b-alert v-else show class="text-center">There are no episodes added yet.</b-alert>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import WaveSurfer from 'wavesurfer.js'
import LoaderInline from "@/components/share/LoaderInline.vue";
import EpisodeStatus from './episode-status.vue'
import moment from "moment-timezone";

export default {
  name: 'PodcastEpisodes',
  components: { LoaderInline, EpisodeStatus },
  data: () => {
    return {
      podcast: null,
      episodes: [],
      pagination: {
        limit: 50,
        offset: 0,
        from: 0,
        to: 0,
        total: 0,
        pages: {
          total: 0,
          current: 1
        }
      },
      searchData: {
        query: null,
        status: ['published']
      },
      statuses: [
        { code: 'published', label: 'Published' },
        { code: 'scheduled', label: 'Scheduled' },
        { code: 'importing', label: 'Importing' },
        { code: 'transcoding', label: 'Transcoding' },
        { code: 'transcoding_error', label: 'Transcoding Error' },
      ],
      loading: false,
      currentPlay: {},
    }
  },
  async mounted () {
    this.podcastId = this.$route.params.id
    await this.retrievePodcast()
    this.load()
  },
  methods: {
    async retrievePodcast () {
      const response = await this.$http.get(`/podcasts/${this.$route.params.id}?remote=true`)
      this.podcast = {
        _id: response.data._id,
        title: response.data.meta.titleEnglish,
        remote: response.data.remote
      }
    },

    load () {
      const params = {
        limit: this.pagination.limit,
        offset: this.pagination.offset
      }

      if (this.searchData.query) {
        params.search = this.searchData.query
      }

      if (this.searchData.status && this.searchData.status.length > 0) {
        params.status = this.searchData.status.join(',')
      }

      this.loading = true
      this.$http.get(`/podcasts/${this.podcast._id}/episodes`, { params })
          .then((response) => {
            if (response.success) {
              this.episodes = response.data.items
              this.pagination.pages = response.data.pagination.pages
              this.pagination.from = response.data.pagination.from
              this.pagination.to = response.data.pagination.to
              this.pagination.total = response.data.pagination.total
            }
          })
          .finally(() => {
            this.loading = false
          })
    },

    next () {
      this.pagination.offset += this.pagination.limit
      this.load()
    },

    prev () {
      this.pagination.offset -= this.pagination.limit
      this.load()
    },

    search () {
      if (this.searchData.query && this.searchData.query.length < 3) {
        return false
      }

      this.load()
    },

    async playAudio (index) {
      if (Object.keys(this.currentPlay).length > 0) {
        this.stopAudio()
      }

      try {
        this.wavesurfer = WaveSurfer.create({
          container: `#wavesurfer_${index}`,
          height: '60',
          backend: 'MediaElement',
          waveColor: '#FD8283',
          progressColor: '#f2494a',
          responsive: true,
          barWidth: 2
        })

        this.wavesurfer.load(this.episodes[index].audio_url)
        this.$set(this.currentPlay, index, true)
        await this.wavesurfer.play()
      } catch (error) {
        console.log(error)
        this.$notify({
          group: 'notifications',
          title: 'Error',
          text: 'Error playing the episode',
          type: 'error'
        })
        this.$set(this, 'currentPlay', {})
      }
    },

    stopAudio () {
      if (this.wavesurfer) {
        this.wavesurfer.pause()
        this.wavesurfer.empty()
        this.wavesurfer.destroy()
        this.wavesurfer = null
        this.$set(this, 'currentPlay', {})
      }
    },

    formatEpisodeDate (date) {
      return moment(date).utc(false).format('MMMM Do, YYYY [at] h:mm a')
    }
  }
}
</script>
<style lang="scss">
.bv-no-focus-ring {
  .custom-control-inline {
    min-width: 12%;
    margin-right: 20px;
  }
}
</style>
<style scoped lang="scss">
.loading-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(247, 247, 247, 0.95);
  z-index: 1;

  .loader-inline {
    margin-top: calc(50vh - 230px);
  }
}

.list {
  li {
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 80px;
    margin-bottom: 10px;
    border: 1px solid #eaeaea;

    &.sortable-ghost {
      opacity: 0.3;
    }

    &.header {
      height: 20px;
      border: none;
      background: none;

      div {
        display: inline-block;
        width: 50%;

        &.label {
          font-weight: 600;
        }

        &.actions {
          text-align: right;

          button {
            padding: 5px 10px 3px;
            margin: 9px 0 5px 0;
            text-align: center;
            background: #f2494a;
            border: 1px solid #f2494a;
            color: #fff;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 12px;
            border-radius: 0;

            &:disabled {
              cursor: not-allowed;
            }
          }
        }
      }
    }

    .episode-order {
      min-width: 40px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      font-size: 12px;
      font-weight: 900;
      border-right: 1px solid #eaeaea;
    }

    .episode-img {
      padding-left: 5px;
      padding-right: 5px;
      border-right: 1px solid #eaeaea;
      height: 80px;
      line-height: 80px;

      img {
        width: 60px;
        cursor: move;
        border-radius: 4px;
      }
    }

    &.load-more-wrapper {
      height: auto;
      text-align: center;
      background: transparent;
      border: none;
      align-items: center;
      align-content: center;

      button {
        background-color: #f1494a;
        display: inline-block;
        padding: 5px 20px;
        color: #fff;
        cursor: pointer;
        border-radius: 0;
        border: none;
        width: 100%;
      }
    }
  }
}

.episode-controls-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;

  .wavesurfer {
    position: absolute;
    right: 10px;
    left: 50px;
    background: #fff;
    border: 1px solid #eaeaea;
    display: none;

    &.show {
      display: block;
    }

    &.has-video {
      left: 90px;
    }
  }

  .wavesurfer canvas {
    max-width: none;
  }

  .icon-wrapper {
    width: 40px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    border-left: 1px solid #eaeaea;
    flex-shrink: 0;

    &.icon-wrapper-video {
      border-left: none;
      border-right: 1px solid #eaeaea;
    }
  }

  .icon-wrapper-play {
    border-left: none;
    border-right: 1px solid #eaeaea;
  }

  .player {
    margin-right: 15px;
    flex: 1;
    position: relative;

    .episode-title {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 500;
      padding-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 650px;
      white-space: nowrap;
    }

    .episode-status {
      padding-left: 10px;
      font-size: 12px;
    }

    .episode-number {
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      text-align: center;
      background: rgba(0, 0, 0, .2);
      border-radius: 5px;
      font-size: 11px;
      font-weight: 600;
      line-height: 20px;
    }

    .episode-date {
      padding-left: 10px;
      font-size: 12px;
      font-style: normal;
    }
  }
}

</style>
