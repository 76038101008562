<template>
  <div>
        <div class="page-header">
          <div class="row align-items-center header">
            <div class="col-9">
              <div class="title">
                <router-link to="/admin/moments">Moments</router-link>/
                <span> {{ $route.params.id }} </span>
              </div>
            </div>
          </div>
        </div>
  
        <div class="page-content">
            <div v-if="moment">
                <MomentsForm :moment="moment" @saveMoment="updateMoment"/>
            </div>
        </div>
    </div>
</template>

<script>
import MomentsForm from './MomentsForm.vue';
import AssetsVideos from '../assets/videos/AssetsVideos.vue';
import axios from 'axios';
import MomentValidations from './Validation.js'

export default {
    name: 'MomentsEdit',
    components: {
        MomentsForm,
        AssetsVideos
    },
    data(){
        return {
            moment: null
        }
    },
    mounted(){
        this.getMomentDetails()
    },
    methods:{
        async getMomentDetails(){
            const momentId = this.$route.params.id
            let url = `${this.$http.url}/moments/${momentId}`
            const response = await axios.get(url)
            this.moment = response.data.data
        },
        async updateMoment(formData){
            const url = `${this.$http.url}/moments/${this.moment._id}`
            console.log('updateMoment', {moment: formData});

            if(formData.published){
                const isMetadataValid = MomentValidations.metadataValidationForPublishedMoment(formData)
                if(!isMetadataValid){
                this.$notify({
                        group: 'notifications',
                        title: 'Missing required fields',
                        text: 'All fields except link is required to update a published moment',
                        type: 'error'
                    })
                    return 
                }
            }else{
                const isRemoved = await this.$http.put(`/video-section/remove-resource/moment/${formData._id}`)
                if(!isRemoved.success){
                    this.$notify({
                        group: 'notifications',
                        title: 'Cannot unpublish moment',
                        text: 'Failed to remove moment from video section',
                        type: 'error'
                    })
                    return
                }
            }

            const response = await axios.put(url, formData)
            if(response.data.success){
                this.$notify({
                        group: 'notifications',
                        title: 'Moment is updated',
                        text: '',
                        type: 'success'
                    })
            }
        }
    }
}
</script>