<template>
  <div class="videos-edit-component">
    <div class="page-header" :class="{shadowHeader: showShadow}">
      <div class="row align-items-center header">
        <div class="col-6">
          <div class="title">
            <router-link to="/admin/videos">New Video </router-link>/
            <span>{{video.title.es}}</span>
          </div>
        </div>
        <div class="col-6">
          <div class="typeahead-wrapper float-right">
            <button type="submit" form="videoForm" id="createButton" class="typeahead-btn">Create</button>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="videos-all-list">
        <div class="container">
          <div class="col-12">
            <b-form @submit.prevent="save" v-on:keydown.13.prevent.stop id="videoForm" class="bootstrap-form">
              <VideosForm :video="video" />
            </b-form>
          </div>
        </div>
      </div>
    </div>

    <VideosAddArtistModal :artists="artists" />
  </div>
</template>


<script>

import axios from 'axios'
import { Datetime } from 'vue-datetime'
import { DateTime as LuxonDateTime } from 'luxon'
import VideosForm from './VideosForm'
import VideosCommon from './VideosCommon'
import VideosAddArtistModal from './AddArtistsModal'

export default {
  name: 'VideosNew',
  components: {
    axios,
    Datetime,
    VideosForm,
    VideosAddArtistModal
  },
  data () {
    const utilities = new VideosCommon({ context: this })
    const imageKeys = {}
    for (let imageSize of VideosCommon.getImageSizesState()) {
      imageKeys[imageSize.imageKey] = {}
    }
    return {
      video: {
        title: {
          en: '',
          es: ''
        },
        cardTitle: {
          en: '',
          es: ''
        },
        sliderTitle: {
          es1: '',
          en1: '',
          es2: '',
          en2: ''
        },
        slug: '',
        description: {
          en: '',
          es: ''
        },
        categories: {
          secondary: [],
          primary: null
        },
        genres: [],
        artists: [],
        images: imageKeys,
        long_format: false,
        origin: '',
        lastUpdated: '',
        tags: [],
        file: {
          portrait: {
            ingestLocation: null,
            m3u8: null
          },
          landscape: {
            ingestLocation: null,
            m3u8: null
          }
        },
        authors: [],
        regions: [],
        analytics: {},
        available: {
          start: LuxonDateTime.local().toISO(),
          end: LuxonDateTime.fromObject({ year: 2025, month: 12, day: 31, hour: 0, minute: 0 }).toISODate()
        },
        published: false,
        videoCardUrl: null,
        isSponsored: false,
        disableAds: false,
        SpotXPrerollVideoAdUnitId: null,
        DFPPrerollVideoAdUnitId: null,
        isEncoding: {
          landscape: false,
          portrait: false
        },
        web_ads: {
          '300x250': [{
            tag: '',
            disable:false,
            start: null,
            end: null
          }],
          '728x90': [{
            tag: '',
            disable:false,
            start: null,
            end: null
          }]
        },
        vendor:{
          landscape:{
            job_id:'',
            mediastream_id:''
          },
          portrait:{
            job_id:'',
            mediastream_id:''
          }
        },
        show_id:''
      },
      artists: [],
      utilities,
      ...utilities.getDefaultInitialState()
    }
  },

  mounted () {
  },

  created () {
    this.utilities.handleCreated()
  },

  destroyed () {
    this.utilities.handleDestroyed()
  },

  methods: {
    save (evt) {
      const isValid = this.utilities.validateContent(evt)
      if (!isValid) {
        return
      }

      const video = this.utilities.mapVideoForSave(this.video)

      this.$http.post('/videos', {video})
        .then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Video created successfully :)',
              text: '',
              type: 'success'
            })
            this.$router.push(`/admin/videos/edit/${res.data.id}`)
          } else {
            this.$notify({
              group: 'notifications',
              title: res.message,
              text: '',
              type: 'error'
            })
          }
        }).catch((err) => {
        console.log(err)
        this.$notify({
          group: 'notifications',
          title: 'Sorry, something went wrong :(',
          text: '',
          type: 'error'
        })
      })
    },
    openNewArtist () {
      this.$refs.createArtist.show()
    }
  }
}
</script>

<style>
.video-js {
  width: 100%;
}

.vdp-datepicker__calendar {
  width: 100%;
}

.calendar-wrapper > div {
  padding: 10px;
}

.custom-control-inline {
  min-width: 24%;
  margin-right: 1%;
}

.videos-edit-component .custom-file-label {
  height: 60px;
}

.videos-edit-component .custom-file-label::after {
  display: none;
}

.videos-edit-component .custom-file input {
  height: 60px !important;
}

.videos-edit-component .custom-file input:hover {
  cursor: pointer;
}

.videos-edit-component .custom-file {
  height: 60px;
}

.videos-edit-component .required {
  color: #f2494a;
  font-size: 10px;
}

.videos-edit-component .modal .custom-file input {
  height: 45px !important;
}

.videos-edit-component .modal .custom-file-label::after {
  display: block;
}

.videos-edit-component .modal .custom-file-label {
  height: calc(2.25rem + 2px) !important
}

.video-holder-card .card-image video {
  width: 100%;
  margin-bottom: 10px;
}

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.page-header {
  padding: 30px 15px 20px;
}

.typeahead-wrapper {
  align-items: center;
}

.controls-wrapper ul {
  margin-bottom: 0 !important;
}

.status-wrapper {
  display: flex;
  align-items: center;
}

.switch {
  width: 53px;
}

.status-wrapper .video-labels {
  margin-right: 10px;
  padding: 0;
}

.videos-edit-component{
  display: block;
  width: 100%;
}

.videos-edit-component .header .title{
  margin-bottom: 0;
}

.videos-all-list .bootstrap-form small {
  margin-top: -3px;
  margin-bottom: 3px;
}

.input-group-text {
  height: 45px;
  font-size: 14px;
  font-weight: bold;
}

.btn-outline-success {
  font-size: 14px;
  text-align: center;
  background: #f2494a;
  color: #fff;
  text-transform: uppercase;
}

textarea {
  padding: 10px;
}

.geographic-targeting small{
  margin-bottom: 10px;
}

.addNewArtist {
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  color: #f2494a;
}

.artist-image {
  position: relative;
  margin-bottom: 10px;
}

.artist-image img {
  width: 100%;
}

.loading.isLoadingImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  margin-top: 0;
}

.tags-btn {
  width: auto;
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
}

.video-validations {
  list-style-type: none;
  margin: 10px 0;
}

.video-validations li {
  font-size: 11px;
}

.inline-checkbox {
  display: inline;
  width: auto;
  height: auto !important;
}

.video-labels {
  padding: 10px 0;
  width: 100%;
  display: block;
  font-size: 12px;
  font-weight: bold;
}

.inputDescription {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.video-player {
  margin-top: 20px;
  text-align: center !important;
}

.loading {
  background-color: #fff;
  position: relative;
  margin-top: 20px;
}

.loadingPortrait {
  height: 700px;
}

.loadingLandscape {
  height: 400px;
}

.inputImages {
  margin: 5px 0 10px;
}

#radiosGroup {
  width: 100%;
}

.subtitleDescription {
  width: 100%;
  margin-bottom: 10px;
}

.video-holder-card {
  max-width: 300px;
  margin-top: 10px;
}

.find-artist,
.find-artist:hover,
.find-artist:active,
.find-artist:active,
.find-artist:active:focus {
  background-color: #d6d6d6 !important;
  color: #2c3e50 !important;
  box-shadow: none !important;
  cursor: default !important;
}

ul.searchTagsResults {
  z-index: 3;
  position: absolute;
  top: 46px;
  /* right: 85px; */
  left: 5px;
  min-height: 40px;
  max-height: 200px;
  background-color: #fff;
  border: 1px solid #f7f7f7;
  list-style: none;
  overflow: scroll;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.175);
  box-shadow: 0 6px 12px rgba(0,0,0,0.175);
}

ul.searchTagsResults li {
  padding: 10px 20px;
  cursor: pointer;
}

ul.searchTagsResults li:hover {
  color: #fff;
  background-color: #f45354;
}

.shadowHeader {
  -webkit-box-shadow: 0 8px 6px -6px rgba(0,0,0,0.19);
  -moz-box-shadow: 0 8px 6px -6px rgba(0,0,0,0.19);
  box-shadow: 0 8px 6px -6px rgba(0,0,0,0.19);
}

.loading-images {
  position: relative;
  height: 200px;
}

.images-wrapper {
  margin-bottom: 15px;
}

.images-wrapper img {
  max-width: 150px;
  margin-right: 3px;
}

.content-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.content-wrapper .progress-status {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #dc3545;
}

.content-wrapper .progress-status.complete {
  color: #7eaf12;
}

.content-wrapper .progress-status span {
  font-size: 15px;
}

.content-wrapper .spinner {
  margin: 0 auto;
  width: 50px;
  height: 40px;
}

.content-wrapper .spinner > div {
  background-color: #f2494a;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.content-wrapper .spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.content-wrapper.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.content-wrapper .spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.content-wrapper .spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}


@media (max-width: 768px) {
  .videos-edit-component .container {
    padding: 0;
  }

  .slug-creation .input-group-prepend{
    display: none;
  }

  .input-group-append button{
    font-size:12px;
  }

  .images-wrapper img {
    margin-bottom: 5px;
    max-width: 100%;
  }
}

</style>
