import { render, staticRenderFns } from "./CategoryNew.vue?vue&type=template&id=3628ccf3&scoped=true"
import script from "./CategoryNew.vue?vue&type=script&lang=js"
export * from "./CategoryNew.vue?vue&type=script&lang=js"
import style0 from "./CategoryNew.vue?vue&type=style&index=0&id=3628ccf3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3628ccf3",
  null
  
)

export default component.exports