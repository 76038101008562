<template>
  <div>
    <b-row>
        <b-col cols="12" class="required">
            *Required to publish
        </b-col>
        <b-col md="6" sm="12">
            <b-form-group
                id="moment-title-label"
                label="*English Title:"
                label-for="moment-title"
                description="English Title for the moment (60 characters max)"
            >
                <b-form-input
                id="moment-title"
                maxlength="60"
                v-model="form.title.en"
                type="text"
                placeholder="Title"
                required
                ></b-form-input>
            </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
            <b-form-group
                id="moment-title-label"
                maxlength="60"
                label="*Spanish Title:"
                label-for="moment-title"
                description="Spanish Title for the moment (60 characters max)"
            >
                <b-form-input
                id="moment-title"
                v-model="form.title.es"
                type="text"
                placeholder="Title"
                required
                ></b-form-input>
            </b-form-group>
        </b-col>

        <b-col md="6" sm="12">
            <b-form-group
                id="moment-description-label"
                label="*English Description:"
                label-for="moment-description"
                description="A description or subtitle for the moment(in English)"
            >
                <b-form-input
                id="moment-description"
                v-model="form.description.en"
                type="text"
                placeholder="Description"
                required
                ></b-form-input>
            </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
            <b-form-group
                id="moment-description-label"
                label="*Spanish Description:"
                label-for="moment-description"
                description="A description or subtitle for the moment(in Spanish)"
            >
                <b-form-input
                id="moment-description"
                v-model="form.description.es"
                type="text"
                placeholder="Description"
                required
                ></b-form-input>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-form-group
                id="moment-link-label"
                label="Attached Link:"
                label-for="moment-link"
                description="Here you may link this moment to a full length video or a sponsor"
            >
                <b-form-input
                id="moment-link"
                v-model="form.link"
                type="text"
                placeholder="https://www.lamusica.com...."
                ></b-form-input>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-form-group
                id="moment-slug-label"
                label="*Slug:"
                label-for="moment-slug"
                description="Every moment needs a unique slug"
            >
                <b-form-input
                id="moment-slug"
                v-model="form.slug"
                type="text"
                ></b-form-input>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-form-checkbox
            id="moment-publish"
            v-model="form.published">
            Published
            </b-form-checkbox>
        </b-col>

        <b-col cols="4" class="d-flex justify-content-center my-2">
            <b-btn @click="$bvModal.show('assetVideosModal')">Connect A Video</b-btn>
        </b-col>

        <b-col cols="4" class="d-flex justify-content-center my-2">
            <b-btn @click="showThumbSelector">Load Thumbnails</b-btn>
        </b-col>

        <b-col cols="4" class="d-flex justify-content-center my-2">
            <b-btn @click="saveMoment"> {{ formButton }} </b-btn>
        </b-col>


        
        <section class="d-flex flex-wrap w-100">
              <div v-if="player.portraitOptions.sources[0].src" class="video-preview p-2 mx-2">
                <video-player class="video-player-box"
                        ref="videoPlayer"
                        :options="player.portraitOptions"
                        :playsinline="true">
                </video-player>
                <p>Video Preview</p>
              </div>

              <div v-if="form.image && form.image.url !== ''" class="image-preview p-2 mx-2">
                <b-img :src="form.image.url" width="320"></b-img>
                <p>Image Preview</p>
              </div>
        </section>
<!-- Create slug button for show and moments -->
        
    </b-row>

        <b-modal id="assetVideosModal" ref="assetVideosModal" title="Video Files Manager" size="xl" :hide-footer="true"  :no-close-on-backdrop="true" :no-close-on-esc="true">
            <div class="modal-wrapper">
            <AssetsVideos orientation="portrait" v-on:videoSelected="handleSelected($event)"></AssetsVideos>
            </div>
        </b-modal>

        <b-modal id="thumbnailModal" title="Thumbnail Selection" size="xl" :hide-footer="true" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <ThumbnailSelection :thumbnails="thumbnails" @setThumbnail="setImageUrl" />
        </b-modal>
  </div>
</template>

<script>
import AssetsVideos from '../assets/videos/AssetsVideos.vue';
import axios from 'axios';
import ThumbnailSelection from './ThumbnailSelection.vue';

export default {
    name:'MomentsForm',
    props:['moment'],
    components:{
        AssetsVideos,
        ThumbnailSelection
    },
    data(){
        return{
            player: {
                portraitOptions: {
                language: 'en',
                responsive: true,
                sources: [{
                    type: 'application/x-mpegURL',
                    src: this.moment.file || null
                }],
                fluid: true
                }
            },
            form:{
                title:{
                    en:'',
                    es:''
                },
                description:{
                    en:'',
                    es:''
                },
                published:false,
                link:'',
                slug:'',
                image:{
                    url:'',
                    id:''
                },
                video:{
                    url:'',
                    id:''
                }
            },
            thumbnails:{
             options: null,
             selected:''   
            }
        }
    },
    computed:{
        formButton() {
           return this.moment._id ? 'Update Moment' : 'Create Moment'
        }
    },
    mounted(){
        this.form.title = this.moment.title
        this.form.description = this.moment.description
        this.form.published = this.moment.published
        this.form.link = this.moment.link
        this.form.slug = this.moment.slug

        this.form.video = this.moment.video
        this.player.portraitOptions.sources[0].src = this.moment.video.url

        this.form.image = this.moment.image
        this.thumbnails.selected = this.moment.image

        if(this.moment.video && this.moment.video.id && this.moment.video.id !==''){
            this.loadThumbs(this.moment.video.id)
        }
    },
    methods:{
    saveMoment(){
        this.$emit('saveMoment', this.form)
    },
    showThumbSelector(){
        this.$bvModal.show('thumbnailModal')
    },
    async loadThumbs(mediastreamId){
        let url = `${this.$http.url}/moments/mediastream/${mediastreamId}/thumbs`
        const response = await axios.get(url)
        if(response.data.success){
            this.thumbnails.options = response.data.data.thumbnails
        }

    },
    setImageUrl(image){
        const imgProp = {
            url: image.url,
            id: image.id
        }
        this.form.image = imgProp
        this.thumbnails.selected = imgProp
        this.$bvModal.hide('thumbnailModal')
    },
    handleSelected (value) {
      const hlsUrl = `${value.file_hls_url}?${new Date().getTime()}`
      this.loadThumbs(value.id)

      this.form.title.en = value.file_name
      this.form.title.es = value.file_name

      this.player.portraitOptions.sources[0].src = hlsUrl
      this.form.video = {
        url: hlsUrl,
        id: value.id
      }

      this.$bvModal.hide('assetVideosModal')
    },
    }
}
</script>

<style scoped>
.video-preview{
    width: 350px;
}

.image-preview{
    width: 350px;
}

.required {
  color: #f2494a;
  font-size: 10px;
}
</style>