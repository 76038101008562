<template>
  <div>
    <div class="page-header">
        <div class="row align-items-center header">
          <div class="col-9">
            <div class="title">
              <router-link to="/admin/shows">Shows</router-link>/
              <span>New</span>
            </div>
          </div>
          <div class="col d-flex justify-content-end">
            <div class=" btn btn-danger" @click="createShow">Create Show</div>
          </div>
        </div>
      </div>

      <div class="page-content">
        <MetadataForm :meta="show.meta"/>
      </div>
  </div>
</template>

<script>
import MetadataForm from '@/components/admin/shows/MetadataForm.vue'
import ShowValidation from './Validation'

export default {
components:{
  MetadataForm
},
mounted(){
  this.getAllSlugs()
},
data(){
  return{
    show:{
      published:false,
      meta: {
        titleEnglish:'',
        titleSpanish:'',
        descriptionEnglish:'',
        descriptionSpanish:'',
        slug:'',
        isSponsored:false,
        disableAds: false,
        type: 'show',
        exploreImagery: {
            '2xImageURL': '',
            '3xImageURL': '',
            mdpiImageURL: '',
            hdpiImageURL: '',
            xhdpiImageURL: '',
            xxhdpiImageURL: '',
            xxxhdpiImageURL: ''
          },
        detailViewImagery: {
          '2xImageURL': '',
          '3xImageURL': '',
          mdpiImageURL: '',
          hdpiImageURL: '',
          xhdpiImageURL: '',
          xxhdpiImageURL: '',
          xxxhdpiImageURL: ''
        },
        cardImagery: {
          '2xImageURL': '',
          '3xImageURL': ''
        },
        landing: {
          '2xImageURL': '',
          '3xImageURL': ''
        },
        portrait: {
          '1xImageURL': '',
          '2xImageURL': '',
          '3xImageURL': ''
        },
        video_ads_settings: {
            isSponsored: false,
            sponsored: {
              start: null,
              end: null
            },
            disableAds: false,
            DFPPrerollVideoAdUnitId: null,
            WebVideoAdUnitId: null,
            AmazonTVVideoAdUnitId: null,
            AndroidTVVideoAdUnitId: null,
            RokuTVVideoAdUnitId: null,
            AppleTVVideoAdUnitId: null
          }
      },
    },
    slugs:[]
  }
},
methods:{
  async getAllSlugs(){
    const response = await this.$http.get('/shows/slugs')
    if(response.success){
      this.slugs = response.data
    }
  },
  async createShow(){
    if(!ShowValidation.slugIsUnique(this.show.meta.slug, this.slugs)){
      this.$notify({
        group: 'notifications',
        title: 'Duplicate slug',
        text: 'There\'s another show with the same slug. Each show must have a unique slug.',
        type: 'error'
      })
      return
    }

    if(ShowValidation.metadataValidationForUnpublishedShows(this.show.meta)){
      const response = await this.$http.post('/shows', this.show)
      if(response.success){
        this.$notify({
              group: 'notifications',
              title: 'Show is created',
              text: '',
              type: 'success'
            })
            const id = response.data.insertedId
            this.$router.push(`/admin/shows/edit/${id}`)
      }else{
        this.$notify({
              group: 'notifications',
              title: 'Failed to create the show',
              text: '',
              type: 'error'
            })
      }
    }else{
      this.$notify({
              group: 'notifications',
              title: 'Incomplete Metadata',
              text: 'Title english, spanish and slug is required to create a new show',
              type: 'error'
            })
    }
  },
  
}
}
</script>