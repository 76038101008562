<template>
    <div class="videos-categories-component">
      <div class="page-header">
        <div class="row align-items-center header">
          <div class="col-6">
            <div class="title">
              <router-link to="/admin/videos">Shows </router-link>/
            </div>
          </div>
        </div>
      </div>
  
      <div class="page-content">
        <b-row v-if="shows && shows.length>0" >
          <b-col v-for="show of shows" :key="show._id" sm md="3">
            <div class="show-list-card d-flex flex-column" :class="{published: show.published}">
              <div class="show-list-card-image" @click="showEdit(show._id)">
                  <img :src="show.meta.exploreImagery.hdpiImageURL" alt="" v-if="show.meta && show.meta.exploreImagery && show.meta.exploreImagery.hdpiImageURL" />
                  <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!show.meta || !show.meta.exploreImagery || !show.meta.exploreImagery.hdpiImageURL"/>
              </div>
            
              <div class="d-flex flex-row my-2 px-3"> 
                <div class="d-flex flex-column flex-grow-1">
                  <div class="show-list-card-title"> {{ show.meta.titleEnglish }} </div>
                  <div> {{ getLastUpdated(show) }} </div>
                </div>
                <div class="submenu-actions">
                  <div class="icon">
                    <i class="fas fa-ellipsis-h"></i>
                  </div>
                  <div class="actions">
                    <ul>
                      <li @click="checkBeforeUnpublish(show)"> {{ show.published ? 'Unpublish' : 'Publish' }} </li>
                      <li @click="deleteUnpublishedShow(show)">Delete</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </b-col>
        </b-row>
        <div v-else class="text-center">
          <h4>To create a new show, click create in the left subpanel</h4>
        </div>

        <b-pagination v-if="pages > 1" align="right" size="md" :total-rows="count" v-model="current" :per-page="limit" @change="getMoreShows"></b-pagination>
      </div>
    </div>
  </template>
  <script>
  import { DateTime as LuxonDateTime } from 'luxon'
  import ShowValidation from './Validation'

  export default {
  name:'VideoShows',
  async mounted(){
    await this.getShows()
  },
  data(){
    return{
      limit: 20,
      pages: 1,
      current: 1,
      count: 0,
      shows: null
    }
  },
  methods:{
    async checkBeforeUnpublish(show){
      const action = !show.published
      // if being unpublished
      if(!action){
        const isRemoved = await this.$http.put(`/video-section/remove-resource/show/${show._id}`)
        if(!isRemoved.success){
              this.$notify({
                group: 'notifications',
                title: 'Cannot unpublish show',
                text: 'Failed to remove show from video section',
                type: 'error'
              })
              return false
        }
      }
      
      this.togglePublish(show)
    },
    togglePublish(show){
      const url = `/shows/${show._id}/publish`
      const action = !show.published

      // if being published
      if(action){
        const metadataIsValid = ShowValidation.metadataValidationForPublishedShows(show.meta)
        const imageIsValid = ShowValidation.imageValidationForPublishedShows(show.meta)

        if(!metadataIsValid){
          this.$notify({
            group: 'notifications',
            title: 'Incomplete Metadata',
            text: 'Title, descriptions & slug is required for a published shows. Make sure all of those fields are filled.',
            type: 'error'
          })
          return
        }

        if (!imageIsValid){
          this.$notify({
            group: 'notifications',
            title: 'Incomplete Images',
            text: 'All images are required for a published show',
            type: 'error'
          })
          return
        }
      }

      this.$http.put(url, {published: !show.published}).then(() => {
        show.published = !show.published
        this.$notify({
            group: 'notifications',
            title: 'Show updated!',
            text: '',
            type: 'success'
          })
      })
    },
    async deleteUnpublishedShow(show){
      if(show.published){
        this.$notify({
            group: 'notifications',
            title: 'You must unpublish the show before deleting it',
            text: '',
            type: 'error'
          })
          return
      }
      const url = `/shows/${show._id}`
      const deleted= await this.$http.delete(url)
      if(deleted.success){
        this.$notify({
            group: 'notifications',
            title: 'Show deleted!',
            text: '',
            type: 'success'
          })
      }
      this.current = 1
      await this.getShows()
    },
    getLastUpdated (show) {
        if (show.updated_at) {
          const date = new Date(show.updated_at)
          const timeAgoString = LuxonDateTime.fromJSDate(date).toRelative()
          return `Last updated: ${timeAgoString}`
        }
    },
    getMoreShows(page){
      this.current = page
      this.getShows()
    },
    async getShows () {
      const showRes = await this.$http.get('/shows', {params: {limit: this.limit , page: this.current, sortBy: null}})
      this.shows = showRes.data.items
      this.count = showRes.data.count
      this.pages = Math.ceil(showRes.data.count / this.limit)
    },
    showEdit(id){
        this.$router.push('shows/edit/' + id)
    }
  }
  }
  </script>
  
  <style scoped>
   /* .show-list-card-image{
    width:200px;
    height:150px
   } */

   .show-list-card{
    margin-bottom: 1.5rem;
    background-color: white;
    border-bottom: 1px solid #f2494a
   }

   .show-list-card-image img{
    width:100%;
    height:100%;
   }

   .show-list-card .show-list-card-title {
    font-size: 12px;
    font-weight: 900;
    text-transform: capitalize;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .show-list-card.published{
    border-bottom: 1px solid #8cd200
  }

  </style>