<template>
        <div class="modal-wrapper">
        <div class="row">
          <div class="col-12">
            <div class="note">*Required for publishing</div>
          </div>
          <div class="col-6">
            <label>English Title<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Title of the show that will be displayed on all the apps.</span>
            <input type="text" placeholder="Sobremesa" v-model="meta.titleEnglish"/>
          </div>
          <div class="col-6">
            <label>Spanish Title<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Title of the show that will be displayed on all the apps.</span>
            <input type="text" placeholder="Sobremesa" v-model="meta.titleSpanish"/>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label>English Description<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">This text is for all LaMusica apps, and is used to provide a helpful summary of what the show is about.</span>
            <textarea class="form-control" v-model="meta.descriptionEnglish" rows="3"></textarea>
          </div>
          <div class="col-6">
            <label>Spanish Description<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">This text is for all LaMusica apps, and is used to provide a helpful summary of what the show is about.</span>
            <textarea class="form-control" v-model="meta.descriptionSpanish" rows="3"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label>Slug<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Please add a unique, lowercase slug for sharing & for LaMusica app/web.</span>
            <input type="text" placeholder="top-hits" v-model="meta.slug"/>
          </div>

          <div class="col-6" role="group">
            <label>Podcast Association</label>
            <span class="form-text text-muted">If applicable, select the podcast associated with this show</span>
            <b-form-input type="text" 
            :state="podcastIdValid" 
            @change="validatePodcastId"
            id="input-pod-id"
            aria-describedby="input-pod-id-feedback"
            placeholder="podcast Id" 
            v-model="meta.podcast_id"></b-form-input>

            <b-form-invalid-feedback id="input-live-feedback">
              Invalid podcast Id, make sure the id is correct.
            </b-form-invalid-feedback>
          </div>
        </div>

        <br />
        <AdConfigurationFormV2
            v-if="meta.video_ads_settings"
            :item="meta"
            :has-wide-orbit-companion-ads="false"
            :has-audio-companion-ads="false"
            :has-web-display-ads="false"
        />
      </div>
</template>

<script>
import AdConfigurationFormV2 from '@/components/share/AdConfigurationFormV2'

export default {
    components:{
        AdConfigurationFormV2
    },
    data(){
      return {
        podcastIdValid:null
      }
    },
    props:{
        meta:{
            type: Object,
            required:true,
            default (){
                return {}
            }
        }
    },
    mounted(){
      if(this.meta.podcast_id && this.meta.podcast_id.length > 0){
        this.validatePodcastId()
      }
    },
    methods:{
      async validatePodcastId(){
        // console.log('validatePodcastId',`/shows/validate-podcast/${this.meta.podcast_id}`);
        const validationRes = await this.$http.get(`/shows/validate-podcast/${this.meta.podcast_id}`)
        console.log('vres', validationRes);
        if(validationRes.success){
          if(validationRes.data){
            this.podcastIdValid=true
            return
          }
        }

        this.podcastIdValid=false
      }
    }
}
</script>

<style scoped>
.note{
  color: red;
  font-size: 11px;
}
</style>