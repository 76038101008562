<template>
  <div class="station-edit-component">
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-3">
          <div class="title">
            <router-link to="/admin/stations">Station </router-link>/ New
          </div>
        </div>
        <div class="col-9 pull-right">
          <div class="add-btn update-station" @click="showCancelConfirm">Cancel</div>
          <div class="add-btn update-station" @click="createStation">Create</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="controls-wrapper">
            <ul>
              <b-btn v-b-modal.modalStationMeta class="add-playlist modal-btn">Meta</b-btn>
              <b-btn v-b-modal.modalStationImages class="add-playlist modal-btn">Add Images</b-btn>
              <li class="add-playlist" @click="station.hideTitle = !station.hideTitle">{{station.hideTitle ? 'Show Title' : 'Hide Title'}}</li>
              <li class="add-playlist" @click="station.isFeatured = !station.isFeatured">{{station.isFeatured ? 'No Featured' : 'Is Featured'}}</li>
              <li class="add-playlist" @click="station.isSponsored = !station.isSponsored">{{station.isSponsored ? 'No Sponsored' : 'Is Sponsored'}}</li>
              <li @click="station.published = !station.published">{{ station.published ? 'Unpublish' : 'Publish' }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="station-content-wrapper">
        <b-tabs v-if="station.programming" v-model="tabIndex">
          <b-tab  v-for="(value, day, index) in station.programming.times" :key="index" :title="day" :title-link-class="linkClass(index)">
            <div class="row" v-if="value.length > 0">
              <div class="col-2">
                <label>Title</label>
              </div>
              <div class="col-2">
                <label>From</label>
              </div>
              <div class="col-2">
                <label>To</label>
              </div>
              <div class="col-5">
                <label>Live image URL</label>
              </div>
            </div>
            <div class="row" v-for="(program, $i) in value" :key="$i">
              <div class="station-row-wrapper">
                <div class="col-2">
                  <input type="text" placeholder="Title" v-model="program.radio_name"/>
                </div>
                <div class="col-2">
                  <input type="number" placeholder="From" v-model="program.from"/>
                </div>
                <div class="col-2">
                  <input type="number" placeholder="To" v-model="program.to"/>
                </div>
                <div class="col-5">
                  <input type="text" placeholder="Live image URL" v-model="program.live_img_url"/>
                </div>
                <div class="col-1">
                  <button type="button" class="btn-program btn-delete" @click="removeProgram(day, $i)">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button type="button" class="btn-program" @click="addProgram(day)">Add Program</button>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>


    <b-modal id="modalStationImages" :ok-only="true" ok-title="close" size="lg" title="Images">
      <div class="row modal-wrapper">
        <div class="col-6" v-for="platform in platforms" v-bind:key="platform.platform">
          <upload @imgUploaded="imgUploaded" :data="platform"></upload>
        </div>
        <div class="col-6">
          <upload-svg @imgUploaded="svgUploaded" :data="svg"></upload-svg>
        </div>

        <div class="col-6">
          <upload @imgUploaded="logoUploaded" :data="logo"></upload>
        </div>

        <div class="col-6">
          <upload @imgUploaded="carplayLogoUploaded" :data="carplayLogo"></upload>
        </div>

        <div class="col-6">
          <upload @imgUploaded="playerMobileUpload" :data="playerMobile"></upload>
        </div>
        <div class="col-6">
          <upload @imgUploaded="playerTabletUpload" :data="playerTablet"></upload>
        </div>
        <div class="col-6">
          <upload @imgUploaded="variableLogoUpload" @onFieldUpdate="fieldUpdated" :data="variableLogo"></upload>
        </div>

        <div class="col-12">
          <upload @imgUploaded="playerBackgroundUpload" :data="webTopPlayerBackground"></upload>
        </div>

        <div class="col-6">
          <p>
            <strong>Station Video Loop</strong><br/>
            <span>Video loop used once the user play a radio station</span>
          </p>
          <loader-inline v-if="video.loading"/>
          <div class="video-holder-card">
            <div class="remove-video-card" v-if="video.url" @click="handleRemoveVideo()">
              <i class="fas fa-times"></i>
            </div>
            <div class="card-image" v-if="video.url" v-html="videoHTML"></div>
          </div>
          <b-form-file accept="video/mp4" ref="videoInput" v-model="video.file" :placeholder="video.placeholder" @change.native="handleVideoUpload($event)"></b-form-file>
        </div>
      </div>
    </b-modal>

    <b-modal id="modalStationMeta" :ok-only="true" ok-title="close" size="lg" title="Edit Station Metadata">
      <station-meta-form :station="station" />
    </b-modal>

    <b-modal id="confirmCancelModal" ref="confirmCancelModal" size="md" title="Confirmation" @ok="cancelAction">
      <div class="row modal-wrapper">
        <div class="col-12">
          Are you sure you want to cancel this page?
        </div>
      </div>
    </b-modal>

  </div>
</template>


<script>
import upload from '../../share/UploadImage'
import UploadSvg from '../../share/UploadSvg'
import AdConfigurationForm from '../../share/AdConfigurationForm'
import StationMetaForm from './StationMetaForm'
import {DateTime as LuxonDateTime} from 'luxon'

export default {
  name: 'StationsNew',
  components: {
    StationMetaForm,
    AdConfigurationForm,
    upload,
    UploadSvg
  },
  props: ['card'],
  data () {
    return {
      station: {
        callSign: null,
        title: null,
        published: false,
        order: 99,
        disableAds: false,
        primaryGenreEnglish: null,
        city: null,
        slug: null,
        videoUrl: null,
        websiteURL: null,
        broadcastFrequency: null,
        address: null,
        stationDescriptionEnglish: null,
        stationDescriptionSpanish: null,
        primaryGenreSpanish: null,
        hideTitle: true,
        isFeatured: false,
        callSignID: null,
        secondaryTitleEnglish: null,
        secondaryTitleSpanish: null,
        scanGenresColorCode: null,
        twitterEnglishText: null,
        twitterSpanishText: null,
        facebookEnglishText: null,
        facebookSpanishText: null,
        facebookShareImageLink: null,
        isSponsored: false,
        sponsored: {
          start: LuxonDateTime.local().toISO(),
          end: LuxonDateTime.fromObject({ year: 2025, month: 12, day: 31, hour: 0, minute: 0 }).toISODate()
        },
        streamURL: null,
        adsZoneId: null,
        adsServerUrl: null,
        adsCompanionSizeWidth: null,
        adsCompanionSizeHeight: null,
        adsCompanionMobileSizeWidth: null,
        adsCompanionMobileSizeHeight: null,
        latitude: null,
        longitude: null,
        androidImageInterstitialAdUnitId: null,
        iOSImageInterstitialAdUnitId: null,
        androidVideoInterstitialAdUnitId: null,
        iOSVideoInterstitialAdUnitId: null,
        exploreImagery: {
          '2xImageURL': null,
          '3xImageURL': null,
          mdpiImageURL: null,
          hdpiImageURL: null,
          xhdpiImageURL: null,
          xxhdpiImageURL: null,
          xxxhdpiImageURL: null
        },
        detailViewImagery: {
          '2xImageURL': null,
          '3xImageURL': null,
          mdpiImageURL: null,
          hdpiImageURL: null,
          xhdpiImageURL: null,
          xxhdpiImageURL: null,
          xxxhdpiImageURL: null
        },
        programming: {
          radio_url: null,
          station: null,
          timezone: null,
          offset: null,
          times: {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: []
          }
        },
        layout: [
          {
            type: 'cards',
            title: {
              en: 'Titulo en espanol',
              es: 'english Title'
            },
            automated: 'none',
            displayTitles: false,
            items_desktop_count: 1,
            items_mobile_count: 1,
            image_type: 'landscape',
            items: []
          }
        ],
        web_ads: {
          '300x250': [{
            tag: '',
            start: null,
            end: null
          },
          {
            tag: '',
            start: null,
            end: null
          }],
          '970x90': [{
            tag: '',
            start: null,
            end: null
          }],
          '1270x150': [{
            tag: '',
            disable: false,
            start: LuxonDateTime.local().toISO(),
            end: LuxonDateTime.fromObject({ year: 2025, month: 12, day: 31, hour: 0, minute: 0 }).toISODate()
          }],
          '1270x150_ALT': [{
            tag: '',
            disable: true,
            start: LuxonDateTime.local().toISO(),
            end: LuxonDateTime.fromObject({ year: 2025, month: 12, day: 31, hour: 0, minute: 0 }).toISODate()
          }],
          '1x1': [{
            tag: '',
            start: LuxonDateTime.local().toISO(),
            end: LuxonDateTime.fromObject({ year: 2025, month: 12, day: 31, hour: 0, minute: 0 }).toISODate()
          }],
          '728x180': [{
            tag: '',
            disable:false,
            start: null,
            end: null
          }],
          '320x100':[{
            tag: '',
            disable:false,
            start: null,
            end: null
          }],
          '468x60': [{
            tag: '',
            disable:false,
            start: null,
            end: null
          }]
        },
        player: {},
        logo : {},
        carplayLogo : {url:''},
        svg: null,
        chatBannerAd:{
          tag: '',
          isDisabled: false
        },
        chat: {
          id: '',
          welcomeMessage:{},
          isEnabled: false
        },
        podcasts: [],
        livestreams: [],
        mobile_hex_color:'#ffffff'
      },
      image: '',
      svg: {
        label: 'Upload SVG',
        description: 'This will be used on the Station section of LaMusica app/web',
        uploadURL: '/stations/image?type=svg',
        url: ''
      },
      platforms: {
        android: {
          label: 'Android - 1296x560px',
          description: 'This will be used on the playlists section of LaMusica app/web',
          platform: 'android',
          isLoading: false,
          width: 1296,
          height: 560,
          url: ''
        },
        ios: {
          label: 'iOS - 1092x432px',
          description: 'This will be used on the playlists section of LaMusica app/web',
          platform: 'ios',
          isLoading: false,
          width: 1092,
          height: 432,
          url: ''
        },
        exploreSquare: {
          label: 'Detail - 1440x1440px',
          platform: 'exploreSquare',
          description: 'This will be used on the detail view (app/web) and homepage card rows (app)',
          isLoading: false,
          width: 1440,
          height: 1440,
          url: ''
        },
        landing: {
          label: 'Featured Header - 1920x850',
          platform: 'landing',
          description: 'This will be used on the tv and homepage website rotators',
          isLoading: false,
          width: 1920,
          height: 850,
          url: ''
        }
      },
      video: {
        loading: false,
        file: null,
        url: null,
        placeholder: 'Upload Video .MP4'
      },
      logo: {
        label: 'Logo Square - 250x250px',
        description: 'This will be the sation logo on LaMusica web',
        isLoading: false,
        width: 250,
        height: 250,
        url: '',
        uploadURL: '/stations/image',
        isDimensionVariable : false
      },
      carplayLogo: {
          label: 'Carplay/Android Auto Logo - 200x200px',
          description: 'This image will show up in android auto or apple carplay',
          isLoading: false,
          width: 200,
          height: 200,
          url: '',
          uploadURL: '/stations/image',
          isDimensionVariable : false
      },
      playerMobile: {
        label: 'Player Background(Mobile) - 1618x2880px',
        description: 'This will be used as the player background of LaMusica app on android/ios phones',
        isLoading: false,
        width: 1618,
        height: 2880,
        url: '',
        uploadURL: '/stations/image',
        isDimensionVariable : false
      },
      playerTablet: {
        label: 'Player Background(Tablet) - 640x960px',
        description: 'This will be used as the player background of LaMusica app for tablets',
        isLoading: false,
        width: 640,
        height: 960,
        url: '',
        isDimensionVariable : false,
        uploadURL: '/stations/image'
      },
      variableLogo: {
        label: 'Variable Logos',
        description: 'These are transparent logos that are used throughout the app.',
        isLoading: false,
        width: 0,
        height: 0,
        isDimensionVariable : true,
        url: '',
        uploadURL: '/stations/image'
      },
      phonePlayer: {
            label: 'Mobile Player 1000x500',
            description: 'This image will appear in the player in android and ios',
            isLoading: false,
            width: 1000,
            height: 500,
            isDimensionVariable : false,
            url: '',
            uploadURL: '/stations/image'
      },
      webTopPlayerBackground: {
        label: 'Web Top Strip Player Background',
        description: 'This will be used as the top player background of LaMusica web.',
        isLoading: false,
        width: 1920,
        height: 120,
        isDimensionVariable : false,
        url: '',
        uploadURL: '/stations/image'
      },
      tabIndex: 0,
      optionsTimezone: [
        { value: null, text: 'Select Timezone' },
        { value: 'America/New_York', text: 'America/New York' },
        { value: 'America/Los_Angeles', text: 'America/Los Angeles' },
        { value: 'America/Chicago', text: 'America/Chicago' },
        { value: 'America/Puerto_Rico', text: 'America/Puerto Rico' }
      ],
      stream_settings: {
        is_active: false,
        stream_url_aac: null,
        stream_url_hls: null,
        zone_id: null,
        companion_server_url: null,
        ads_companion_size_width: 300,
        ads_companion_size_height: 250,
        ads_companion_mobile_size_width: 300,
        ads_companion_mobile_size_height: 250,
      }
    }
  },
  computed: {
    videoHTML: function () {
      return `<video controls><source src="${this.video.url}" type="video/mp4"></video>`
    }
  },
  mounted () {
  },
  methods: {
    addProgram (day) {
      this.station.programming.times[day].push({
        from: null,
        to: null,
        radio_name: null,
        live_img_url: null
      })
    },
    handleRemoveVideo () {
      this.video.url = null
      this.video.file = null
      this.station.videoUrl = null
    },
    handleVideoUpload ($event) {
      const input = $event.target
      const formData = new FormData()
      const file = input.files && input.files[0]
      if (!file) {
        return
      }

      const hasValidExtension = /\.(mp4)$/i.test(file.name)
      if (!hasValidExtension) {
        return this.$notify({
          group: 'notifications',
          title: 'Video should be mp4 format :(',
          text: '',
          type: 'error'
        })
      }

      const fileSize = file.size / 1024 / 1024
      const isTooLargeUpload = fileSize > 3
      if (isTooLargeUpload) {
        return this.$notify({
          group: 'notifications',
          title: 'Video File size exceeds 3 MB :)',
          text: '',
          type: 'error'
        })
      }

      formData.append('file', file)

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      this.video.loading = true
      this.$http.post('/stations/video', formData, config).then((res) => {
        if (res.success) {
          this.video.url = res.data[0]
          this.station.videoUrl = this.video.url
        } else {
          this.$notify({
            group: 'notifications',
            title: 'Video file is required :)',
            text: '',
            type: 'error'
          })
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.video.loading = false
      })
    },
    linkClass (idx) {
      if (this.tabIndex === idx) {
        return ['tab-title-active', 'tab-title']
      } else {
        return ['tab-title']
      }
    },
    removeProgram (key, index) {
      this.station.programming.times[key].splice(index, 1)
    },
    isLogoObjectValid() {
      if(this.station.logo.url && this.station.logo.url !== ''){
        if(this.station.logo.width &&this.station.logo.width>0){
          if(this.station.logo.height && this.station.logo.height>0){
            return 0;
          }else{
            return 3; //3= height not set
          }
        }else {return 2;} //2= width not set
      }else{
        return 1; // 1= url not set
      }
    },
    createStation () {
      if (this.station.published) {
        if (!this.station.title || this.station.title === '') {
          return this.$notify({
            group: 'notifications',
            title: 'Title is required :(',
            text: '',
            type: 'error'
          })
        }

        if (!this.station.slug || this.station.slug === '') {
          return this.$notify({
            group: 'notifications',
            title: 'Slug is required :(',
            text: '',
            type: 'error'
          })
        }

        if (!this.station.callSign || this.station.callSign === '') {
          return this.$notify({
            group: 'notifications',
            title: 'Call Sign is required :(',
            text: '',
            type: 'error'
          })
        }

        if (!this.station.programming || !this.station.programming.radio_url || this.station.programming.radio_url === '') {
          return this.$notify({
            group: 'notifications',
            title: 'Radio URL is required :(',
            text: '',
            type: 'error'
          })
        }

        if ( (!this.station.streamURL || this.station.streamURL === '') && (!this.station.streamURLHls || this.station.streamURLHls === '') ) {
          return this.$notify({
            group: 'notifications',
            title: 'Stream URL is required :(',
            text: '',
            type: 'error'
          })
        }

        let valid = this.validateProgramming()
        if (!valid) {
          this.$notify({
            group: 'notifications',
            title: 'All programs are required :(',
            text: '',
            type: 'error'
          })
          return false
        }

        if(!this.station.player.mobile){
          this.$notify({
                group: 'notifications',
                title: 'Player Background(Mobile) is required',
                text: '',
                type: 'error'
              })
            return false;
        }
        if(!this.station.player.tablet){
          this.$notify({
                group: 'notifications',
                title: 'Player Background(Tablet) is required',
                text: '',
                type: 'error'
              })
            return false;
        }

        if (!this.station.videoUrl || (this.station.videoUrl === '')) {
          return this.$notify({
            group: 'notifications',
            title: 'Video Loop is required :(',
            text: '',
            type: 'error'
          })
        }

        if (this.station.chat.isEnabled){
          if(this.station.chat.id === ''){
            return this.$notify({
            group: 'notifications',
            title: 'Chat id is required :(',
            text: '',
            type: 'error'
          })
          }
        }

        let logoState = this.isLogoObjectValid()
        switch(logoState){
          case 1:
            this.$notify({
                group: 'notifications',
                title: 'A Variable logo is required',
                text: '',
                type: 'error'
              })
            return false;
          case 2:
            this.$notify({
                group: 'notifications',
                title: 'Width for Variable logo is required',
                text: '',
                type: 'error'
              })
            return false;
          case 3:
            this.$notify({
                group: 'notifications',
                title: 'Height for Variable logo is required',
                text: '',
                type: 'error'
              })
            return false;
        }
      }

      for (const key in this.station.programming.times) {
        if (this.station.programming.times.hasOwnProperty(key)) {
          this.station.programming.times[key].forEach((day) => {
            day.from = parseInt(day.from)
            day.to = parseInt(day.to)
          })
        }
      }

      this.$http.post('/stations', {station: this.station})
        .then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Station created successfully :)',
              text: '',
              type: 'success'
            })
            this.$router.push('/admin/stations')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    validateProgramming () {
      let alert = false
      for (const day in this.station.programming.times) {
        if (this.station.programming.times[day]) {
          this.station.programming.times[day].forEach(item => {
            for (const key in item) {
              if (item[key] === null) {
                alert = true
              }
            }
          })
        }
      }

      if (alert) {
        return false
      }

      return true
    },
    imgUploaded (formData, data) {
      this.image = formData
      // data.isLoading = true
      this.platforms[data.platform].isLoading = true
      this.$http.post(`/common/image?resize=${data.platform}`,
        this.image,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then((res) => {
          this.platforms[res.data.device]['url'] = res.data.images[0]
          switch (res.data.device) {
            case 'android':
              this.station.exploreImagery.xxxhdpiImageURL = res.data.images[0]
              this.station.exploreImagery.xxhdpiImageURL = res.data.images[1]
              this.station.exploreImagery.xhdpiImageURL = res.data.images[2]
              this.station.exploreImagery.hdpiImageURL = res.data.images[3]
              this.station.exploreImagery.mdpiImageURL = res.data.images[4]
              break
            case 'ios':
              this.station.exploreImagery['3xImageURL'] = res.data.images[0]
              this.station.exploreImagery['2xImageURL'] = res.data.images[1]
              break
            case 'exploreSquare':
              this.station.detailViewImagery.xxxhdpiImageURL = res.data.images[0]
              this.station.detailViewImagery.xxhdpiImageURL = res.data.images[1]
              this.station.detailViewImagery.xhdpiImageURL = res.data.images[2]
              this.station.detailViewImagery.hdpiImageURL = res.data.images[3]
              this.station.detailViewImagery.mdpiImageURL = res.data.images[4]
              this.station.detailViewImagery['3xImageURL'] = res.data.images[5]
              this.station.detailViewImagery['2xImageURL'] = res.data.images[6]
              break
            case 'landing':
              this.station.landing = {}
              this.station.landing['3xImageURL'] = res.data.images[0]
              this.station.landing['2xImageURL'] = res.data.images[1]
              this.station.landing['1xImageURL'] = res.data.images[2]
              break
          }
          this.platforms[res.data.device].isLoading = false
          // data.isLoading = false
        })
        .catch((e) => {
          console.log('FAILURE!!', e)
        })
    },
    svgUploaded (url) {
      this.station.svg = url
      this.svg.url = url
    },
    logoUploaded (data, res) {
      this.logo.url = res.data[0]
      this.station.logo_web_square_image = this.logo.url
    },
    carplayLogoUploaded(data, res){
      this.carplayLogo.url = res.data[0]
      this.station.carplayLogo.url = this.carplayLogo.url
    },
    playerMobileUpload (data, res) {
      this.playerMobile.url = res.data[0]
      this.station.player.mobile = this.playerMobile.url
    },
    playerTabletUpload (data, res) {
      this.playerTablet.url = res.data[0]
      this.station.player.tablet = this.playerTablet.url
    },
    variableLogoUpload (data, res) {
      this.variableLogo.url = res.data[0]
      this.station.logo.url = this.variableLogo.url; station.data.logo.url
    },
    playerBackgroundUpload (data, res) {
      this.webTopPlayerBackground.url = res.data[0]
      this.station.player.webTopBackground = this.webTopPlayerBackground.url
    },
    fieldUpdated(data){
      this.variableLogo.height= data.height
      this.variableLogo.width= data.width
      this.station.logo.width = parseInt(this.variableLogo.width)
      this.station.logo.height = parseInt(this.variableLogo.height)
    },
    showCancelConfirm() {
      this.$refs.confirmCancelModal.show()
    },
    cancelAction() {
      this.$refs.confirmCancelModal.hide()
      this.$router.push('/admin/stations')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.cards-type-playlist .controls-wrapper ul {
  margin-bottom: 0 !important;
}

.station-content-wrapper {
  padding: 15px 0;
}

.cards-type-playlist .header {
  margin-bottom: 60px;
}

.cards-type-playlist .typeahead-wrapper {
  justify-content: flex-end;
}

.cards-type-playlist .custom-file {
  margin-bottom: 15px;
}

.cards-type-playlist .wrapper-btn{
  display: flex;
  justify-content: flex-end;
}

.cards-type-playlist .card-btn{
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  text-transform: uppercase;
}

.cards-type-playlist .btn-add{
  margin-right: 10px;
}

.cards-type-playlist .btn-modal{
  height: 45px;
}

.card-image img{
  width: 100%;
  margin-bottom: 10px;
}

ul.playlistUpdated-list{
    list-style-type: none;
    overflow-y: scroll;
    max-height: 400px;
    padding: 3px;
}

ul.playlistUpdated-list .playlist-title{
  font-size: 12px;
  font-weight: bold;
}

ul.playlistUpdated-list .playlist-tracks{
  font-size: 10px;
  font-weight: bold;
}

ul.playlistUpdated-list li {
  list-style-type: none;
  display: flex;
  background-color: #fff;
  margin-bottom: 10px;
  height: 60px;
  align-items: center;
  border: 1px solid #eaeaea;
  cursor: pointer;
}

ul.playlistUpdated-list li.selected {
  background-color: #eaeaea;
}

ul.playlistUpdated-list li .playlists-list-card-img {
  margin-right: 15px;
}

ul.playlistUpdated-list li .playlists-list-card-img img{
  height: 60px;
  width: auto;
}

.station-image img {
  width: 100%;
}

.modal-wrapper label{
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 5px;
}

.btn-program {
  padding: 0;
  font-size: 14px;
  width: 130px;
  line-height: 44px;
  text-align: center;
  background: #f2494a;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  border-radius: 0;
}

.btn-program.btn-delete {
  max-width: 45px;
}

.btn-program:hover,
.btn-program.btn-delete:hover {
  background-color: #f2494a;
}

.tab-pane {
  padding: 20px 0;
}

.tab-pane input {
  height: 45px;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 15px;
  border: 1px solid #eaeaea;
}

.tab-pane label {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
}

.tab-title{
  color: #2c3e50 !important;
}

.tab-title.tab-title-active {
  font-weight: bold;
}

.station-row-wrapper {
  display: flex;
  flex: 1;
  margin-bottom: 15px;
}

.station-row-wrapper input{
  margin-bottom: 0;
}

.update-station{
  float: right;
  margin-bottom: 20px;
}

.station-edit-component .page-content {
  margin-top: 115px;
}

.station-edit-component .page-header ul{
  margin-bottom: 0 !important;
}

</style>
