import { render, staticRenderFns } from "./MediaEdit.vue?vue&type=template&id=3bf64164&scoped=true"
import script from "./MediaEdit.vue?vue&type=script&lang=js"
export * from "./MediaEdit.vue?vue&type=script&lang=js"
import style0 from "./MediaEdit.vue?vue&type=style&index=0&id=3bf64164&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bf64164",
  null
  
)

export default component.exports