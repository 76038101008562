<template>
  <div class="users-all-component">

    <div class="page-header">
      <div class="typeahead-wrapper">
        <div class="typeahead">
          <input id="input" class="form-control" type="text" placeholder="Find user by email..." v-model="query"  @keyup.13="search">
          <div class="btn btn-search" @click="search">
            <i class="fas fa-search"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th scope="col">User</th>
          <th scope="col" v-for="(mod, index) in modules" :key="index">
            {{mod}}
          </th>
          <th>Controls</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(user, $i) in users" :key="user._id">
          <td scope="row">
            <strong>{{user.fullname}}<br/></strong>
            {{user.email}}
          </td>
          <td v-for="(permit, index) in user.permissions" :key="index" :class="{videos_td: permit.modul === 'videos'}">

            <input type="checkbox" v-model="permit.permition" />

            <div class="can_publish" v-if="permit.modul === 'videos'">
              <input type="checkbox" v-model="permit.canPublish" @change="modifyVideosPermissions($i)"/>
              <span>Can publish</span>
            </div>

            <button v-if="permit.modul === 'locations' && permit.permition" @click="addLocations($i)">Add</button>
            <button v-if="permit.modul === 'podcasts' && permit.permition" @click="addPodcasts($i)">Add</button>
          </td>
          <td class="user-controls">
            <span @click="viewUserActivity(user)">View User Activity</span>
            <span @click="updateUser(user)">Update</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <b-modal id="addLocations" ref="addLocations" :ok-only="true" ok-title="Add" size="md" title="Select Locations">
      <div class="row modal-wrapper">
        <div class="col-12">
          <ul class="locations-list">
            <li v-for="location in locations" v-bind:key="location._id" @click="selectResource('locations', location)" v-bind:class="{selected: getResourceSelected('locations', location)}">
              <div class="location-list-card-img">
                <img src="/static/img/256x256.png" alt="" />
              </div>
              <div class="location-info">
                <div class="location-title">{{location.regionName}}</div>
                <div class="location-latLong">{{location.lat}} / {{location.long}}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-modal>

    <b-modal id="addPodcasts" ref="addPodcasts" :ok-only="true" ok-title="Add" size="md" title="Select Podcasts">
      <div class="row modal-wrapper">
        <div class="col-12">
          <p class="podcast-warning"><strong>Note: </strong> any person uploading to podcasts <strong>MUST</strong> have access to media or else they will not be allowed to upload.</p>
          <ul class="locations-list">
            <li v-for="podcast in podcasts" v-bind:key="podcast._id" @click="selectResource('podcasts', podcast)" v-bind:class="{selected: getResourceSelected('podcasts', podcast)}">
              <div class="location-list-card-img podcast-img">
                <img :src="podcast.meta.exploreImagery.hdpiImageURL" alt="" v-if="podcast.meta && podcast.meta.exploreImagery && podcast.meta.exploreImagery.hdpiImageURL" />
                <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!podcast.meta || !podcast.meta.exploreImagery || !podcast.meta.exploreImagery.hdpiImageURL"/>
              </div>
              <div class="location-info">
                <div class="location-title">{{podcast.title}}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-modal>

    <b-modal id="userActivity" ref="userActivity" ok-title="Exit" size="lg" :title="`User Activity for ${userForActivityModal.email}`" :cancel-disabled="true">
      <div class="row modal-wrapper">
        <div class="col-12">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>Section</th>
                <th>ID</th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="action in userActivities" :key="action">
              <td>
                {{formatDate(action.date)}}<br/>
                <span class="text-muted">({{formatDateAgo(action.date)}})</span>
              </td>
              <td style="text-transform: capitalize">{{action.resourceType}}</td>
              <td><code>{{action.resource._id}}</code></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>

  </div>
</template>


<script>
  import moment from 'moment'
  export default {
    name: 'UsersAll',
    data () {
      return {
        query: '',
        users: [],
        modules: [
          'artists',
          'cards',
          'categories',
          'concerts',
          'configurations',
          'contests',
          'dashboard',
          'livestreams',
          'locations',
          'media',
          'notifications',
          'playlists',
          'podcasts',
          'stations',
          'tests',
          'users',
          'videos',
          'moments',
          'shows',
          'videoSection',
          'support',
          'charts'
        ],
        userForActivityModal: {},
        userActivities: [],
        locations: [],
        podcasts: [],
        userToEdit: 0
      }
    },
    computed: {
    },
    mounted () {
      this.$http.get(`/users`)
        .then((res) => {
          this.users = res.data
          this.users = this.mapUser(this.users)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    methods: {
      formatDate (date) {
        return moment(new Date(date)).format('MMMM Do YYYY, h:mm:ss a')
      },
      formatDateAgo (date) {
        return moment(new Date(date)).fromNow()
      },
      search () {
        if (this.query !== '') {
          this.$http.get(`/users/search?q=${this.query}`)
            .then((res) => {
              this.users = res.data
              this.users = this.mapUser(this.users)
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          this.$http.get(`/users`)
            .then((res) => {
              this.users = res.data
              this.users = this.mapUser(this.users)
            })
            .catch((err) => {
              console.log(err)
            })
        }
      },
      updateUser (user) {
        let userCopy = JSON.parse(JSON.stringify(user))
        userCopy.permissions = userCopy.permissions.filter((permit) => {
          return permit.permition
        }).map((item) => {
          return item.modul
        })

        this.$http.put('/users', {user: userCopy})
          .then((res) => {
            this.$notify({
              group: 'notifications',
              title: `The user with email <u>${user.email}</u> has been updated successfully. <br/><br/>Please ask that user to log out and back in to see their new permissions :)`,
              text: '',
              type: 'success'
            })
          })
          .catch((err) => {
            console.log(err)
          })
      },
      mapUser (users) {
        return users.map((user) => {
          let data = []
          let canPublish = false

          this.modules.forEach(mod => {
            if (!user.permissions.includes(mod)) {
              data.push({
                modul: mod,
                permition: false
              })
            }

            user.permissions.forEach((permit) => {
              if (permit === mod) {
                if (permit === 'videos') {
                  if (user.videos[0] === 'publish') {
                    canPublish = true
                  }
                  data.push({
                    modul: mod,
                    permition: true,
                    canPublish: canPublish
                  })
                } else {
                  data.push({
                    modul: mod,
                    permition: true
                  })
                }
              }
            })
          })

          user.permissions = data

          return user
        })
      },
      addLocations (userIndex) {
        EventHandler.emit('isLoading', true)
        this.userToEdit = userIndex
        this.$http.get('/locations', {params: {limit: 0, page: 1}})
          .then((res) => {
            this.locations = res.data
            EventHandler.emit('isLoading', false)
            this.$refs.addLocations.show()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      addPodcasts (userIndex) {
        EventHandler.emit('isLoading', true)
        this.userToEdit = userIndex
        this.$http.get('/podcasts', {params: {limit: 0, page: 1}})
          .then(async (res) => {
            this.podcasts = res.data.podcasts
            EventHandler.emit('isLoading', false)
            this.$refs.addPodcasts.show()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      viewUserActivity (user) {
        this.userForActivityModal = user
        this.$refs.userActivity.show()
        this.$http.get(`/users/${user._id}/activity`)
          .then((res) => {
            this.userActivities = res.data
          }).catch((err) => {
            console.log(err)
          })
      },
      selectResource (userResource, resource) {
        var index = this.users[this.userToEdit][userResource].indexOf(resource._id)
        if (index !== -1) {
          this.users[this.userToEdit][userResource].splice(index, 1)
          return
        }

        this.users[this.userToEdit][userResource].push(resource._id)
      },
      getResourceSelected (userResource, resource) {
        let selected = false
        this.users[this.userToEdit][userResource].forEach(onUser => {
          if (onUser === resource._id) {
            selected = true
          }
        })

        return selected
      },
      modifyVideosPermissions ($i) {
        if (this.users[$i].videos[0] === 'publish') {
          this.users[$i].videos = []
        } else {
          this.users[$i].videos.push('publish')
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .users-all-component .btn-search {
    height: 45px;
    background: #f2494a;
    border-radius: 0;
    color: #fff;
    line-height: 32px;
  }

  .user-controls span, td button {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    background-color: #f2494a;
    color: #fff;
    padding: 5px 12px;
  }

  td button {
    margin-left: 15px;
  }

  .table th,
  .table td{
    padding: 1.3rem 0.75rem;
    vertical-align: middle;
  }

  .table th{
    border-top: none;

  }

  .table thead th {
    border-bottom: 1px solid #dee2e6
  }

  th {
    text-transform: capitalize;
  }

  ul.locations-list {
    list-style-type: none;
    overflow-y: scroll;
    max-height: 400px;
    padding: 3px;

    .location-info {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: space-between;
      padding: 0 20px;
    }
  }

  ul.locations-list .location-title,
  ul.locations-list .location-latLong {
    font-size: 12px;
    font-weight: bold;
  }

  ul.locations-list li {
    list-style-type: none;
    display: flex;
    background-color: #fff;
    margin-bottom: 10px;
    height: 60px;
    align-items: center;
    border: 1px solid #eaeaea;
    cursor: pointer;
  }

  ul.locations-list li.selected {
    background-color: #eaeaea;
  }

  ul.locations-list li .location-list-card-img {
    margin: 0 15px;
  }

  ul.locations-list li .location-list-card-img.podcast-img {
    margin-left: 0;
  }

  ul.locations-list li .location-list-card-img img{
    height: 45px;
    width: auto;
  }

  ul.locations-list li .location-list-card-img.podcast-img img{
    height: 58px;
    width: auto;
  }

  .videos_td {
    min-width: 125px;
  }

  .videos_td .can_publish span{
    font-size: 11px;
    font-weight: bold;
  }
  .podcast-warning {
    background: #fff7f7;
    padding: 1em;
    border: #ef9b9b 1px solid;
    margin-bottom: 10px;
  }
</style>
