<template>
  <div class="podcast-form">
    <section>
      <h4>Basic Information</h4>
      <hr>
      <div class="row">
        <div class="col-6">
          <b-form-group>
            <label class="has-form-text">English Title<span>*</span></label>
            <b-form-input placeholder="Top Hits" v-model="podcast.meta.titleEnglish"></b-form-input>
            <span class="form-text text-muted">
              Title used for LaMusica.com single page and on card rows.
            </span>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group>
            <label class="has-form-text">Spanish Title<span>*</span></label>
            <b-form-input placeholder="Los Exitos de Ahora" v-model="podcast.meta.titleSpanish"></b-form-input>
            <span class="form-text text-muted">
              Title used for LaMusica.com single page and on card rows.
            </span>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group>
            <label class="has-form-text">English Secondary Title</label>
            <b-form-input v-model="podcast.meta.secondaryTitleEnglish"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group>
            <label class="has-form-text">Spanish Secondary Title</label>
            <b-form-input v-model="podcast.meta.secondaryTitleSpanish"></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group>
            <label class="has-form-text">English Description<span>*</span></label>
            <b-form-textarea rows="5" v-model="podcast.meta.descriptionEnglish"></b-form-textarea>
            <span class="form-text text-muted">Please provide a high-level summary about this podcast.</span>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group>
            <label class="has-form-text">Spanish Description<span>*</span></label>
            <b-form-textarea rows="5" v-model="podcast.meta.descriptionSpanish"></b-form-textarea>
            <span class="form-text text-muted">Please provide a high-level summary about this podcast.</span>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group>
            <label class="has-form-text">Slug<span>*</span></label>
            <b-form-input v-model="podcast.meta.slug"></b-form-input>
            <span class="form-text text-muted">Please add a unique, lowercase slug for sharing & for LaMusica app/web.</span>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <b-form-group>
            <b-form-checkbox v-model="podcast.meta.hideTitle" switch>Hide Title</b-form-checkbox>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group>
            <b-form-checkbox id="checkbox2" v-model="podcast.meta.isFeatured" switch>Is Featured</b-form-checkbox>
          </b-form-group>
        </div>
        <div class="col-4" v-if="isEdit">
          <b-form-group>
            <b-form-checkbox v-model="podcast.published" switch>Published</b-form-checkbox>
          </b-form-group>
        </div>
      </div>
    </section>

    <AdConfigurationForm
        @validateCheckBox="validateCheckBox"
        :item="podcast.meta"
        :has-wide-orbit-companion-ads="false"
        :has-web-display-ads="true"
        v-if="isEdit"
        class="mt-3"
    />
  </div>
</template>
<script>
import AdConfigurationForm from "@/components/share/AdConfigurationForm.vue";

export default {
  name: 'PodcastForm',
  components: { AdConfigurationForm },
  props: {
    podcast: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    validateCheckBox (object) {
      console.log(object)
      if (object.btn === 0 && object.value) {
        this.podcast.meta.isSponsored = false
      } else if (object.btn === 1 && object.value) {
        this.podcast.meta.hasCompanionAdPreroll = false
      }
    },
  }
}
</script>
