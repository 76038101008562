<template>
    <div>
        <b-row>
            <b-col class="text-center">
                <p>Click to select</p>
            </b-col>
        </b-row>
    <b-row v-if="thumbnails.options" >
        <b-col >
            <b-img 
                v-for="(eachThumb, idx) in thumbnails.options" class="p-1 thumb-img" 
                :class="eachThumb.id === thumbnails.selected.id ? 'selected' : ''"
                :key="idx"
                width="120"
                @click="setThumbnail(eachThumb)"
                :src="eachThumb.url">
                <!-- class="p-1"  -->
            </b-img>
        </b-col>
    </b-row>
    </div>
</template>

<script>
export default {
    name:'ThumbnailSelection',
    props:['thumbnails'],
    mounted(){
        console.log('selection',this.thumbnails);
        if(!this.thumbnails.selected){
            this.thumbnails.selected = {
                id:'',
                url:''
            }
        }
    },
    methods:{
        setThumbnail(image){
            this.$emit('setThumbnail', image)
        }
    }
}
</script>

<style scoped>
.thumb-img{
    cursor: pointer;
}

.thumb-img.selected{
    cursor: pointer;
    border: 4px solid red;
}
</style>