<template>
  <div class="podcasts-component">
    <router-view :key="$route.fullPath"/>
  </div>
</template>


<script>

export default {
  name: 'Podcasts',
  data () {
    return {
    }
  },

  mounted () {
  },

  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .podcast-item-wrapper {
    .item-header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .breadcrumb {
        background: transparent;

        .breadcrumb-item {
          text-transform: uppercase;
          font-size: 16px;
          a {
            text-transform: uppercase;
            text-decoration: none;
            color: #2c3e50;
            font-weight: 400;
          }
        }
      }

      .actions {
        .btn {
          vertical-align: top;
          display: inline-block;
          font-size: 12px;
          font-weight: 700;
          text-transform: uppercase;
          cursor: pointer;
          border: 1px solid #2c3e50;
          padding: 5px 10px;
          border-radius: 4px;
          text-decoration: none;
          background: #fff;
          color: #2c3e50;

          &.page-action {
            border: 1px solid red;
            background-color: #f2494a;
            color: #fff;
          }
        }
      }
    }
  }

  .podcast-form {
    hr {
      margin-top: 0;
    }

    fieldset {
      font-size: 14px;
      margin-bottom: 15px;

      input, textarea {
        font-size: 14px;
        margin-bottom: 0;
      }

      input {
        height: 35px;
      }
    }

    label {
      font-weight: 700;
      margin-bottom: 5px;

      span {
        color: #f2494a;
      }

      .has-form-text {
        margin-bottom: .125em;
      }
    }

    .form-text {
      font-size: 13px;
      margin-top: 3px;
    }
  }

</style>
