<template>
    <div>
      <div class="page-header">
        <div class="row align-items-center header">
          <div class="col-9">
            <div class="title">
              <router-link :to="`/admin/shows/edit/${$route.params.showid}`">Back to Show</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content">
        <ShowEpisodesForm :episodeId="$route.params.id"/>
      </div>
    </div>
</template>


<script>
import ShowEpisodesForm from '@/components/admin/shows/episodes/Form';
export default {
    name:'ShowEpisodesEdit',
    components:{
      ShowEpisodesForm
    }
}
</script>

