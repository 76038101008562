<template>
    <div class="videos-categories-component">
        <div class="page-header">
            <div class="typeahead-wrapper">
                <div class="typeahead">
                <b-input-group prepend="Search">
                    <b-form-input v-model="query" @keyup.13.native="search"></b-form-input>
                    <b-btn variant="danger" @click="resetSearch">Reset</b-btn>
                </b-input-group>
                </div>
                <router-link class="add-btn" to="/admin/moments/new" tag="div">Create Moment</router-link>
            </div>
            
        </div>

        <div class="page-content">
            <b-row v-if="moments.length>0">
                <b-col cols="3" v-for="moment in moments" :key="moment._id" >
                    <div class="d-flex flex-row moment-item">
                        <div @click="editMoment(moment._id)">
                        <b-img rounded :src="getMomentImg(moment.image)" width="120"></b-img>
                    </div>
                    <div class="d-flex flex-column pl-2 moment-meta flex-grow-1">
                        <div class="moment-title"> {{ moment.title.en }} </div>
                        <div class="mt-1 moment-description"> {{ moment.description.en }} </div>
                        <div class="mt-1 flex-grow-1"> {{ getLastUpdated(moment) }} </div>
                        <div class="d-flex flex-column w-100">
                            <b-btn class="p-1 m-1" size="sm" :variant="publishBtnVariant(moment.published)" @click.stop="unPublishMoment(moment)">
                                {{ moment.published? 'Unpublish' : 'Publish' }}
                            </b-btn>
                            <b-btn class="p-1 m-1" size="sm" variant="danger" @click.stop="deleteMoment(moment)">
                                Delete
                            </b-btn>
                        </div>
                        

                        <!-- <b-icon-trash class="option-item" font-scale="1.5" variant="danger" @click.stop="deleteMoment(moment._id)"></b-icon-trash> -->
                    </div>
                    </div>
                </b-col>
            </b-row>

            <div v-else class="text-center">
                <h4>To create a new moment, click Create Moment button</h4>
            </div>

            <div v-if="!searching">
                <b-pagination v-if="pages > 1" align="right" size="md" :total-rows="count" v-model="current" :per-page="limit" @change="getMoreMoments"></b-pagination>
            </div>
            <div v-else class="text-center my-2">
                If you do not see the item in the list, try to refine the search query...
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { DateTime as LuxonDateTime } from 'luxon'
import MomentValidations from './Validation.js'

export default {
    mounted(){
        this.getMoments()
    },
    data(){
        return {
            limit: 20,
            pages: 1,
            current: 1,
            count: 0,
            query:'',
            searching:false,
            moments: []
        }
    },
    methods:{
        publishBtnVariant(published){
            if(published){
                return 'danger'
            }
            return 'success'
        },
        async deleteMoment(moment){
            if(moment.published){
                    this.$notify({
                        group: 'notifications',
                        title: 'You must unpublish the moment before deleting it',
                        text: '',
                        type: 'error'
                        })
                        return
            }
            const check = confirm("Do you want to delete the moment?");
            if(check){
                const res= await axios.delete(`${this.$http.url}/moments/${moment.id}`)
                if(res.data.success){
                    this.getMoments()
                }
            }
        },
        async unPublishMoment(moment){
            const alertText = `Do you want to ${moment.published? 'unpublish' : 'publish'} the moment?`
            const check = confirm(alertText);
            if(check){
                const id = moment._id
                const action = !moment.published
                if(action){
                    const isMetadataValid = MomentValidations.metadataValidationForPublishedMoment(moment)
                    if(!isMetadataValid){
                    this.$notify({
                            group: 'notifications',
                            title: 'Missing required fields',
                            text: 'All fields except link is required to update a published moment',
                            type: 'error'
                        })
                        return 
                    }
                    moment.published = action
                    const res = await axios.put(`${this.$http.url}/moments/${id}`, moment)
                    if(res.data.success){
                        this.getMoments()
                    }
                }else{
                    console.log('unpublished toggling publish');
                    const isRemoved = await this.$http.put(`/video-section/remove-resource/moment/${moment._id}`)
                    if(!isRemoved.success){
                        this.$notify({
                            group: 'notifications',
                            title: 'Cannot unpublish moment',
                            text: 'Failed to remove moment from video section',
                            type: 'error'
                        })
                        return false
                    }

                    moment.published = !moment.published
                    const res = await axios.put(`${this.$http.url}/moments/${id}`, moment)
                    if(res.data.success){
                        this.getMoments()
                    }
                }
            }
        },
        getMomentImg(img){
            if(img && img.url !==''){
                return img.url
            }
            return 'https://placehold.co/1080x1920'
        },
        editMoment(id){
            this.$router.push('moments/edit/' + id)
        },
        getMoreMoments(page){
            this.current = page
            this.getMoments()
        },
        async getMoments(){
            let url = `${this.$http.url}/moments?limit=${this.limit}&page=${this.current}`
            const response = await axios.get(url)
            this.moments = response.data.data.items
            this.count = response.data.data.count
            this.pages = Math.ceil(response.data.data.count / this.limit)
        },
        getLastUpdated (moment) {
            if (moment.updated_at) {
            const date = new Date(moment.updated_at)
            const timeAgoString = LuxonDateTime.fromJSDate(date).toRelative()
            return `Last Updated: ${timeAgoString}`
            }
        },
        async search(){
            if(this.query===''){
                this.resetSearch()
                return
            }
            this.searching = true
            let url = `${this.$http.url}/moments?q=${this.query}&limit=64&page=1` // setting limit as multiple of 4
            const response = await axios.get(url)
            console.log('re', response);
            this.moments = response.data.data.items
        },
        resetSearch(){
            console.log('sreset');
            this.searching = false
            this.query = ''
            this.getMoments()
        }
    }
}
</script>

<style scoped>
.moment-item{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 10px 0px;
    cursor: pointer;
}

.moment-title{
    font-size: 14px;
    font-weight: bold;
}
.moment-description{
  max-width: 100%;
  width: 100%;
  height: 6em;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.moment-meta{
    font-size: 12px;
}
/* .moment-title-container{
    color: white;
    width: 120px;
    padding: 10px 0px;
    background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.2));
    position:absolute;
    bottom: 2px;
} */

/* .moment-options{
    width: 120px;
    padding-left: 4px;
    padding-right: 4px;
} */

.option-item{
    cursor: pointer;
}
</style>