<template>
  <div class="cards-edit-component">

    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-3">
          <div class="title">
            <router-link to="/admin/cards">Cards </router-link>/ new {{cardType}}
          </div>
        </div>
        <div class="col-9">
          <div class="controls-wrapper">
            <ul>
              <li class="add-playlist" :class="{active: cardType === 'video'}" @click="selectType('video')">Editorial Video</li>
              <li class="add-playlist" :class="{active: cardType === 'moment'}" @click="selectType('moment')">Moments</li>
              <li class="add-playlist" :class="{active: cardType === 'playlist'}" @click="selectType('playlist')">Playlist</li>
              <li class="add-playlist" :class="{active: cardType === 'podcast'}" @click="selectType('podcast')">Podcast</li>
              <li class="add-playlist" :class="{active: cardType === 'contest'}" @click="selectType('contest')">Web Link</li>
              <li class="add-playlist" :class="{active: cardType === 'station'}" @click="selectType('station')">Station</li>
              <li class="add-playlist" :class="{active: cardType === 'media'}" @click="selectType('media')">Podcast Episode</li>
              <li class="add-playlist" :class="{active: cardType === 'artist'}" @click="selectType('artist')">Artist</li>
              <li class="add-playlist" :class="{active: cardType === 'livestream'}" @click="selectType('livestream')">Livestream</li>
              <li class="add-playlist" :class="{active: cardType === 'show'}" @click="selectType('show')">Show</li>
              <li class="add-playlist" :class="{active: cardType === 'show-episode'}" @click="selectType('show-episode')">Show Episode</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <playlist-type v-if="cardType === 'playlist'" :card="card" :type="'create'"></playlist-type>
      <video-type v-if="cardType === 'video'" :card="card" :type="'create'"></video-type>
      <moment-type v-if="cardType === 'moment'" :card="card" :type="'create'"></moment-type>
      <podcast-type v-if="cardType === 'podcast'" :card="card" :type="'create'"></podcast-type>
      <contest-type v-if="cardType === 'contest'" :card="card" :type="'create'"></contest-type>
      <station-type v-if="cardType === 'station'" :card="card" :type="'create'"></station-type>
      <media-type v-if="cardType === 'media'" :card="card" :type="'create'"></media-type>
      <artist-type v-if="cardType === 'artist'" :card="card" :type="'create'"></artist-type>
      <livestream-type v-if="cardType === 'livestream'" :card="card" :type="'create'"></livestream-type>
      <show-type v-if="cardType === 'show'" :card="card" :type="'create'"></show-type>
      <show-episode-type v-if="cardType === 'show-episode'" :card="card" :type="'create'"></show-episode-type>
    </div>

  </div>
</template>

<script>
import { DateTime as LuxonDateTime } from 'luxon'
import playlistType from './types/PlaylistType'
import videoType from './types/VideoType'
import MomentType from './types/MomentType'
import podcastType from './types/PodcastType'
import contestType from './types/ContestType'
import stationType from './types/StationType'
import mediaType from './types/MediaType'
import artistType from './types/ArtistType'
import livestreamType from './types/LivestreamType'
import ShowType from './types/ShowType'
import ShowEpisodeType from './types/ShowEpisodeType'

export default {
  name: 'CardsNew',
  components: {
    playlistType,
    videoType,
    podcastType,
    contestType,
    stationType,
    mediaType,
    artistType,
    livestreamType,
    ShowType,
    ShowEpisodeType,
    MomentType
  },
  data () {
    return {
      card: {
        titleEnglish: '',
        titleSpanish: '',
        subtitleEnglish: '',
        subtitleSpanish: null,
        id: null,
        img: [],
        video: [],
        available: {
          start: LuxonDateTime.local().toISO(),
          end: LuxonDateTime.fromObject({ year: 2025, month: 12, day: 31, hour: 0, minute: 0 }).toISODate()
        }
      },
      cardType: this.$route.params.cardType || 'video'
    }
  },
  destroyed () {
    this.$store.commit('duplicateCard', {})
  },
  created () {
    if (Object.keys(this.$store.getters.card).length > 0) {
      this.card = this.$store.getters.card
    }
  },
  methods: {
    selectType (type) {
      return this.$router.push('/admin/cards/new/' + type)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cards-edit-component .header{
  margin-bottom: 45px;
}

.cards-edit-component .title{
  margin-bottom: 0;
}

.cards-edit-component  .create-card {
  width: 150px;
  line-height: 45px;
  text-align: center;
  background: #f2494a;
  margin-left: 15px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.cards-edit-component .controls-wrapper{
  align-items: center;
}

.cards-edit-component .controls-wrapper span{
  margin-right: 10px;
}

.cards-edit-component .controls-wrapper ul{
  margin-bottom: 0 !important;
  max-width: none;
}

.cards-edit-component .controls-wrapper ul .add-playlist.active{
  background-color: #f2494a;
  color: #fff;
}
</style>
