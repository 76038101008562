<template>
  <div class="cards-edit-component">
    <editors v-if="cardID" :resource="cardID" />

    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-3">
          <div class="title">
            <router-link to="/admin/cards">Cards </router-link>/ Edit {{cardType}}
          </div>
        </div>
        <div class="col-9">
          <div class="controls-wrapper">
            <ul>
              <li class="add-playlist" :class="{active: cardType === 'video'}">Video</li>
              <li class="add-playlist" :class="{active: cardType === 'moment'}">Moments</li>
              <li class="add-playlist" :class="{active: cardType === 'playlist'}">Playlist</li>
              <li class="add-playlist" :class="{active: cardType === 'podcast'}">Podcast</li>
              <li class="add-playlist" :class="{active: cardType === 'contest'}">Web Link</li>
              <li class="add-playlist" :class="{active: cardType === 'station'}">Station</li>
              <li class="add-playlist" :class="{active: cardType === 'media'}">Episode</li>
              <li class="add-playlist" :class="{active: cardType === 'artist'}">Artist</li>
              <li class="add-playlist" :class="{active: cardType === 'livestream'}">Livestream</li>
              <li class="add-playlist" :class="{active: cardType === 'show'}">Show</li>
              <li class="add-playlist" :class="{active: cardType === 'show-episode'}">Show Episode</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <playlist-type v-if="cardType === 'playlist'" :card="card" :type="'update'"></playlist-type>
      <video-type v-if="cardType === 'video'" :card="card" :type="'update'"></video-type>
      <moment-type v-if="cardType === 'moment'" :card="card" :type="'update'"></moment-type>
      <podcast-type v-if="cardType === 'podcast'" :card="card" :type="'update'"></podcast-type>
      <contest-type v-if="cardType === 'contest'" :card="card" :type="'update'"></contest-type>
      <station-type v-if="cardType === 'station'" :card="card" :type="'update'"></station-type>
      <media-type v-if="cardType === 'media'" :card="card" :type="'update'"></media-type>
      <artist-type v-if="cardType === 'artist'" :card="card" :type="'update'"></artist-type>
      <livestream-type v-if="cardType === 'livestream'" :card="card" :type="'update'"></livestream-type>
      <show-type v-if="cardType === 'show'" :card="card" :type="'update'"></show-type>
      <show-episode-type v-if="cardType === 'show-episode'" :card="card" :type="'update'"></show-episode-type>
    </div>

  </div>
</template>

<script>
import playlistType from './types/PlaylistType'
import videoType from './types/VideoType'
import podcastType from './types/PodcastType'
import contestType from './types/ContestType'
import stationType from './types/StationType'
import mediaType from './types/MediaType'
import artistType from './types/ArtistType'
import livestreamType from './types/LivestreamType'
import Editors from '../../share/Editors'
import ShowType from './types/ShowType'
import ShowEpisodeType from './types/ShowEpisodeType'
import MomentType from './types/MomentType'

export default {
  name: 'CardsEdit',
  components: {
    playlistType,
    videoType,
    podcastType,
    contestType,
    stationType,
    mediaType,
    artistType,
    livestreamType,
    Editors,
    ShowType,
    ShowEpisodeType,
    MomentType
  },
  data () {
    return {
      cardID: null,
      card: {},
      cardType: ''
    }
  },
  mounted () {
    this.cardID = this.$route.params.id
    this.$http.get(`/cards/${this.cardID}`)
      .then((res) => {
        this.card = res.data
        this.cardType = this.card.type
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    selectType (type) {
      this.cardType = type
    },
    create () {
      console.log('create card')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cards-edit-component .header{
  margin-bottom: 45px;
}

.cards-edit-component .title{
  margin-bottom: 0;
}

.cards-edit-component  .create-card {
  width: 150px;
  line-height: 45px;
  text-align: center;
  background: #f2494a;
  margin-left: 15px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.cards-edit-component .controls-wrapper{
  align-items: center;
}

.cards-edit-component .controls-wrapper span{
  margin-right: 10px;
}

.cards-edit-component .controls-wrapper ul{
  margin-bottom: 0 !important;
  max-width: none;
}

.cards-edit-component .controls-wrapper ul .add-playlist.active{
  background-color: #f2494a;
  color: #fff;
}
</style>
