<template>
    <div>
        <div>
            <b-form-input v-model="query" placeholder="Scene title..." debounce="500"></b-form-input>
        </div>

        <b-row>
            <b-col >
                <div v-if="searchResults.length>0" class="list-view">
                    <div v-for="eachVideo of searchResults" :key="eachVideo._id" class="m-1">

                        <b-form-checkbox
                        @input="updateSelected(eachVideo.checked, eachVideo.data._id, eachVideo)"
                        v-model="eachVideo.checked">
                        <div class="d-flex">
                            <div class="mx-2">
                            <b-img :src="eachVideo.data.images.thumbnail.hdpiImageURL" width="100px"></b-img>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="mx-2 v-title">{{ eachVideo.data.title.en }}</div>
                            </div>
                            
                        </div>
                        </b-form-checkbox>
                    </div>

                    <div class="mt-4 d-flex justify-content-center">
                        <b-btn variant="danger" @click="submitShowList">Add To Show</b-btn>
                    </div>

                </div>
                <div v-else class="text-center">
                    Search Results will appear here
                </div>
                
            </b-col >
            <!-- <b-col >
                <div v-if="Object.keys(selectedVideos).length>0" class="list-view">
                    <div v-for="(eachVideo, key) in selectedVideos" :key="key" class="m-1">
                        <div class="d-flex">
                            <div class="mx-2">
                            <b-img :src="eachVideo.images.thumbnail.hdpiImageURL" width="100px"></b-img>
                            </div>
                            <div class="mx-2 l-title">{{ eachVideo.title.en }}</div>
                            <b-btn @click="updateSelected(false, key)">X</b-btn>
                        </div>
                    </div>

                    <div class="mt-4 d-flex justify-content-end">
                        <b-btn variant="danger" @click="submitShowList">Add To Show</b-btn>
                    </div>
                </div>
                <div v-else class="text-center">
                    List of selected videos
                </div>
            </b-col> -->
        </b-row>
    </div>
</template>

<script>
export default {
    name:'SceneSearch',
    data(){
        return{
            query:'',
            searchResults:[],
            selectedVideos:{},
            options: [
            { text: 'Red', value: 'red' },
            { text: 'Green', value: 'green' },
            { text: 'Yellow (disabled)', value: 'yellow', disabled: true },
            { text: 'Blue', value: 'blue' }
        ]
        }
    },
    watch:{
        query(){
            // console.log('q', this.query);
            this.searchResults=[]
            this.searchScenes()
        }
    },
    methods:{
        submitShowList(){
            this.$emit('update-list', this.selectedVideos)
        },
        updateSelected(added, videoId, selected = null){
            const selId = videoId
            if(added){
                this.selectedVideos[selId] = selected.data    
            }else{
                this.$delete(this.selectedVideos,selId)
            }
        },
        async searchVideo(){
            const vids = await this.$http.get(`/videos/search?q=${this.query}&type=title&limit=20`)
            console.log('v', vids);
            if(vids.success){
                this.searchResults= vids.data.map( (e) =>  {
                    const c = this.selectedVideos[e._id] !== undefined
                    return {checked: this.selectedVideos[e._id] !== undefined, data: e}
                } )
            }
        },
        async searchScenes(){
            const vids = await this.$http.get(`/videos/search?q=${this.query}&type=title&limit=20`)
            console.log('v', vids);
            if(vids.success){
                this.searchResults= vids.data.map( (e) =>  {
                    const c = this.selectedVideos[e._id] !== undefined
                    return {checked: this.selectedVideos[e._id] !== undefined, data: e}
                } )
            }
        }

    }
}
</script>

<style scoped>

.v-title{
    font-weight: 500;
}

.l-title{
    font-size: smaller;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 3.6em; /* (Number of lines you want visible) * (line-height) */
    line-height: 1.2em;
}

.list-view{
    background-color: white;
    height: 100%;
    background-clip: content-box;
}
</style>