export default class VideosCommon {
  constructor ({ context }) {
    this.context = context
  }

  getDefaultInitialState () {
    return {
      showShadow: false,
      encodingLandscape: false,
      encodingPortrait: false
    }
  }

  handleScroll () {
    let scrollPosition = window.scrollY
    this.context.showShadow = scrollPosition > 5
  }

  handleCreated () {
    window.addEventListener('scroll', () => {
      this.handleScroll()
    })
  }
  handleDestroyed () {
    window.removeEventListener('scroll', () => {
      this.handleScroll()
    })
  }

  static isURL (str) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
    return pattern.test(str)
  }

  validateContent (event) {
    event.preventDefault()
    const $this = this.context
    const video = $this.video
    if (video.published) {
      const sendErrorMessage = (message) => {
        $this.$notify({
          group: 'notifications',
          title: message,
          text: '',
          type: 'error'
        })
        return false
      }

      if (!video.file.portrait.m3u8 && !video.file.landscape.m3u8) {
        return sendErrorMessage('At least one video file needs to be uploaded :(')
      }

      const imageNodes = [
        /* Featured Image */
        'featured.2xImageURL',
        'featured.3xImageURL',
        'featured.mdpiImageURL',
        'featured.hdpiImageURL',
        'featured.xhdpiImageURL',
        'featured.xxhdpiImageURL',
        'featured.xxxhdpiImageURL',
        /* Thumbnail */
        'thumbnail.2xImageURL',
        'thumbnail.3xImageURL',
        'thumbnail.mdpiImageURL',
        'thumbnail.hdpiImageURL',
        'thumbnail.xhdpiImageURL',
        'thumbnail.xxhdpiImageURL',
        'thumbnail.xxxhdpiImageURL',
        /* Landing */
        'landing.1xImageURL',
        'landing.2xImageURL',
        'landing.3xImageURL',
        /* Row */
        'row.mdpiImageURL',
        'row.2xImageURL',
        'row.3xImageURL',
        'row.xxxhdpiImageURL',
        /* Card */
        'card.2xImageURL',
        'card.3xImageURL'
      ]

      const videoImages = video.images

      for (let i = 0; i < imageNodes.length; i++) {
        const split = imageNodes[i].split('.')
        const key = split[0]
        const resolution = split[1]
        const imageNode = videoImages[key]
        const label = key === 'landing' ? 'lanscape' : key

        // If doesn't have a landscape video
        // don't do checks for "landing" node
        if (key === 'landing' && !video.file.landscape.m3u8) {
          continue
        }

        if (Object.keys(imageNode).length === 0) {
          return sendErrorMessage(`The ${label} image is required :(`)
        }

        if (imageNode && (!imageNode[resolution] || !VideosCommon.isURL(imageNode[resolution]))) {
          return sendErrorMessage(`The "${label}" image is missing a resized image at the "${resolution}" resolution, please try uploading it again so you can publish :(`)
        }
      }

      if (video.available.start >= video.available.end) {
        return sendErrorMessage('<code>Start Date</code> should be before <code>End date</code>')
      }
    }

    return true
  }

  static getImageSizesState () {
    const imageSizes = [
      {
        imageKey: 'featured',
        title: 'Portrait Featured Image',
        required: true,
        description: 'Click here to upload the asset for iOS/Android featured section, web videos section, or AppleTV header section',
        width: 2304,
        height: 3072
      },
      {
        imageKey: 'landing',
        title: 'TV Header Image (Right-aligned)',
        required: true,
        description: 'Click here to upload the right-aligned asset used for AppleTV, FireTV, Roku, Amazon Fire TV, or Android TV homepage header section',
        width: 1920,
        height: 850
      },
      {
        imageKey: 'landscape_centered',
        title: 'Web Header Image (Center-aligned)',
        required: true,
        description: 'Click here to upload the center-aligned assets for the web rotator section',
        width: 1920,
        height: 850
      },
      {
        imageKey: 'thumbnail',
        title: 'Thumbnail Image',
        required: true,
        description: 'Click here to upload the thumbnail used for the iOS/Android videos and TV list views',
        width: 1296,
        height: 560
      },
      {
        imageKey: 'card',
        title: 'Homepage Card Image (Top Cards Section)',
        required: true,
        description: ' Click here to upload the card image used in the LaMusica iOS, Android, and mobile web featured card section',
        width: 1000,
        height: 1100
      },
      {
        imageKey: 'row',
        title: 'Homepage Card Image (Lower Rows)',
        required: true,
        description: 'Click here upload the card image used in the LaMusica iOS, Android, and web lower row section',
        width: 1440,
        height: 1440
      }
    ]

    for (const imageSize of imageSizes) {
      const widthByHeight = `${imageSize.width}x${imageSize.height}`
      imageSize.description += ` sized at ${widthByHeight}.`
      imageSize.loading = false
      imageSize.valid = false
      imageSize.store = {}
      imageSize.placeholder = `Please insert a ${widthByHeight} jpg or png`
    }

    return imageSizes
  }

  mapVideoForSave (video) {
    const $videoCopy = JSON.parse(JSON.stringify(video))
    $videoCopy.artists = $videoCopy.artists.map(artist => artist._id)
    $videoCopy.tags = $videoCopy.tags.map(tag => tag._id)
    console.log('vc', $videoCopy);
    return $videoCopy
  }
}
