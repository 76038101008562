import Vue from 'vue'
import Vuex from 'vuex'
import oauth2 from '@/store/oauth2'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    card: {}
  },
  mutations: {
    duplicateCard (state, card) {
      state.card = card
    }
  },
  getters: {
    card: state => state.card
  },
  modules: {
    oauth2
  }
})
