import Vue from 'vue'
import Router from 'vue-router'
import Axios from 'axios'

// Homepage Routes
import Homepage from '@/components/home/Homepage'
import login from '@/components/home/login'
import register from '@/components/home/register'
import forgot from '@/components/home/forgot'
import newPassword from '@/components/home/newPassword'

// Admin Routes
import Adminpage from '@/components/admin/Adminpage'

import Categories from '@/components/admin/categories/Categories'
import CategoriesAll from '@/components/admin/categories/CategoriesAll'
import CategoryNew from '@/components/admin/categories/CategoryNew'
import CategoryEdit from '@/components/admin/categories/CategoryEdit'

import Playlists from '@/components/admin/playlists/Playlists'
import PlaylistsAll from '@/components/admin/playlists/PlaylistsAll'
import PlaylistNew from '@/components/admin/playlists/PlaylistNew'
import PlaylistEdit from '@/components/admin/playlists/PlaylistEdit'

// import PodcastsAllMedia from '@/components/admin/podcasts/PodcastsAllMedia'

import Podcasts from '@/components/admin/podcasts/Podcasts'
import PodcastsAll from '@/components/admin/podcasts/PodcastsAll'
import PodcastNew from '@/components/admin/podcasts/PodcastNew'
import DownloadAudio from '@/components/admin/podcasts/DownloadAudio'
import PodcastEdit from '@/components/admin/podcasts/PodcastEdit'
import PodcastEpisodes from '@/components/admin/podcasts/PodcastEpisodes'

import Videos from '@/components/admin/videos/Videos'
import VideosAll from '@/components/admin/videos/VideosAll'
import VideosNew from '@/components/admin/videos/VideosNew'
import VideosEdit from '@/components/admin/videos/VideosEdit'
import VideosCategories from '@/components/admin/videos/VideosCategories'
import AdRules from '@/components/admin/videos/sectionAds/AdRules'

import Shows from '@/components/admin/shows/Shows'
import ShowsAll from '@/components/admin/shows/ShowsAll'
import ShowsEdit from '@/components/admin/shows/ShowsEdit'
import ShowsNew from '@/components/admin/shows/ShowsNew'
import ShowEpisodesAll from '@/components/admin/shows/episodes/All'
import ShowEpisodesNew from '@/components/admin/shows/episodes/New'
import ShowEpisodesEdit from '@/components/admin/shows/episodes/Edit'

import Moments from '@/components/admin/moments/Moments'
import MomentsAll from '@/components/admin/moments/MomentsAll'
import MomentsEdit from '@/components/admin/moments/MomentsEdit'
import MomentsNew from '@/components/admin/moments/MomentsNew'

import VideoBuilder from '../components/admin/videos/builder/VideoBuilder'
// import BuilderAll from '../components/admin/videos/builder/BuilderAll'

import Cards from '@/components/admin/cards/Cards'
import CardsAll from '@/components/admin/cards/CardsAll'
import CardsNew from '@/components/admin/cards/CardsNew'
import CardsEdit from '@/components/admin/cards/CardsEdit'

import Builder from '@/components/admin/builder/Builder'
import BuilderAll from '@/components/admin/builder/BuilderAll'
import BuilderEdit from '@/components/admin/builder/BuilderEdit'

import Stations from '@/components/admin/stations/Stations'
import StationsAll from '@/components/admin/stations/StationsAll'
import StationsEdit from '@/components/admin/stations/StationsEdit'
import StationsNew from '@/components/admin/stations/StationsNew'

import Livestreams from '@/components/admin/livestreams/Livestreams'
import LivestreamsAll from '@/components/admin/livestreams/LivestreamsAll'
import LivestreamsEdit from '@/components/admin/livestreams/LivestreamsEdit'
import LivestreamsNew from '@/components/admin/livestreams/LivestreamsNew'

import Contests from '@/components/admin/contests/Contests'
import ContestsAll from '@/components/admin/contests/ContestsAll'
import ContestsEdit from '@/components/admin/contests/ContestsEdit'
import ContestsNew from '@/components/admin/contests/ContestsNew'
import Concerts from '@/components/admin/concerts/Concerts'

import Media from '@/components/admin/media/Media'
import MediaAll from '@/components/admin/media/MediaAll'
import MediaNew from '@/components/admin/media/MediaNew'
import MediaEdit from '@/components/admin/media/MediaEdit'

import Artists from '@/components/admin/artists/Artists'
import ArtistsAll from '@/components/admin/artists/ArtistsAll'
import ArtistsNew from '@/components/admin/artists/ArtistsNew'
import ArtistsEdit from '@/components/admin/artists/ArtistsEdit'

import Users from '@/components/admin/users/Users'
import UsersAll from '@/components/admin/users/UsersAll'

import Dashboard from '@/components/admin/dashboard/Dashboard'

import Tests from '@/components/admin/tests/Tests'
import OneSignalEdit from '@/components/admin/tests/OneSignalEdit'
import OneSignalAll from '@/components/admin/tests/OneSignalAll'

import Notifications from '@/components/admin/notifications/Notifications'

import Configurations from '@/components/admin/configurations/Configurations'
import ConfigurationsAll from '@/components/admin/configurations/ConfigurationsAll'
import ConfigurationsEdit from '@/components/admin/configurations/ConfigurationsEdit'
import ConfigurationsNew from '@/components/admin/configurations/ConfigurationsNew'

import AdsAll from '@/components/admin/tests/AdsAll'
import AdsEdit from '@/components/admin/tests/AdsEdit'

import Support from '@/components/admin/support/Support'

import Charts from '@/components/admin/charts/Charts'
import ChartsAll from '@/components/admin/charts/ChartsAll'
import ChartsEdit from '@/components/admin/charts/ChartsEdit'

import AssetsManagement from '@/components/admin/assets/AssetsManagement'

import Http from '../components/share/http.js'
import Oauth2 from '../components/home/oauth2'

Vue.use(Router)
const http = new Http()

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Homepage,
      children: [
        {
          path: '/',
          component: login
        },
        {
          path: 'register',
          component: register
        },
        {
          path: 'forgot',
          component: forgot
        },
        {
          path: 'recover/:userID/:token',
          component: newPassword
        },
        {
          path: 'oauth2/:vendor',
          component: Oauth2
        }
      ]
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Adminpage,
      children: [
        {
          path: 'categories',
          component: Categories,
          children: [
            {
              path: '/',
              component: CategoriesAll
            },
            {
              path: 'new',
              component: CategoryNew
            },
            {
              path: 'edit/:id',
              component: CategoryEdit
            }
          ],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'playlists',
          component: Playlists,
          children: [
            {
              path: '/',
              component: PlaylistsAll
            },
            {
              path: 'new',
              component: PlaylistNew
            },
            {
              path: 'edit/:id',
              component: PlaylistEdit
            }
          ],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'podcasts',
          component: Podcasts,
          children: [
            {
              path: '/',
              component: PodcastsAll,
              name: 'podcast-all'
            },
            {
              path: 'new',
              component: PodcastNew,
              name:'podcast-new'
            },
            {
              path: 'download-audio',
              component: DownloadAudio,
              name:'download-audio'
            },
            {
              path: ':id/edit',
              component: PodcastEdit,
              name: 'podcast-edit'
            },
            {
              path: ':id/episodes',
              component: PodcastEpisodes,
              name: 'podcast-episodes'
            }
          ],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'videos',
          component: Videos,
          children: [
            {
              path: '/',
              component: VideosAll
            },
            {
              path: 'new',
              component: VideosNew
            },
            {
              path: 'categories-all',
              component: VideosCategories
            },
            {
              path: 'ads',
              component: AdRules
            },
            {
              path: 'edit/:id',
              component: VideosEdit
            }
          ],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'shows',
          component: Shows,
          children: [
            {
              path: '/',
              component: ShowsAll
            },
            {
              path: 'new',
              component: ShowsNew
            },
            {
              path: 'edit/:id',
              component: ShowsEdit
            },
            {
              path: 'edit/:showid/episodes/edit/:id',
              component: ShowEpisodesEdit
            }
            // {
            //   path: 'episodes',
            //   component: ShowEpisodesAll
            // },
            // {
            //   path: 'episodes/new',
            //   component: ShowEpisodesNew
            // },
            // {
            //   path: 'episodes/edit/:id',
            //   component: ShowEpisodesEdit
            // }
          ],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'videoSection',
          component: VideoBuilder,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'moments',
          component: Moments,
          children: [
            {
              path: '/',
              component: MomentsAll
            },
            {
              path: 'new',
              component: MomentsNew
            },
            {
              path: 'edit/:id',
              component: MomentsEdit
            }
          ],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'media',
          component: Media,
          children: [
            {
              path: '/',
              component: MediaAll
            },
            // {
            //   path: 'podcast',
            //   component: PodcastsAllMedia
            // },
            {
              path: 'new',
              component: MediaNew
            },
            {
              path: 'edit/:id',
              component: MediaEdit
            }
          ],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'artists',
          component: Artists,
          children: [
            {
              path: '/',
              component: ArtistsAll
            },
            {
              path: 'new',
              component: ArtistsNew
            },
            {
              path: 'edit/:id',
              component: ArtistsEdit
            }
          ],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'cards',
          component: Cards,
          children: [
            {
              path: '/',
              component: CardsAll
            },
            {
              path: 'new',
              component: CardsNew
            },
            {
              path: 'new/:cardType',
              component: CardsNew
            },
            {
              path: 'edit/:id',
              component: CardsEdit
            }
          ],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'locations',
          component: Builder,
          children: [
            {
              path: '/',
              component: BuilderAll
            },
            {
              path: 'edit/:id',
              component: BuilderEdit
            }
          ],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'stations',
          component: Stations,
          children: [
            {
              path: '/',
              component: StationsAll
            },
            {
              path: 'new',
              component: StationsNew
            },
            {
              path: 'edit/:id',
              component: StationsEdit
            }
          ],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'livestreams',
          component: Livestreams,
          children: [
            {
              path: '/',
              component: LivestreamsAll
            },
            {
              path: 'new',
              component: LivestreamsNew
            },
            {
              path: 'edit/:id',
              component: LivestreamsEdit
            }
          ],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'contests',
          component: Contests,
          children: [
            {
              path: '/',
              component: ContestsAll
            },
            {
              path: 'new',
              component: ContestsNew
            },
            {
              path: 'edit/:id',
              component: ContestsEdit
            }
          ]
        },
        {
          path: 'concerts',
          component: Concerts,
          children: [],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'users',
          component: Users,
          children: [
            {
              path: '/',
              component: UsersAll
            }
          ],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'tests',
          component: Tests,
          children: [
            {
              path: '/',
              component: OneSignalAll
            },
            {
              path: 'onesignal/edit/:id',
              component: OneSignalEdit
            },
            {
              path: 'ads',
              component: AdsAll
            },
            {
              path: 'ads/edit/:id',
              component: AdsEdit
            }
          ],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'notifications',
          component: Notifications,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'configurations',
          component: Configurations,
          children: [
            {
              path: '/',
              component: ConfigurationsAll
            },
            {
              path: 'new',
              component: ConfigurationsNew
            },
            {
              path: 'edit/:id',
              component: ConfigurationsEdit
            }
          ],
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'assets',
          component: AssetsManagement,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'support',
          component: Support,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'charts',
          component: Charts,
          children: [
            {
              path: '/',
              component: ChartsAll
            },
            {
              path: 'edit/:id',
              component: ChartsEdit
            }
          ],
          meta: {
            requiresAuth: true
          }
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') === null) {
      next({
        path: '/'
      })
    } else {
      http.post('/auth/validate')
        .then((res) => {
          if (res.success) {
            next()
          } else {
            localStorage.removeItem('token')
            Axios.defaults.headers.common['Authorization'] = `Bearer`
            next({
              path: '/'
            })
          }
        })
        .catch((err) => {
          console.log(err)
          localStorage.removeItem('token')
          Axios.defaults.headers.common['Authorization'] = `Bearer`
          next({
            path: '/'
          })
        })
    }
  } else {
    next()
  }
})

export default router
