<template>
    <div>
        <div class="page-header">
          <div class="row align-items-center header">
            <div class="col-9">
              <div class="title">
                <router-link to="/admin/moments">Moments</router-link>/
                <span>New</span>
              </div>
            </div>
          </div>
        </div>
  
        <div class="page-content">
            <div>
                <MomentsForm :moment="moment" @saveMoment="createMoment" />
            </div>

        </div>
    </div>
</template>

<script>
import MomentsForm from './MomentsForm.vue'
import axios from 'axios'
import MomentValidations from './Validation.js'

export default {
  components: {
    MomentsForm,
  },
  data() {
    return {
      imgSrc: '/static/img/LaMusica-Icon-playlist-placeholder.jpg',
      cropImgMobile: '',
      moment:{
        title:{
            en:'',
            es:''
        },
        description:{
            en:'',
            es:''
        },
        published:false,
        link:'',
        slug:'',
        image:{
          url:'',
          id:''
        },
        video:{
          url:'',
          id:''
        }
      },
      cropImgTv: '',
      data: null,
      aspectRatioOptions:[
        9 / 16,
        16 / 9
      ],
      aspectRatioSelection: 0
    };
  },
  methods:{
    async createMoment(form){
        
        const url = `${this.$http.url}/moments`
        const validateUrl = `${this.$http.url}/moments/validate-slug?slug=${form.slug}`
        const validationRes = await axios.get(validateUrl)
        const isSlugUnique = validationRes.data.data

        if(isSlugUnique){
            this.$notify({
                group: 'notifications',
                title: 'Slug already exists',
                text: '',
                type: 'error'
            })
            return
        }else{
          if(form.published){
            const isMetadataValid = MomentValidations.metadataValidationForPublishedMoment(form)
              if(!isMetadataValid){
              this.$notify({
                    group: 'notifications',
                    title: 'Missing required fields',
                    text: 'All fields except link is required to create a published moment',
                    type: 'error'
                })
                return 
              }
          }else{
            const isMetadataValid = MomentValidations.metadataValidationForUnpublishedMoment(form)
            if(!isMetadataValid){
              this.$notify({
                    group: 'notifications',
                    title: 'Title & Slug Missing',
                    text: 'Title and slug is required to create a moment entry',
                    type: 'error'
                })
                return
            }
          }
          
          const response = await axios.post(url, form)
          if(response.data.success){
            this.$notify({
                group: 'notifications',
                title: 'New Moment created',
                text: '',
                type: 'success'
            })
            this.$router.push('/admin/moments')
          }
          // console.log('createMoment',response);
        }
    }
  }

}
</script>