<template>
  <div class="builder-edit-component">
    <editors v-if="locationID" :resource="locationID" />

    <div class="header-wrapper">
      <div class="title">
        <router-link to="/admin/locations">Locations </router-link>/
        <span>{{location.regionName}}</span>
      </div>
      <div class="btn-group">
        <div class="preview-location" @click="preview">Preview</div>
        <div class="update-location" @click="update">Update</div>
      </div>
    </div>

    <div class="row">
      <div class="col-8">
        <div class="location-area-outer">
          <draggable v-model="location.rows" class="location-area" :options="{group:{ name:'row', put: ['layout'], pull: ['row']}}" :move="cancelEventOnFirstRows">
            <div class="row-wrapper" v-for="(row, rowIndex) in location.rows" :key="rowIndex">
              <div v-if="row['type'] !== 'ads'">
                <div class="row-title">
                  {{row.titleEnglish || 'Row English Title'}} <span>/ {{row.titleSpanish || 'Row Spanish Title'}}</span>
                </div>
                <div class="row-type" v-if="rowIndex !== 0 && rowIndex !== 1">
                  <img src="/static/img/Drag_and_drop_icon.png" alt="" />
                </div>
                <div class="remove-row">
                  <div class="submenu-actions">
                    <div class="icon">
                      <i class="fas fa-ellipsis-h"></i>
                    </div>
                    <div class="actions">
                      <ul>
                        <li @click="editRow(row, rowIndex)">Edit</li>
                        <li v-if="rowIndex > 1 && (row.automated === 'none' || !row.automated)" @click="duplicateRowToLocations(row)">Duplicate To Locations</li>
                        <li @click="confirmRemoveRow(rowIndex)" v-if="rowIndex > 1">Remove</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <draggable v-model="row.items" class="card-area" :options="{group:{ name:'card', put:['newCard', 'card']}}" :class="{featured: rowIndex === 0, stations: rowIndex === 1, automated: row.automated && row.automated.indexOf('automated')> -1, carousel: row.type === 'carousel', landscape: row.image_type === 'landscape'}"
                           :move="checkCard" @start="showAlert = true" :index="rowIndex" v-if="Array.isArray(row.items)">

                  <div class="card-wrapper" v-for="(card, cardIndex) in row.items"
                       :key="card._id" :class="{featured: rowIndex === 0}">
                    <div class="card-wrapper-each" v-if="card">
                      <div class="card-img" :style="getCardStyle(card)">
                        <img :src="card.img[0]['750x750']" alt="" v-if="rowIndex !== 0"/>
                        <img :src="card.img[0]['1000x1100']" alt="" v-if="rowIndex === 0"/>
                      </div>
                      <div class="card-title" v-if="(card.titleSpanish || card.titleEnglish) && rowIndex !== 0 && row.displayTitles">
                        {{card.titleSpanish ? card.titleSpanish : card.titleEnglish}}
                      </div>
                      <div class="remove-row remove-row-card" @click="removeCard(rowIndex, cardIndex)">
                        <i class="fas fa-times"></i>
                      </div>
                      <div class="duplicate-card" @click="duplicateRowToLocations(null, card)" v-if="rowIndex === 0">
                        Duplicate
                      </div>
                    </div>
                  </div>

                  <div v-if="row.items.length === 0 && row.automated && row.automated.indexOf('automated')> -1">
                    <h5>{{automated_row_types[location.rows[rowIndex].automated].title}}</h5>
                    <p>{{automated_row_types[location.rows[rowIndex].automated].description}}</p>
                  </div>

                  <!-- <div v-if="row.type === 'recommend' && row.automated">
                    {{ row.automated }}
                    <h5>{{ recommended_row_descriptor[row.automated].title }}</h5>
                    <p>{{ recommended_row_descriptor[row.automated].description }}</p>
                  </div> -->
                </draggable>
              </div>

              <div class="ads-row" v-if="row['type'] === 'ads'">
                <div class="row-title">
                  {{row.titleEnglish || 'Row English Title'}} <span>/ {{row.titleSpanish || 'Row Spanish Title'}}</span>
                </div>
                <div class="remove-row">
                  <div class="submenu-actions">
                    <div class="icon">
                      <i class="fas fa-ellipsis-h"></i>
                    </div>
                    <div class="actions">
                      <ul>
                        <li @click="editRow(row, rowIndex)">Edit</li>
                        <li v-if="rowIndex > 1 && (row.automated === 'none' || !row.automated)" @click="duplicateRowToLocations(row)">Duplicate To Locations</li>
                        <li @click="confirmRemoveRow(rowIndex)" v-if="rowIndex > 1">Remove</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="ads-form">
                  <b-form-row>
                    <b-col>
                      <strong class="ads-label">Background Image <strong class='required'>*</strong></strong>
                      <b-form-group>
                        <div class="ads-image-wrapper">
                          <img :src="row.bg_img" alt="" v-if="row.bg_img" class="ads-bg-img">
                        </div>
                        <small>Background creative (for iOS & Android)</small>
                        <b-form-file class="ads-img-input"
                                     v-model="row.bg_img"
                                     placeholder="876×1633px"
                                     @change.native="uploadAdsImage($event, 876, 1633, 'bg_img', rowIndex)"
                                     :state="Boolean(row.bg_img)"></b-form-file>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <strong class="ads-label">Overlay Image <strong class='required'>*</strong></strong>
                      <b-form-group>
                        <div class="ads-image-wrapper">
                          <img :src="row.overlay_img" alt="" v-if="row.overlay_img" class="ads-bg-img">
                        </div>
                        <small>Overlay creative (for iOS & Android)</small>
                        <b-form-file class="ads-img-input"
                                     v-model="row.overlay_img"
                                     @change.native="uploadAdsImage($event, 869, 1095, 'overlay_img', rowIndex)"
                                     :state="Boolean(row.overlay_img)"
                                     placeholder="869x1095px"></b-form-file>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <strong class="ads-label">iPad Background Image <strong class='required'>*</strong></strong>
                      <b-form-group>
                        <div class="ads-image-wrapper">
                          <img :src="row.ipad_bg_img" alt="" v-if="row.ipad_bg_img" class="ads-bg-img">
                        </div>
                        <small>Background creative (for iPad/Tablets)</small>
                        <b-form-file class="ads-img-input"
                                     v-model="row.ipad_bg_img"
                                     @change.native="uploadAdsImage($event, 1536, 2048, 'ipad_bg_img', rowIndex)"
                                     :state="Boolean(row.ipad_bg_img)"
                                     placeholder="1536×2048px"></b-form-file>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <strong class="ads-label">iPad Overlay Image <strong class='required'>*</strong></strong>
                      <b-form-group>
                        <div class="ads-image-wrapper">
                          <img :src="row.ipad_overlay_img" alt="" v-if="row.ipad_overlay_img" class="ads-bg-img">
                        </div>
                        <small>Overlay creative (for iPad/Tablets)</small>
                        <b-form-file class="ads-img-input"
                                     v-model="row.ipad_overlay_img"
                                     @change.native="uploadAdsImage($event, 1536, 1024, 'ipad_overlay_img', rowIndex)"
                                     :state="Boolean(row.ipad_overlay_img)"
                                     placeholder="1536x1024px"></b-form-file>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <hr/>

                  <b-form-row>
                    <b-col>
                      <strong class="ads-label">URL <strong class='required'>*</strong></strong>
                      <b-form-group>
                        <b-form-input id="livestream_url"
                                      type="url"
                                      v-model="row.url"
                                      placeholder="Ads url text...">
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <br />

                  <b-form-row>
                    <b-col>
                      <strong class="ads-label">Pixel Tracker URL <strong class='required'>*</strong></strong>
                      <b-form-group>
                        <b-form-input id="livestream_pixel_tracker_url"
                                      type="url"
                                      required
                                      v-model="row.pixel_tracker_url"
                                      placeholder="Ads pixel tracker URL">
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <b-form-row class="justify-content-md-center">
                    <b-col>
                      <div class="datetime-label">Start date</div>
                      <datetime type="datetime"
                                v-model="row.available.start"
                                class="theme-orange"
                                :minDatetime="minDatetime"
                                :phrases="{ok: 'Continue', cancel: 'Exit'}"
                                use12-hour>
                      </datetime>
                    </b-col>

                    <b-col>
                      <div class="datetime-label">End date</div>
                      <datetime type="datetime"
                                v-model="row.available.end"
                                class="theme-orange"
                                :minDatetime="minDatetime"
                                :phrases="{ok: 'Continue', cancel: 'Exit'}"
                                use12-hour>
                      </datetime>
                    </b-col>
                  </b-form-row>

                </div>
                <draggable v-model="row.items" class="card-area ads-section" :options="{group:{ name:'card', put:['newCard', 'card']}}"
                           :move="checkCard" @start="showAlert = true" :index="rowIndex" v-if="Array.isArray(row.items)">

                  <div class="card-wrapper" v-for="(card, cardIndex) in row.items"
                       :key="card._id" :class="{featured: rowIndex === 0}">
                    <div class="card-wrapper-each" v-if="card">
                      <div class="card-img" :style="getCardStyle(card)">
                        <img :src="card.img[0]['750x750']" alt="" v-if="rowIndex !== 0"/>
                        <img :src="card.img[0]['1000x1100']" alt="" v-if="rowIndex === 0"/>
                      </div>
                      <div class="card-title" v-if="(card.titleSpanish || card.titleEnglish) && rowIndex !== 0 && row.displayTitles">
                        {{card.titleSpanish ? card.titleSpanish : card.titleEnglish}}
                      </div>
                      <div class="remove-row remove-row-card" @click="removeCard(rowIndex, cardIndex)">
                        <i class="fas fa-times"></i>
                      </div>
                    </div>
                  </div>

                  <div class="message" v-if="row.items.length === 0">
                    <strong>Drag & Drop card in this area to deep link parallax ads</strong>
                  </div>
                </draggable>
              </div>
              
              <div class="top20-row" v-if="row['type'] === 'top20'">
                <b-row>
                  <b-col lg="*" class="mx-auto">
                    <label>
                      <strong>Homepage Video Banner(Only For Mobile) *</strong>
                    </label>
                    <loader-inline v-if="videoImage.loading"/>
                    <div class="video-holder-card">
                      <div class="remove-video-card" v-if="row.video" @click="handleRemoveVideoImage">
                        <i class="fas fa-times"></i>
                      </div>
                      <div class="video-image" v-if="row.video" v-html="videoHTML"></div>
                    </div>
                    <b-form-file accept="video/mp4" ref="videoInput" v-model="videoImage.file" :placeholder="videoImage.placeholder" @change.native="handleUploadVideoImage($event)"></b-form-file>
                  </b-col>
                  <!-- col sep 
                    width: 1296,
          height: 560
                  -->
                  <b-col lg="*" class="mx-auto mt-2">
                    <strong class="ads-label">Backup Image <strong class='required'>*</strong></strong>
                      <b-form-group>
                        <div>
                          <b-img :src="row.image" alt="" v-if="row.image" class="top20-backup-image"/>
                        </div>
                        <small>Overlay creative (for iPad/Tablets)</small>
                        <b-form-file class="ads-img-input"
                                     v-model="row.image"
                                     @change.native="handleUploadBackupImage"
                                     :state="Boolean(row.image)"
                                     placeholder="1296x560px"></b-form-file>
                      </b-form-group>
                    </b-col>
                </b-row>
              </div>

            </div>
          </draggable>

          <div class="add-row" @click="addRow">
            Add Row
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="layout-picker">

          <div class="controls-wrapper">
            <ul>
              <li class="add-playlist" :class="{active: selected === 'cards'}" @click="selectTab('cards')">Cards</li>
              <li class="add-playlist" :class="{active: selected === 'meta'}" @click="selectTab('meta')">Meta</li>
            </ul>
          </div>

          <div class="cards-wrapper" v-show="selected === 'cards'">
            <div class="search-wrapper">
              <input type="text" placeholder="Find card..." v-model="queryCards" @keyup.13="search"/>
              <div class="btn btn-search-card" @click="search">
                <i class="fas fa-search"></i>
              </div>
            </div>

            <div class="card-type">
              <select v-model="cardTypeSelected" @change="getCardsType">
                <option value="video">Videos</option>
                <option value="playlist">Playlists</option>
                <option value="podcast">Podcasts</option>
                <option value="contest">Web Link</option>
                <option value="station">Stations</option>
                <option value="media">Episodes</option>
                <option value="livestream">Livestreams</option>
                <option value="show">Shows</option>
                <option value="show-episode">Show Episodes</option>
                <option value="moment">Moments</option>
              </select>

              <select v-model="cardsizeSelected" @change="getCardsType">
                <option value="all">All Images</option>
                <option value="1000x1100">Card Images</option>
                <option value="750x750">Row Images</option>
              </select>
            </div>

            <div class="cards-right-outer">
              <draggable v-model="cards" class="cards-right card-area"
                         :options="{group:{ name:'newCard',  pull:'clone', put:false }}"
                         @start="showAlert = true"
                         :move="editCardAfterDragged">
                <div class="card-wrapper" v-for="card in cards" :key="card._id">
                  <div class="card-img">
                    <img :src="card.img[0]['750x750']" alt="" v-if="card.img[0]['750x750']" />
                    <img :src="card.img[0]['1000x1100']" alt="" v-if="!card.img[0]['750x750']" />
                  </div>
                  <div class="card-title"
                       v-b-tooltip.hover.topright
                       :title="card.titleSpanish ? card.titleSpanish : card.titleEnglish"
                       v-if="card.titleSpanish || card.titleEnglish">
                    {{card.titleSpanish ? card.titleSpanish : card.titleEnglish}}
                  </div>
                </div>
              </draggable>
            </div>
          </div>

          <div class="meta-wrapper" v-show="selected === 'meta'">
            <div class="bootstrap-form">
              <div class="published-builder">
                <span>Published </span>
                <label class="switch">
                  <input type="checkbox" v-model="location.published">
                  <span class="slider"></span>
                </label>
              </div>

              <label>Region Name</label>
              <input type="text" v-model="location.regionName" />

              <label>Latitude</label>
              <input type="number" v-model="location.lat" />

              <label>Longitude</label>
              <input type="number" v-model="location.long" />

              <label>Radius (Kilometers)</label>
              <input type="number" v-model="location.radius" />

              <label>States</label>
              <input type="text" v-model="location.states" />
              <div class="note">* List all States separate by comma</div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <b-modal id="editRow" ref="editRow" ok-title="ok" size="md" title="Row Details"
                          @ok.prevent="handleOk" @cancel="handleCancel"
                          :hide-header-close="true"
                          :no-close-on-backdrop="true"
                          :no-close-on-esc="true">
      <div class="row modal-wrapper">
        <div class="col-6">
          <label>English Title</label>
          <input type="text" v-model="rowToEdit.titleEnglish"/>
        </div>
        <div class="col-6">
          <label>Spanish Title</label>
          <input type="text" v-model="rowToEdit.titleSpanish"/>
        </div>

        <div class="col-6">
          <label>English Subtitle</label>
          <input type="text" v-model="rowToEdit.subtitleEnglish"/>
        </div>
        <div class="col-6">
          <label>Spanish Subtitle</label>
          <input type="text" v-model="rowToEdit.subtitleSpanish"/>
        </div>

        <div class="col-12" v-if="location.type !== 'web'">
          <label>Row Type</label>
          <select v-model="rowToEdit.type">
            <option value="cards">Featured Cards</option>
            <option value="card">Regular Row</option>
            <option value="circular">Circular</option>
            <option value="carousel">Carousel</option>
            <option value="ads">Ads Row</option>
            <option value="top20">Top 20</option>
            <option value="moments">Moments</option>
          </select>
        </div>

        <div class="col-12" v-if="location.type === 'web'">
          <label>Row Type</label>
          <select v-model="rowToEdit.type">
            <option value="cards">Featured Cards</option>
            <option value="carousel">Carousel</option>
            <option value="grid">Grid</option>
            <option value="top20">Top 20</option>
            <option value="moments">Moments</option>
          </select>
        </div>

        <div class="col-12" v-if="rowToEdit.type === 'card' || rowToEdit.type === 'carousel' || rowToEdit.type === 'grid'">
          <label>Automated Type</label>
          <select v-model="rowToEdit.automated">
            <option value="none">Select Automated Type</option>
            <option v-for="(metadata, type) in automated_row_types" :value="type" :key="type">{{metadata.title}} (Automated)</option>
          </select>
        </div>

        <div class="col-12" v-if="location.type === 'web'" style="margin-top: 1em; margin-bottom: 1em;">
          <label>Images Type</label>
          <select v-model="rowToEdit.image_type" @change="checkCardSizes">
            <option value="landscape">Landscape</option>
            <option value="square">Square</option>
          </select>
          <ul>
            <li><strong>Landscape</strong>: This will use the wider 1920x850 images.</li>
            <li><strong>Square</strong>: This will use the square 750x750 images.</li>
          </ul>
        </div>

        <div class="col-6" v-if="location.type === 'web'">
          <label>Items Desktop Count</label>
          <input type="number" v-model="rowToEdit.items_desktop_count"/>
          <small>Maximum amount of items on mobile before the row wraps or carousel slides</small>
        </div>

        <div class="col-6" v-if="location.type === 'web'">
          <label>Items Mobile Count</label>
          <input type="number" v-model="rowToEdit.items_mobile_count"/>
          <small>Maximum amount of items on desktop before the row wraps or carousel slides</small>
        </div>

        <div class="col-12">
          <label>Display titles underneath each cell?</label>
          <select v-model="rowToEdit.displayTitles">
            <option :value="true">Show Titles</option>
            <option :value="false">Hide Titles</option>
          </select>
        </div>
        <div class="col-12">
          <label>Shuffle the items on this row?</label>
          <select v-model="rowToEdit.shuffle">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </div>
      </div>
    </b-modal>


    <b-modal id="duplicateRowToLocations" ref="duplicateRowToLocations" :ok-only="true" ok-title="Duplicate" @ok="handleDuplicateRowToLocation" size="md" title="Select Locations" @cancel="handleCancel">
      <div class="row modal-wrapper">
        <div class="col-12 duplicate-row-locations">
          <p>Please select which locations you would like this row's contents to be duplicated to.</p>
          <p>If a row by the same name exists on another location, selecting a location and choosing "duplicate" will overwrite the contents of that row.</p>
          <p>If a row does not exist by the same name, this row will be added at the bottom of that location</p>
          <ul>
            <li v-for="(location, index) in locations" v-bind:key="index" @click="handleDuplicateLocationSelected(location)" v-bind:class="{selected: location.selected}">
              {{location.regionName}}
            </li>
          </ul>
        </div>
      </div>
    </b-modal>

    <b-modal id="confirmRemoveRow" ref="confirmRemoveRow" size="md" title="Confirm" @ok="removeRow">
      <div class="row modal-wrapper">
        <div class="col-12">
          Are you sure want to remove it?
        </div>
      </div>
    </b-modal>

    <b-modal id="previewLocationModal" ref="previewLocationModal" size="md" :title="location.regionName" :ok-only="true">
      <div class="modal-wrapper">
        <builder-preview :location="location"></builder-preview>
      </div>
    </b-modal>

  </div>
</template>


<script>
  import draggable from 'vuedraggable'
  import axios from 'axios'
  import moment from 'moment'
  import { Datetime } from 'vue-datetime'
  import { DateTime as LuxonDateTime } from 'luxon'
  import Editors from '../../share/Editors'
  import BuilderPreview from './preview/BuilderPreview'

  export default {
    name: 'Builder',
    components: {
      BuilderPreview,
      draggable,
      Datetime,
      Editors
    },
    computed:{
      videoHTML: function () {
        const top20Row = this.location.rows.find( (e) => e.type ==='top20' )
        return `<video controls width="500" ><source src="${top20Row.video}" type="video/mp4"></video>`
      }
    },
    data () {
      return {
        minDatetime: LuxonDateTime.local().toISO(),
        maxDatetime: LuxonDateTime.fromJSDate(new Date(new Date().getTime() + 2 * 7 * 24 * 60 * 60 * 1000)).toISODate(),
        locations: [],
        location: {},
        cards: [],
        selected: 'cards',
        videoImage:  {
          placeholder: '1296 x 560px',
          loading: false,
          width: 1296,
          height: 560
        },
        rowToEdit: {
          titleEnglish: 'English Title',
          titleSpanish: 'Título en Español',
          subtitleEnglish: 'English Subtitle',
          subtitleSpanish: 'Subtitulo en Español',
          displayTitles: true,
          shuffle: false,
          type: 'card',
          automated: 'none',
          image_type: 'square',
          items_desktop_count: 1,
          items_mobile_count: 1,
          items: [],
          aplitude_id: ''
        },
        rowIndexToEdit: null,
        rowToDuplicate: null,
        cardTypeSelected: 'video',
        cardsizeSelected: 'all',
        queryCards: '',
        rowIndexToDelete: 0,
        showAlert: true,
        automated_row_types: {
          automated_latest_videos: {
            title: 'Latest Videos',
            description: 'This automated row pulls the 20 most recent published videos sorted by date published, from newest to oldest.'
          },
          automated_latest_vacilon_videos: {
            title: 'El Vacilón Videos',
            description: 'This automated row pulls the 20 most recent published videos tagged under the "El Vacilón De La Mañana" primary category, sorted by date published, from newest to oldest.'
          },
          automated_latest_molusco_videos: {
            title: 'Los Reyes De La Punta Videos',
            description: 'This automated row pulls the 20 most recent published videos tagged under the "Molusco" primary category, sorted by date published, from newest to oldest.'
          },
          automated_latest_despelote_videos: {
            title: 'El Despelote Videos',
            description: 'This automated row pulls the 20 most recent published videos tagged under the "El Despelote" primary category, sorted by date published, from newest to oldest.'
          },
          automated_live_radio_shows: {
            title: 'Live Radio Shows',
            description: 'This row will appear displaying the currently available livestream shows.  At least 3 streams have to be up and running for this row to appear.'
          }
        },
        todayDate: moment().format(),
        locationID: null
      }
    },
    watch: {
      'rowToEdit.type': function (newValue, oldValue) {
        if(newValue === 'moments'){
          this.rowToEdit.automated = 'automated_latest_videos'
        }else if(this.rowToEdit.automated === 'automated_recommended_row'){
          this.rowToEdit.automated = 'none'
        }
      }
    },
    mounted () {
      EventHandler.emit('isLoading', true)
      this.locationID = this.$route.params.id
      console.log('id', this.$route);
      axios.all([this.getLocation(), this.getCards()]).then(axios.spread((location, cards) => {
        for (const row of location.data.rows) {
          if (row.type === 'ads' && !row.available) {
            row.available = {
              start: this.minDatetime,
              end: this.maxDatetime
            }
          }
        }
        this.location = location.data
        if (this.location.states) {
          this.location.states = this.location.states.join()
        }
        if (this.location.type === 'web') {
          this.rowToEdit.type = 'carousel'
        }
        this.cards = cards.data
        EventHandler.emit('isLoading', false)
      }))
    },
    methods: {
      handleUploadBackupImage(event){
        const input = event.target
        const formData = new FormData()
        const file = input.files && input.files[0]
        const top20RowIndex = this.location.rows.findIndex( (e) => e.type ==='top20' )
        if (!file) {
          return
        }
        const hasValidExtension = /\.(jpe?g|png)$/i.test(file.name)
        if (!hasValidExtension) {
          return $this.$notify({
            group: 'notifications',
            title: 'Images should be jpg or png format :(',
            text: '',
            type: 'error'
          })
        }
  
        formData.append('image', file)
        console.log('http posting');
        this.$http.post('/locations/image',
          formData,
          {
            headers:
              {
                'Content-Type': 'multipart/form-data'
              }
          }
        ).then((res) => {
          console.log('res', res);
            if (res.success) {
              this.$set(this.location.rows[top20RowIndex],'image',res.data[0])
              this.$notify({
                group: 'notifications',
                title: 'Image uploaded successfully :)',
                text: '',
                type: 'success'
              })
            } else {
              this.$notify({
                group: 'notifications',
                title: `${res.message} :(`,
                text: '',
                type: 'error'
              })
            }
            this.clearFormData(this.formData)
          }).catch((err) => {
            console.log(err)
            this.clearFormData(this.formData)
          })
      },
      handleUploadVideoImage(event){
        const input = event.target
        const formData = new FormData()
        const file = input.files && input.files[0]
        const top20RowIndex = this.location.rows.findIndex( (e) => e.type ==='top20' )
        if (!file) {
          return
        }
        const hasValidExtension = /\.(mp4)$/i.test(file.name)
        if (!hasValidExtension) {
          return $this.$notify({
            group: 'notifications',
            title: 'Video should be mp4 format :(',
            text: '',
            type: 'error'
          })
        }
        const fileSize = file.size / 1024 / 1024
        const isTooLargeUpload = fileSize > 3
        if (isTooLargeUpload) {
          return this.$notify({
            group: 'notifications',
            title: 'Video File size exceeds 3 MB :)',
            text: '',
            type: 'error'
          })
        }
        formData.append('file', file)
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        this.videoImage.loading = true
        this.$http.post('/cards/video', formData, config).then((res) => {
          if (res.success) {
            // this.trackToEdit.video = res.data[0]
            this.$set(this.location.rows[top20RowIndex],`video`,res.data[0])
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Video file is required :)',
              text: '',
              type: 'error'
            })
          }
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          this.videoImage.loading = false
        })
      },
      handleRemoveVideoImage () {
        const top20RowIndex = this.location.rows.findIndex( (e) => e.type ==='top20' )
        if(top20RowIndex > 0){
          this.location.rows[top20RowIndex].video = null
          this.videoImage.html = null
        }
      },
      duplicateFeaturedCard () {
        console.log(this.featureCardToDuplicate)
      },
      getCardStyle (card) {
        const style = {}
        const available = card.available
        if (available && available.start && available.end) {
          const isExpired = new Date(available.end).getTime() < Date.now()
          const willBeAvailableInFuture = new Date(available.start).getTime() > Date.now()
          if (isExpired) {
            style['border-left'] = '3px solid #f1494a'
          } else if (willBeAvailableInFuture) {
            style['border-left'] = '3px solid #e6d141'
          }
        }
        return style
      },
      search () {
        if (this.queryCards !== '') {
          this.$http.get('/cards', {params: {type: this.cardTypeSelected, size: this.cardsizeSelected, q: this.queryCards}})
            .then((res) => {
              this.cards = res.data
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          this.getCards().then((res) => {
            this.cards = res.data
          }).catch((err) => {
            console.log(err)
          })
        }
      },
      update () {
        // console.log(this.location)
        if (this.location.rows[0].items.length < 5) {
          this.$notify({
            group: 'notifications',
            title: 'At least 5 cards are required on Featured Row :(',
            text: '',
            type: 'error'
          })
          return
        }
        let locationCopy = JSON.parse(JSON.stringify(this.location))
        let hasValidImages = true
        let hasAdsRows = false

        for (let i = 0; i < locationCopy.rows.length; i++) {
          const row = locationCopy.rows[i]
          let validImage = true

          // if (row.hasOwnProperty('automated')) {
          //   delete row.automated
          // }

          if (row['type'] === 'ads') {
            hasAdsRows = true
          }

          if (row.type === 'carousel') {
            for (let j = 0; j < row.items.length; j++) {
              const item = row.items[j]

              if (!item.img[0]['1000x1100'] || (item.img[0]['1000x1100'] === '') || !item.img[0]['750x750'] || (item.img[0]['750x750'] === '')) {
                hasValidImages = false
                validImage = false
                console.log('Card with missing images ************************')
                console.log(item)
                break
              }
            }
            if (!validImage) {
              break
            }
          }
        }

        if (!hasValidImages) {
          return this.$notify({
            group: 'notifications',
            title: 'Images and video of 1000x1100px are required on Carousel Row:(',
            text: '',
            type: 'error'
          })
        }

        let hasValidAdsFields = true
        let isBelowRow8 = false
        let haveMoreThan5RowsAfter = true
        let lastAdsRow = 0
        let rowWithCards = 0

        for (let j = 0; j < locationCopy.rows.length; j++) {
          const row = locationCopy.rows[j]

          if (row.items) {
            row.items = row.items.map((item) => {
              return item._id
            })
            rowWithCards = rowWithCards + 1
          } else {
            if (!row['bg_img'] || row['bg_img'] === '' ||
                !row['overlay_img'] || row['overlay_img'] === '' ||
                !row['ipad_bg_img'] || row['ipad_bg_img'] === '' ||
                !row['ipad_overlay_img'] || row['ipad_overlay_img'] === '' ||
                !row['url'] || row['url'] === '') {
              hasValidAdsFields = false
              break
            }
            if (j <= 7) {
              isBelowRow8 = true
              break
            }
            if ((j - lastAdsRow) <= 5) {
              haveMoreThan5RowsAfter = false
              break
            }

            lastAdsRow = j
            rowWithCards = 0
          }

          if (j === (locationCopy.rows.length - 1) && (rowWithCards < 5)) {
            haveMoreThan5RowsAfter = false
          }
        }

        if (hasAdsRows) {
          if (!hasValidAdsFields) {
            return this.$notify({
              group: 'notifications',
              title: 'All Ads fields are required :(',
              text: '',
              type: 'error'
            })
          }

          if (isBelowRow8) {
            // return
            this.$notify({
              group: 'notifications',
              title: 'Ads row must be place below row number 8, but we are allowing it for testing ;)',
              text: '',
              type: 'warning'
            })
          }

          if (!haveMoreThan5RowsAfter) {
            // return
            this.$notify({
              group: 'notifications',
              title: 'Ads row must have minimum 5 content rows after, but we are allowing it for testing ;)',
              text: '',
              type: 'warning'
            })
          }
        }

        const locationNumericalProperties = ['lat', 'long', 'radius']

        for (let i = 0; i < locationNumericalProperties.length; i++) {
          const prop = locationNumericalProperties[i]

          if (parseFloat(locationCopy[prop])) {
            locationCopy[prop] = parseFloat(locationCopy[prop])
          } else {
            return alert(`${prop} is not a number, please edit and try again`)
          }
        }

        this.$http.put('/locations', {location: locationCopy})
          .then((res) => {
            this.$notify({
              group: 'notifications',
              title: `Location Updated successfully :)`,
              text: '',
              type: 'success'
            })
          })
          .catch((err) => {
            console.log(err)
            alert(`There was an error saving: ${err.message}`)
          })
      },
      confirmRemoveRow (index) {
        this.$refs.confirmRemoveRow.show()
        this.rowIndexToDelete = index
      },
      removeRow () {
        this.location.rows.splice(this.rowIndexToDelete, 1)
      },
      removeCard (rowIndex, cardIndex) {
        this.location.rows[rowIndex].items.splice(cardIndex, 1)
      },
      editRow (row, index) {
        this.rowToEdit = JSON.parse(JSON.stringify(row))
        this.rowIndexToEdit = index
        this.$refs.editRow.show()
      },
      duplicateRowToLocations (row, card) {
        if (row) {
          this.rowToDuplicate = row
        }
        if (card) {
          this.featureCardToDuplicate = card
        }
        this.$http.get(`/locations`).then((res) => {
          this.locations = res.data.map((location) => {
            location.selected = false
            return location
          })
          this.$refs.duplicateRowToLocations.show()
        }).catch((err) => {
          console.log(err)
          alert('There was an error fetching locations')
        })
      },
      addRow () {
        this.$refs.editRow.show()
      },
      handleCancel (evt) {
        this.resetRowToEdit()
        this.featureCardToDuplicate = {}
        this.$refs.editRow.hide()
      },
      handleOk () {
        const index = this.rowIndexToEdit
        if (index !== null) {
          this.location.rows[index].titleEnglish = this.rowToEdit.titleEnglish
          this.location.rows[index].titleSpanish = this.rowToEdit.titleSpanish
          this.location.rows[index].subtitleEnglish = this.rowToEdit.subtitleEnglish
          this.location.rows[index].subtitleSpanish = this.rowToEdit.subtitleSpanish
          this.location.rows[index].automated = this.rowToEdit.automated
          this.location.rows[index].displayTitles = this.rowToEdit.displayTitles
          this.location.rows[index].type = this.rowToEdit.type
          this.location.rows[index].shuffle = this.rowToEdit.shuffle
          this.location.rows[index].image_type = this.rowToEdit.image_type
          this.location.rows[index].items_desktop_count = this.rowToEdit.items_desktop_count
          this.location.rows[index].items_mobile_count = this.rowToEdit.items_mobile_count
          this.rowIndexToEdit = null
        } else {
          if (this.rowToEdit.type === 'ads') {
            this.location.rows.push({
              type: this.rowToEdit.type,
              automated: 'none',
              titleEnglish: this.rowToEdit.titleEnglish,
              titleSpanish: this.rowToEdit.titleSpanish,
              bg_img: null,
              overlay_img: null,
              ipad_bg_img: null,
              ipad_overlay_img: null,
              url: null,
              available: {
                start: this.minDatetime,
                end: this.maxDatetime
              },
              items: []
            })
          } else if (this.rowToEdit.type === 'top20'){
            const t20Index = this.location.rows.findIndex( (e)=> e.type ==='top20' )
            if(t20Index>0){
              this.resetRowToEdit()
              this.$refs.editRow.hide()
              return this.$notify({
                group: 'notifications',
                title: 'This location already has a Top 20 row',
                text: '',
                type: 'error'
              })
            }

            this.location.rows.push({
              type: this.rowToEdit.type,
              automated: this.rowToEdit.automated,
              titleEnglish: this.rowToEdit.titleEnglish,
              titleSpanish: this.rowToEdit.titleSpanish,
              subtitleEnglish: this.rowToEdit.subtitleEnglish,
              subtitleSpanish: this.rowToEdit.subtitleSpanish,
              items: [],
              displayTitles: this.rowToEdit.displayTitles,
              shuffle: false,
              image_type: 'square',
              items_desktop_count: this.rowToEdit.items_desktop_count,
              items_mobile_count: this.rowToEdit.items_mobile_count
            })
          } else {
            if(this.rowToEdit.type ==='recommend'){
              if(this.rowToEdit.automated === 'none'){
                return this.$notify({
                      group: 'notifications',
                      title: 'A recommendation row must contain a type',
                      text: '',
                      type: 'error'
                    })
              }
              for (const eachRow of this.location.rows) {
                 if (eachRow.automated && eachRow.automated === this.rowToEdit.automated ) {
                    // this.resetRowToEdit()
                    return this.$notify({
                      group: 'notifications',
                      title: `This location already has a ${this.rowToEdit.automated} recommendation row`,
                      text: '',
                      type: 'error'
                    })
                  }
              }
            }

            if(this.rowToEdit.type==='moments'){
              const momentsIndex = this.location.rows.findIndex((e)=> e.type==='moments')
              if(momentsIndex>-1){
                  return this.$notify({
                    group: 'notifications',
                    title: 'This location already has a moments row',
                    text: '',
                    type: 'error'
                  })
              }
            }
            const sv = {
              type: this.rowToEdit.type,
              automated: this.rowToEdit.automated,
              titleEnglish: this.rowToEdit.titleEnglish,
              titleSpanish: this.rowToEdit.titleSpanish,
              subtitleEnglish: this.rowToEdit.subtitleEnglish,
              subtitleSpanish: this.rowToEdit.subtitleSpanish,
              items: [],
              displayTitles: this.rowToEdit.displayTitles,
              shuffle: false,
              image_type: 'square',
              items_desktop_count: this.rowToEdit.items_desktop_count,
              items_mobile_count: this.rowToEdit.items_mobile_count
            }

            this.location.rows.push(sv)
          }
        }

        this.resetRowToEdit()
        this.$nextTick(() => {
          this.$refs.editRow.hide()
        })
      },
      resetRowToEdit () {
        this.rowIndexToEdit = null
        this.rowToEdit.type = this.location.type === 'web' ? 'carousel' : 'card' // 'cards' || 'grid'
        this.rowToEdit.titleEnglish = ''
        this.rowToEdit.titleSpanish = ''
        this.rowToEdit.subtitleEnglish = ''
        this.rowToEdit.subtitleSpanish = ''
        this.rowToEdit.automated = 'none'
        this.rowToEdit.displayTitles = false
        this.rowToEdit.shuffle = false
        this.rowToEdit.items_desktop_count = 4
        this.rowToEdit.items_mobile_count = 4
        this.rowToEdit.image_type = 'square'
        this.rowToEdit.items = []
      },
      checkCardSizes () {
        if (this.rowIndexToEdit && (this.rowToEdit.items.length > 0)) {
          let valid = true

          if (this.rowToEdit.image_type === 'square') {
            for (let index = 0; index < this.rowToEdit.items.length; index++) {
              const item = this.rowToEdit.items[index]
              if (!item.img[0]['750x750'] || item.img[0]['750x750'] === '') {
                valid = false
                break
              }
            }

            if (!valid) {
              this.rowToEdit.image_type = 'landscape'
              return this.$notify({
                group: 'notifications',
                title: 'All cards should have 750x750px images',
                text: '',
                type: 'error'
              })
            }
          }

          if (this.rowToEdit.image_type === 'landscape') {
            for (let index = 0; index < this.rowToEdit.items.length; index++) {
              const item = this.rowToEdit.items[index]
              if (!item.img[0]['1920x850'] || item.img[0]['1920x850'] === '') {
                valid = false
                break
              }
            }

            if (!valid) {
              this.rowToEdit.image_type = 'square'
              return this.$notify({
                group: 'notifications',
                title: 'All cards should have 1920x850px images',
                text: '',
                type: 'error'
              })
            }
          }
        }
      },
      handleDuplicateLocationSelected (location) {
        location.selected = !location.selected
      },
      handleDuplicateRowToLocation (evt) {
        // evt.preventDefault()
        if (this.rowToDuplicate) {
          const params = {
            row: this.rowToDuplicate,
            locations: this.locations.filter(loc => loc.selected).map(loc => loc._id)
          }

          this.$http.post(`/locations/duplicate_row`, params).then((res) => {
            alert(`Here's your report:\n• Rows Updated: ${res.data.matchingRowsInLocations}\n• Rows Created: ${res.data.missingRowsInLocations}`)
            this.$refs.duplicateRowToLocations.hide()
          }).catch((err) => {
            console.log(err)
            alert('There was an error duplicating to locations')
          })
        } else {
          const params = {
            featuredCard: this.featureCardToDuplicate._id,
            locations: this.locations.filter(loc => loc.selected).map(loc => loc._id)
          }

          this.$http.post(`/locations/duplicate_card`, params).then((res) => {
            this.$refs.duplicateRowToLocations.hide()

            this.$notify({
              group: 'notifications',
              title: res.message,
              text: '',
              type: 'success'
            })
          }).catch((err) => {
            console.log(err)
            alert('There was an error duplicating to locations')
          })
        }
      },
      clone (el) {
        return {
          titleEnglish: el.titleEnglish,
          titleSpanish: el.titleSpanish,
          type: el.type,
          items: el.items
        }
      },
      getLocation () {
        return this.$http.get(`/locations/${this.locationID}`)
      },
      getCards () {
        return this.$http.get('/cards', {
          params: {
            type: this.cardTypeSelected,
            size: this.cardsizeSelected,
            page_size: 50,
            web_location: this.location.type === 'web'
          }
        })
      },
      getRecommendConfig(){
        return this.$http.get('/locations/recommendation')
      },
      selectTab (option) {
        this.selected = option
      },
      getCardsType () {
        this.getCards()
          .then((res) => {
            this.cards = res.data
            this.queryCards = ''
          })
          .catch((err) => {
            console.log(err)
          })
      },
      editCardAfterDragged (evt) {
        // console.log('evt.draggedContext.element', evt.draggedContext.element)
        // console.log('editCardAfterDragged', evt.relatedContext.component.$el.classList)
        // console.log('evt.to.classList', evt.to.classList)
        if (evt.to.classList.contains('ads-section')) {
          if (evt.relatedContext.list.length === 1) {
            return false
          }
          return true
        }

        if (evt.to.classList.contains('featured')) {
          if (this.location.rows[0].items.length > 11) {
            if (this.showAlert) {
              this.$notify({
                group: 'notifications',
                title: 'Sorry. Only 12 cards are allowed :(',
                text: '',
                type: 'error'
              })
            }
            this.showAlert = false
            return false
          }
        }
        if (evt.to.classList.contains('stations') && evt.draggedContext.element.type !== 'station') {
          return false
        }
        let notValid = false
        let validImgSize = this.validateImageOnDragAndDrop(evt)

        evt.relatedContext.list.forEach((item) => {
          if (item._id === evt.draggedContext.element._id) {
            notValid = true
          }
        })

        if (notValid || !validImgSize) {
          return false
        }
      },
      validateImageOnDragAndDrop (evt) {
        if (this.location.type === 'web' && evt.to.classList.contains('landscape') && !evt.draggedContext.element.img[0]['1920x850'] && !evt.to.classList.contains('cards-right')) {
          if (this.showAlert) {
            this.$notify({
              group: 'notifications',
              title: 'Card image of 1920x850 is required',
              text: '',
              type: 'error'
            })
          }
          this.showAlert = false
          return false
        }
        if (evt.to.classList.contains('featured') && !evt.draggedContext.element.img[0]['1000x1100'] && !evt.to.classList.contains('cards-right')) {
          if (this.showAlert) {
            this.$notify({
              group: 'notifications',
              title: 'Card image of 1000x1100 is required',
              text: '',
              type: 'error'
            })
          }
          this.showAlert = false
          return false
        } else if (!evt.to.classList.contains('featured') && !evt.draggedContext.element.img[0]['750x750'] && !evt.to.classList.contains('cards-right')) {
          if (this.showAlert) {
            this.$notify({
              group: 'notifications',
              title: 'Card image of 750x750 is required',
              text: '',
              type: 'error'
            })
          }
          this.showAlert = false
          return false
        } else if (evt.to.classList.contains('carousel') && !evt.draggedContext.element.img[0]['1000x1100'] && !evt.to.classList.contains('cards-right')) {
          if (this.showAlert) {
            this.$notify({
              group: 'notifications',
              title: 'Card image of 1000x1100 is required',
              text: '',
              type: 'error'
            })
          }
          this.showAlert = false
          return false
        } else if (evt.to.classList.contains('carousel') && !evt.draggedContext.element.img[0]['750x750'] && !evt.to.classList.contains('cards-right')) {
          if (this.showAlert) {
            this.$notify({
              group: 'notifications',
              title: 'Card image of 750x750 is required',
              text: '',
              type: 'error'
            })
          }
          this.showAlert = false
          return false
        } else if (evt.to.classList.contains('automated')) {
          if (this.showAlert) {
            this.$notify({
              group: 'notifications',
              title: 'You cannot add any cards to this row, since it is an automated one',
              text: '',
              type: 'error'
            })
          }
          this.showAlert = false
          return false
        }

        return true
      },
      cancelEventOnFirstRows (evt) {
        if (evt.draggedContext.element.type === 'circular' ||
          evt.draggedContext.element.type === 'cards' ||
          evt.draggedContext.futureIndex === 0 ||
          evt.draggedContext.futureIndex === 1) {
          return false
        }
      },
      checkCard (evt, index) {
        let flag = false
        let validImgSize = this.validateImageOnDragAndDrop(evt)

        evt.relatedContext.list.forEach((item) => {
          if (item._id === evt.draggedContext.element._id &&
            evt.to.getAttribute('index') !== evt.from.getAttribute('index')) {
            flag = true
          }
        })

        if (flag || !validImgSize) {
          return false
        }
      },
      uploadAdsImage (event, targetWidth, targetHeight, targetProperty, rowIndex) {
        let input = event.target
        let regexImage = /\.(jpe?g|png)$/i

        if (targetProperty === 'overlay_img' || targetProperty === 'ipad_overlay_img') {
          regexImage = /\.(png)$/i
        }

        if (input.files && input.files[0]) {
          if (regexImage.test(input.files[0].name)) {
            let reader = new FileReader()
            reader.readAsDataURL(input.files[0])

            reader.onload = (e) => {
              const URL = window.URL || window.webkitURL
              const image = new Image()
              image.src = URL.createObjectURL(input.files[0])
              image.onload = () => {
                let height = image.height
                let width = image.width

                if (width !== targetWidth || height !== targetHeight) {
                  this.$notify({
                    group: 'notifications',
                    title: `Image size allowed is ${targetWidth}x${targetHeight}px :(`,
                    text: '',
                    type: 'error'
                  })

                  return false
                } else {
                  this.formData = new FormData()
                  this.formData.append('image', input.files[0])

                  this.$http.post('/locations/image',
                    this.formData,
                    {
                      headers:
                        {
                          'Content-Type': 'multipart/form-data'
                        }
                    }
                  )
                    .then((res) => {
                      if (res.success) {
                        this.location.rows[rowIndex][targetProperty] = res.data[0]

                        this.$notify({
                          group: 'notifications',
                          title: 'Image uploaded successfully :)',
                          text: '',
                          type: 'success'
                        })
                      } else {
                        this.$notify({
                          group: 'notifications',
                          title: `${res.message} :(`,
                          text: '',
                          type: 'error'
                        })
                      }

                      this.clearFormData(this.formData)
                    }).catch((err) => {
                      console.log(err)
                      this.clearFormData(this.formData)
                    })
                }
              }
            }
          } else {
            this.location.rows[rowIndex][targetProperty] = null

            if (targetProperty === 'overlay_img' || targetProperty === 'ipad_overlay_img') {
              return this.$notify({
                group: 'notifications',
                title: 'Image file should be PNG format :(',
                text: '',
                type: 'error'
              })
            }

            this.$notify({
              group: 'notifications',
              title: 'Image file should be JPG or PNG format :(',
              text: '',
              type: 'error'
            })
          }
        }
      },
      clearFormData () {
        this.formData = new FormData()
      },
      preview () {
        this.$refs.previewLocationModal.show();
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .card-area {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 20px 0 20px;
  }

  .cards-right-outer{
    height: -moz-calc(100vh - 285px);
    height: -webkit-calc(100vh - 285px);
    height: calc(100vh - 285px);
    overflow: scroll;
  }

  .cards-wrapper .card-area.cards-right{
    padding-bottom: 20px;
  }

  .card-wrapper{
    /* border:1px solid #eaeaea; */
    max-width: 32%;
    max-width: 31%;
    margin-right: 2%;
    font-size: 10px;
    font-weight: bold;
    align-self: start;
    background-color: #fff;
    cursor: pointer;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.20);
  }

  .card-wrapper .card-title{
    padding: 10px;
    margin-bottom: 0;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-wrapper .card-img img{
    max-width: 100%
  }

  .location-area-outer{
    height: -moz-calc(100vh - 130px);
    height: -webkit-calc(100vh - 130px);
    height: calc(100vh - 130px);
    overflow: scroll;
    padding: 20px 10px 20px 3px
  }

  .row-wrapper{
    padding: 15px 0 10px;
    margin-bottom: 40px;
    position: relative;
    background-color: #ffffff;
    min-height: 80px;
    border: 1px solid #eaeaea;
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.20) */
  }

  .row-wrapper:last-child{
    margin-bottom: 20px;
  }

  .row-title {
    position: absolute;
    top: -15px;
    left: 15px;
    background-color: #fff;
    padding: 5px 20px;
    border: 1px solid #eaeaea;
    font-weight: bold;
    font-size: 12px;
  }

  .row-wrapper .card-area {
    height: auto;
    box-shadow: none;
    min-height: 80px;
    width: 100%;
  }

  .row-wrapper .card-area.ads-section {
    padding: 0;
    background-color: #f7f7f7;
    margin-top: 20px;
    border: 1px dashed #afafaf;
  }

  .row-wrapper .card-area.ads-section  .message {
    margin-top: 25px;
    margin-left: 25px;
  }

  .row-wrapper .card-area.ads-section .card-wrapper {
    margin: 0;
  }

  .row-wrapper .card-area .card-wrapper {
    width: 100px;
    margin-bottom: 10px;
    margin-right: 5px;
    position: relative;
    box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.20);
  }

  .row-wrapper .card-area .card-wrapper.featured{
    width: 135px
  }

  .row-type{
    position: absolute;
    top: -15px;
    right: 10px;
    background: #fff;
    border: 1px solid #eaeaea;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    text-transform: capitalize;
    width: 30px;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .row-type img{
    width: 100%;
  }

  .remove-row{
    position: absolute;
    right: 10px;
    bottom: 5px;
    text-align: center;
    line-height: 20px;
  }

  .remove-row.remove-row-card{
    top: -10px;
    right: -10px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    z-index: 1;
    display: none;
    background: #fff;
    border: 1px solid #eaeaea;
  }

  .duplicate-card {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    padding: 5px 0;
    background-color: rgba(242, 73, 74, .8);
    color: #fff;
    font-size: 12px;
  }

  .row-wrapper .card-area .card-wrapper:hover .remove-row-card,
  .row-wrapper .card-area .card-wrapper:hover .duplicate-card{
    display: block
  }

  .row-wrapper .card-area .card-wrapper .duplicate-card:hover {
    background-color: rgba(242, 73, 74, 1);
  }

  .header-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  .header-wrapper .title{
    margin-bottom: 0;
  }

  .header-wrapper .btn-group {
    display: flex;
  }

  .header-wrapper .update-location, .header-wrapper .preview-location {
    width: 150px;
    line-height: 45px;
    text-align: center;
    background: #f2494a;
    margin-left: 15px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
  }

  .controls-wrapper{
    justify-content: center;
  }

  .controls-wrapper ul{
    flex: 1;
    border: none;
  }

  .controls-wrapper ul li{
    text-align: center;
    width: 100%;
    border: none;
    font-weight: bold;
    padding: 10px 30px;
    background-color: #eaeaea;
  }

  .controls-wrapper ul li{
    border-right: 1px solid #fff;
  }

  .controls-wrapper ul li:last-child{
    border-right: none;
  }

  .controls-wrapper ul li.active{
    background-color: #fff;
    border: none;
  }

  .modal-wrapper label {
    font-weight: bold;
  }

  .modal-wrapper ul {
    margin-left: 2em;
  }

  .layout-picker{
    background-color: #fff;
    border: 1px solid #eaeaea;
  }

  .layout-wrapper{
    min-width: 80px;
    min-height: 80px;
    text-align: center;
    line-height: 80px;
    background-color: #eaeaea;
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }

  .cards-wrapper {
    padding: 15px 20px;
  }

  .cards-wrapper .card-type {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .cards-wrapper .card-type .filterBy{
    font-size: 12px;
    font-weight: bold;
    margin-right: 15px;
    text-transform: uppercase;
  }

  .cards-wrapper .card-type select, .cards-wrapper .card-type input {
    height: 45px;
    border-radius: 0;
    border: 1px solid #eaeaea;
  }

  .search-wrapper {
    display: flex;
    flex: 1;
    margin-bottom: 10px;
  }

  .search-wrapper input{
    flex: 1;
    padding: 0 10px;
  }

  .search-wrapper .btn-search-card {
    height: 45px;
    background: #f2494a;
    border-radius: 0;
    color: #fff;
    line-height: 32px
  }

  .cards-wrapper .card-type select {
    min-width: 110px;
    flex: 1;
    margin-right: 10px;
  }

  .cards-wrapper .card-type select:last-child {
    margin-right: 0;
  }

  .cards-wrapper .card-type input {
    padding: 0 15px;
    margin-right: 0;
    width: 100%;
  }

  .cards-wrapper .card-area{
    padding: 0;
  }

  .meta-wrapper{
    height: -moz-calc(100vh - 186px);
    height: -webkit-calc(100vh - 186px);
    height: calc(100vh - 186px);
    overflow: scroll;
    padding: 15px 20px;
  }

  .bootstrap-form label {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .note {
    font-size: 10px;
    font-weight: bold;
    margin-top: -10px;
    color: #f2494a;
    margin-bottom: 10px;
  }

  .add-row {
    width: 100%;
    background-color: #eaeaea;
    box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.20);
    text-align: center;
    height: 60px;
    line-height: 60px;
    font-weight: bold;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
  }

  .published-builder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .published-builder span {
    font-size: 12px;
    font-weight: bold;
  }

  .published-builder label {
    margin-left: 20px;
    margin-bottom: 0 !important;
  }

  .duplicate-row-locations p {
    margin-bottom: 10px;
  }

  .duplicate-row-locations ul {
    list-style-type: none;
  }

  .duplicate-row-locations ul li {
    cursor: pointer;
    font-weight: bold;
    background-color: #ffffff;
    padding: 1em;
    margin: 10px 0;
    border: 1px solid #d4d4d4;
  }

  .duplicate-row-locations ul li.selected {
    background-color: #bcf7bc;
  }

  .top20-row, .ads-row {
    padding: 20px;
  }
  .top20-backup-image{
    max-width: 500px;
  }
  .ads-form img {
    max-width: 100%;
    height: 200px;
  }

  .ads-image-wrapper {
    padding: 5px;
    border: 1px solid #e8e8e8;
    background-color: #e8e8e8;
  }

  .ads-label {
    font-size: 12px;
  }


</style>

<style>
  .datetime-label {
    margin-top: 10px;
    font-weight: bold;
    font-size: 12px;
  }

  .vdatetime-input {
    width: 100%;
    height: 45px;
    padding: 10px;
    cursor: pointer;
  }
</style>
