// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import { store } from './store/store'
import router from './router'
import {BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSanitize from 'vue-sanitize'

import { SliderPlugin } from '@syncfusion/ej2-vue-inputs'
import '../node_modules/@syncfusion/ej2-base/styles/material.css'
import '../node_modules/@syncfusion/ej2-buttons/styles/material.css'
import '../node_modules/@syncfusion/ej2-popups/styles/material.css'
import '../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css'

import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import 'videojs-contrib-hls/dist/videojs-contrib-hls'

import Datetime from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

import Http from './components/share/http.js'
import Notifications from 'vue-notification'

import VueMask from 'v-mask'
import utilsMixing from './mixins/utils'
import datetimeMixing from './mixins/date-time'

import { VueSelect } from 'vue-select'

Vue.use(VueSanitize)
Vue.use(Datetime)
Vue.use(VueVideoPlayer)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Notifications)
Vue.use(SliderPlugin)
Vue.use(VueMask)
Vue.config.productionTip = false

Vue.prototype.$apiUrl = process.env.VUE_APP_ROOT_API

// Todo move to a global file for events
class EventHandler {
  constructor () {
    this.vue = new Vue()
  }

  emit (event, data) {
    this.vue.$emit(event, data)
  }

  listen (event, callback) {
    this.vue.$on(event, callback)
  }
}

window.EventHandler = new EventHandler()

Vue.prototype.$http = new Http()

Vue.mixin(utilsMixing)
Vue.mixin(datetimeMixing)
Vue.component('v-select', VueSelect)

/* eslint-disable no-new */
const vueApp = new Vue({
  el: '#app',
  store,
  router,
  components: { App },
  template: '<App/>'
})

window.oauth2 = () => {
  const vendor = 'simplecast'
  vueApp.$http.get(`/oauth2/${vendor}`).then((response) => {
    if (!response.success) {
      console.log(response.message)

      return
    }

    document.location.href = response.data.url
  })
}
