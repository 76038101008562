<template>
    <div className="shows-component">
      <router-view :key="$route.fullPath"/>
    </div>
  </template>
  
  
  <script>

  
  export default {
    name: 'moments'
  }
  </script>
  