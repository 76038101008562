<template>
    <div class="modal-wrapper">
        <b-row>
            <b-col cols="6">
                <b-form-group
                id="scene-title-en-label"
                label="*English Title:"
                label-for="scene-title-en"
                >
                    <b-form-input id="scene-title-en" v-model="episode.title.en" placeholder="Enter A title for the episode"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group
                id="scene-title-es-label"
                label="*Spanish Title:"
                label-for="scene-title-es"
                >
                    <b-form-input id="scene-title-es" v-model="episode.title.es" placeholder="Enter A title for the episode"></b-form-input>
                </b-form-group>
            </b-col>


            <b-col cols="6">
                <b-form-group
                id="scene-desc-en-label"
                label="*English Description:"
                label-for="scene-desc-en"
                >
                    <b-form-textarea
                    id="scene-desc-en"
                    v-model="episode.description.en"
                    rows="4"
                    max-rows="6"
                    ></b-form-textarea>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group
                id="scene-desc-es-label"
                label="*Spanish Description:"
                label-for="scene-desc-es"
                >
                    <b-form-textarea
                    id="scene-desc-es"
                    v-model="episode.description.es"
                    rows="4"
                    max-rows="6"
                    ></b-form-textarea>
                </b-form-group>
            </b-col>

            

            <b-col cols="6">
                <b-form-checkbox v-model="episode.published" switch>
                    Published
                </b-form-checkbox>
            </b-col>

            <b-col cols="6">
                <b-form-checkbox v-model="episode.disable_ads" switch>
                    Disable Ads
                </b-form-checkbox>
            </b-col>
        </b-row>
        <hr/>

        <!-- Video Selection Section -->
        <b-row>
            <b-col cols="12">
                <div class="video-labels">Landscape video</div>
            </b-col>
            <b-col cols="8" v-if="player.sources[0].src">

                <div >
                <video-player class="video-player-box"
                                ref="videoPlayer"
                                :options="player"
                                @loadeddata="onPlayerLoadeddata($event)"
                                :playsinline="true">
                </video-player>
                </div>

            </b-col>

            <b-col cols="4">

                <b-button
                class="m-2"
                @click="$bvModal.show('assetVideosModal')"
                >Choose a landscape video
                </b-button>

                <b-btn v-if="
                            episode &&
                            episode.video &&
                            episode.video.ingestLocation"
                        @click="downloadVideo"
                        class="m-2">
                Download Landscape Video
                </b-btn>
            </b-col>
        </b-row>
        <hr/>
        
        <!-- Image Selection Section -->
        <b-row>
            <b-col cols="12">
                <div class="video-labels">Episode Images</div>
            </b-col>

            <b-col sm="12" md="4" v-for="options in episodeImageOptions" v-bind:key="options.platform">
                <!-- landscape -->
                <!-- 1280x720 -->
                <upload @imgUploaded="imgUploaded" :data="options"></upload>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" class="d-flex justify-content-center">
                <div class="btn btn-danger" @click="updateEpisode">Save</div>
            </b-col>
        </b-row>

        <b-modal id="assetVideosModal" ref="assetVideosModal" title="Video Files Manager" size="xl" :hide-footer="true"  :no-close-on-backdrop="true" :no-close-on-esc="true">
            <div class="modal-wrapper">
            <VideoEpisodes v-on:videoSelected="handleSelected($event)"></VideoEpisodes>
            </div>
        </b-modal>
    </div> 
</template>

<script>
import VideoEpisodes from '../../assets/postcasts/VideoEpisodes.vue';
import ShowEpisodeImageUploader from './ImageUploader.vue';
import upload from '../../../share/UploadImage'
import ShowValidation from '../Validation'

export default {
    name:'ShowEpisodesForm',
    components:{
        VideoEpisodes,
        upload,
        ShowEpisodeImageUploader
    },
    props:['episodeId'],
    mounted(){
        this.getEpisode()
    },
    data(){
        return {
            episode: {},
            player: {
                language: 'en',
                responsive:true,
                sources: [{
                    type: 'application/x-mpegURL',
                    src: null // this.video.file.portrait.m3u8
                }],
                fluid: true
            },
            newVideoSelected:false,
            episodeImageOptions: {
                landscape: {
                    label: 'landscape - 1920x850px',
                    description: 'Upload a 1920x850 image',
                    // platform: 'landscape',
                    isLoading: false,
                    width: 1920,
                    height: 850,
                    isDimensionVariable : false,
                    url: '',
                    uploadURL: '/show-episodes/image?imgKey=landscape'
                },
                portrait: {
                    label: 'portrait - 1080x1920px',
                    description: 'Upload a 1080x1920 image',
                    // platform: 'portrait',
                    isLoading: false,
                    width: 1080,
                    height: 1920,
                    isDimensionVariable : false,
                    url: '',
                    uploadURL: '/show-episodes/image?imgKey=portrait'
                },
                square: {
                    label: 'square - 1440x1440px',
                    description: 'Upload a 1440x1440 image',
                    // platform: 'square',
                    isLoading: false,
                    width: 1440,
                    height: 1440,
                    isDimensionVariable : false,
                    url: '',
                    uploadURL: '/show-episodes/image?imgKey=square'
                },
            }
        }
    },
    methods:{
        downloadVideo(){
            const originalURL = this.scene.video.ingestLocation
            const url = originalURL.replace(/^.*\/video-incoming\//g, 'https://manifest.lamusica.com/cms-incoming/')
            window.open(url, 'blank')
        },
        imgUploaded (formData, data) {
            const serverResponseData = data.data
            this.image = formData
            this.episodeImageOptions[serverResponseData.imgKey].isLoading = false

            this.episodeImageOptions[serverResponseData.imgKey]['url'] = serverResponseData.imageUrl
            this.episode.images[serverResponseData.imgKey] = serverResponseData.imageUrl

        },
        handleSelected (value) {
            const hlsUrl = `${value.video_hls_url}`
            this.player.sources[0].src = hlsUrl
            this.episode.video = {
                url: hlsUrl,
                id: value.id,
                ingestLocation: value.file_url
            }
            this.newVideoSelected = true
            this.$bvModal.hide('assetVideosModal')
        },
        onPlayerLoadeddata(player){
            if(this.newVideoSelected){
                this.episode.duration = parseInt(player.duration())
            }
            
        },
        async getEpisode(){
            const sceneRes = await this.$http.get(`/show-episodes/${this.episodeId}`)
            this.episode = sceneRes.data

            if(this.episode.video && this.episode.video.url && this.episode.video.url!==''){
                this.player.sources[0].src = this.episode.video.url
            }
            this.getShowData()
        },
        setEpisodeImages(showData){
            /**
             * If image is set to blank, use default images from show
             * otherwise use the image url from database
             */
            if(this.episode.images){
                if(this.episode.images.landscape===''){
                    this.episodeImageOptions['landscape']['url']= showData.landing['2xImageURL']
                    this.episode.images.landscape = showData.landing['2xImageURL']
                }else{
                    this.episodeImageOptions['landscape']['url']= this.episode.images.landscape
                }
                if(this.episode.images.portrait===''){
                    this.episodeImageOptions['portrait']['url']= showData.portrait['2xImageURL']
                    this.episode.images.portrait= showData.portrait['2xImageURL']
                }else{
                    this.episodeImageOptions['portrait']['url']= this.episode.images.portrait
                }
                if(this.episode.images.square===''){
                    this.episodeImageOptions['square']['url']= showData.detailViewImagery['2xImageURL']
                    this.episode.images.square= showData.detailViewImagery['2xImageURL']
                }else{
                    this.episodeImageOptions['square']['url']= this.episode.images.square
                }
            }
        },
        async getShowData(){
            const showRes = await this.$http.get(`/shows/${this.episode.show_id}`)
            const showMeta = showRes.data.meta
            this.setEpisodeImages(showMeta)
        },
        async updateEpisode(){
            const action = this.episode.published
            //being published
            if(action){
                // validate inputs
                const metadataIsValid = ShowValidation.validationForPublishedShowEpisodes(this.episode)
                
                if(!metadataIsValid){
                this.$notify({
                    group: 'notifications',
                    title: 'Incomplete Metadata',
                    text: 'All information is required for a published episode.',
                    type: 'error'
                })
                return
                }
            }else{
                // remove from video section
                const isRemoved = await this.$http.put(`/video-section/remove-resource/show-episode/${this.episode._id}`)
                
                if(!isRemoved.success){
                    this.$notify({
                        group: 'notifications',
                        title: 'Cannot unpublish show',
                        text: 'Failed to remove show from video section',
                        type: 'error'
                    })
                    return false
                }
            }

            const epRes = await this.$http.put(`/show-episodes/${this.episodeId}`, {episode: this.episode})
            if(epRes.success){
                this.$notify({
                    group: 'notifications',
                    title: 'Episode updated successfully :)',
                    text: '',
                    type: 'success'
                })
            }else{
                this.$notify({
                    group: 'notifications',
                    title: 'Failed to updated episode :)',
                    text: '',
                    type: 'error'
                })
            }
        },
    }
}
</script>