<template>
  <div>
    <b-form-row align-h="end" v-if="can_publish">
      <b-col>
        <div class="status-wrapper">
          <div class="video-labels-left">Long Form</div>
          <label class="switch">
            <input type="checkbox" v-model="video.long_format">
            <span class="slider"></span>
          </label>
        </div>
      </b-col>
      <b-col style="text-align: right;">
        <div class="status-wrapper">
          <div class="video-labels">Published / Status</div>
          <label class="switch">
            <input :disabled="isNewVideo" type="checkbox" v-model="video.published">
            <span class="slider"></span>
          </label>
        </div>
      </b-col>
    </b-form-row>
    <hr/>
    <b-form-row>
      <b-col>
        <div class="video-labels">Title Spanish <strong class='required'>*</strong></div>
        <b-form-group>
          <b-form-input id="titleSpanish"
                        type="text"
                        required
                        v-model="video.title.es"
                        placeholder="Add spanish text...">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <div class="video-labels">Title English <strong class='required'>*</strong></div>
        <b-form-group>
          <b-form-input id="titleEnglish"
                        type="text"
                        required
                        v-model="video.title.en"
                        placeholder="Add english text...">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <hr/>

    <b-form-row>
      <b-col>
        <div class="video-labels">Card Row Title Spanish <br /><strong class='required'>* (Required to publish)</strong></div>
        <b-form-group>
          <b-form-text id="inputLiveHelp">Limited to 35 characters</b-form-text>
          <b-input-group :append="(maxCharacterLength - video.cardTitle.es.length).toString()">
            <b-form-input id="cardTitleSpanish"
                          type="text"
                          :required="video.published"
                          :maxlength="maxCharacterLength"
                          v-model="video.cardTitle.es"
                          placeholder="Add spanish text...">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col>
        <div class="video-labels">Card Row Title English <br/><strong class='required'>* (Required to publish)</strong></div>
        <b-form-group>
          <b-form-text id="inputLiveHelp">Limited to 35 characters</b-form-text>
          <b-input-group :append="(maxCharacterLength - video.cardTitle.en.length).toString()">
            <b-form-input id="cardTitleEnglish"
                          type="text"
                          :required="video.published"
                          :maxlength="maxCharacterLength"
                          v-model="video.cardTitle.en"
                          placeholder="Add english text...">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>

    <hr v-if="video.sliderTitle" />

    <b-form-row>
      <b-col>
        <div class="video-labels">Slug <br/><strong class='required'>* (Required to publish)</strong></div>
        <b-form-group class="slug-creation">
          <b-form-text id="inputLiveHelp">This slug is to uniquely identify, via a pretty URL, a piece of content on LaMusica web. It must be written in English. Once it has been defined it should not change, so that users do not get sent to missing pages</b-form-text>
          <b-input-group prepend="https://www.lamusica.com/videos/">
            <b-form-input id="slug"
                          type="text"
                          :required="video.published"
                          v-model="video.slug">
            </b-form-input>
            <b-input-group-append>
              <b-btn class="typeahead-btn" id="generateSlug" variant="outline-success" @click="generateSlug()">Generate Slug</b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>

    <hr/>

    <b-form-row>
      <b-col sm="12" md="6">
        <div class="video-labels">Video Description (Español) <br/><strong class='required'>* (Required to publish)</strong></div>
        <b-form-group>
          <b-form-textarea id="videoDescriptionSpanish"
                           v-model="video.description.es"
                           placeholder="Add Spanish Text..."
                           :required="video.published"
                           :rows="4"
                           :max-rows="4">
          </b-form-textarea>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6">
        <div class="video-labels">Video Description (English) <br/><strong class='required'>* (Required to publish)</strong></div>
        <b-form-group>
          <b-form-textarea id="videoDescriptionEnglish"
                           v-model="video.description.en"
                           placeholder="Add English Text..."
                           :required="video.published"
                           :rows="4"
                           :max-rows="4">
          </b-form-textarea>
        </b-form-group>
      </b-col>
    </b-form-row>

    <hr/>

    <b-form-row>
      <div class="video-labels">Geographic Targeting</div>
      <b-form-checkbox-group id="geographicTargeting" name="geographicTargeting" v-model="video.regions" :options="locations">
      </b-form-checkbox-group>
    </b-form-row>

    <hr/>

    <b-form-row>
      <div class="video-labels">Origin Type<br/><strong class='required'>* (Required to publish)</strong></div>
      <b-form-radio-group
        id="videoOriginType"
        name="videoOriginType"
        v-model="video.origin"
        :options="videoOriginType"
        :required="video.published">
      </b-form-radio-group>
    </b-form-row>
    <hr/>

    <b-form-row>
      <div class="video-labels">Primary Category <br/><strong class='required'>* (Required to publish)</strong></div>
      <b-form-radio-group
        id="videoPrimaryCategories"
        name="videoPrimaryCategories"
        v-model="video.categories.primary"
        :options="videoPrimaryCategories"
        :required="video.published">
      </b-form-radio-group>
    </b-form-row>

    <hr/>

    <b-form-row>
      <div class="video-labels">Secondary Categories</div>
      <b-form-checkbox-group id="videoSecondaryCategories" name="videoSecondaryCategories" v-model="video.categories.secondary" :options="videoSecondaryCategories">
      </b-form-checkbox-group>
    </b-form-row>

    <hr/>

    <b-form-row>
      <div class="video-labels">Video Tags</div>
      <b-button variant="primary" class="tags-btn" v-for="(tag, index) in video.tags" v-bind:key="index">
        {{tag.text}} <b-badge variant="light" @click="removeTag(index)">x</b-badge>
      </b-button>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group class="geographic-targeting">
          <b-input-group>
            <b-form-input id="cardTitleSpanish"
                          type="text"
                          v-model="newTag"
                          placeholder="Add tags..."
                          @keyup.native="searchTag"
                          @focus.native="showTagListResult = true; tagList = []"
                          @blur.native="hideTagListResult()"
                          autocomplete="off"
            >
            </b-form-input>
            <b-input-group-append>
              <b-btn class="typeahead-btn" variant="outline-success" @click="addTag(null)">Add New Tag</b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <ul v-show="tagList.length > 0 && showTagListResult" class="searchTagsResults">
          <li v-for="tag in tagList" v-bind:key="tag._id" @click="addTag(tag)">
            {{tag.text}}
          </li>
        </ul>
      </b-col>
    </b-form-row>

    <hr/>

    <AddArtistsModal :artists="video.artists" />
    <hr/>

    <b-form-row>
      <div class="video-labels">Genres</div>
      <b-form-checkbox-group id="genresList" name="genresList" v-model="video.genres" :options="genres">
      </b-form-checkbox-group>
    </b-form-row>

    <hr/>

    <b-form-row>
      <div class="video-labels">Show Association</div>
      <b-form-select v-model="video.show_id" :options="showOptions"></b-form-select>
    </b-form-row>

    <hr/>

    <b-form-row>
      <div class="video-labels">Video Content Assets</div>
      <p>
        Upload in the following areas the portrait (and optionally horizontal) content piece & preview files. Please upload one file at a time until getting confirmation that the video is being uploaded. The videos should contain the following technical specifications:
      </p>
      <ul class="video-validations">
        <li class="text-muted">
          1. 1080x1920 (or 1920x1080) resolution, taking into consideration the
          <a href="/upload/guides/video-thumbnail-template.psd" target="_blank">safety zones layout</a>
        </li>
        <li class="text-muted">2. The required export codec is H.264 AAC with a 29.97fps consistent frame rate and a <code>.mov</code> or <code>.mp4</code> file extension</li>
        <li class="text-muted">3. If the video is landscape, the filename must contain <code>HORIZONTAL</code> in the file name.</li>
        <li class="text-muted">4. If the video is a portrait preview that it contain <code>_PREVIEW.mp4</code> or <code>_PREVIEW.mov</code> at the end of the file name.</li>
        <li class="text-muted">5. Please upload one video at a time (wait until the green prompt to reupload next video).</li>
      </ul>
    </b-form-row>

    <b-form-row>
      <b-col sm="12" md="5">
        <div class="video-labels">Portrait Video</div>
        <b-button
          :disabled="isNewVideo"
          @click="showAssetVideosModal('portrait')"
        >Choose a portrait video</b-button>
        <div class="container-ratio" v-if="player.portraitOptions.sources[0].src && !isLoadingPortrait">
          <video-player class="video-player-box"
                        ref="videoPlayer"
                        :options="player.portraitOptions"
                        :playsinline="true">
          </video-player>
        </div>

        <button v-if="video &&
                      video.file &&
                      video.file.portrait &&
                      video.file.portrait.ingestLocation"
                @click="downloadPortraitVideo" class="download-video">Download Portrait Video</button>
      </b-col>

      <b-col sm="12" md="7">
        <div class="video-labels">Landscape video</div>
        <b-button
          :disabled="isNewVideo"
          @click="showAssetVideosModal('landscape')"
        >Choose a landscape video</b-button>
        <div class="container-ratio" v-if="player.landscapeOptions.sources[0].src && !isLoadingLandscape">
          <video-player class="video-player-box"
                        ref="videoPlayer"
                        :options="player.landscapeOptions"
                        :playsinline="true">
          </video-player>
        </div>

        <button v-if="
                      video &&
                      video.file &&
                      video.file.landscape &&
                      video.file.landscape.ingestLocation"
                @click="downloadLandscapeVideo"
                class="download-video">
          Download Landscape Video
        </button>
      </b-col>
    </b-form-row>

    <hr/>
    <b-form-row v-for="imageSize in imageSizes" :key="imageSize.videoKey">
      <videos-image-uploader
        :image-size="imageSize"
        :content="video"
        api-u-r-l="/videos/images" />
    </b-form-row>
    <hr/>

    <b-form-row>
      <b-col>
        <div class="video-labels">Homepage Animated Card</div>
        <b-form-text id="inputLiveHelp">
          Click here to add/change the video used in the LaMusica cards available on the Homepage. sized at 1000x1100.
        </b-form-text>
        <b-form-file accept="video/mp4" ref="videoInput" v-model="card.file1000" :placeholder="card.placeholder1000" :state="!!video.videoCardUrl" @change.native="uploadVideoCard($event, card)"></b-form-file>
        <div class="video-holder-card">
          <div class="remove-video-card" v-if="video.videoCardUrl" @click="removeVideoCard">
            <i class="fas fa-times"></i>
          </div>
          <div class="card-image" v-if="video.videoCardUrl">
            <video controls><source :src="video.videoCardUrl" type="video/mp4"></video>
          </div>
        </div>
      </b-col>
    </b-form-row>

    <hr/>

    <b-form-row class="justify-content-md-center">
      <b-col sm="12" md="4">
        <div class="video-labels">Start date</div>
        <datetime type="datetime"
                  v-model="video.available.start"
                  class="theme-orange"
                  :minDatetime="minDatetime"
                  :phrases="{ok: 'Continue', cancel: 'Exit'}"
                  use12-hour>
        </datetime>
      </b-col>

      <b-col sm="12" md="4">
        <div class="video-labels">End date</div>
        <datetime type="datetime"
                  v-model="video.available.end"
                  class="theme-orange"
                  :minDatetime="minDatetime"
                  :phrases="{ok: 'Continue', cancel: 'Exit'}"
                  use12-hour>
        </datetime>
      </b-col>
    </b-form-row>
    <AdConfigurationForm :item="video" :has-web-display-ads="true"/>
    <b-modal id="assetVideosModal" ref="assetVideosModal" title="Video Files Manager" size="xl" :hide-footer="true"  :no-close-on-backdrop="true" :no-close-on-esc="true">
      <div class="modal-wrapper">
        <assets-videos :orientation="currentOrientation" v-on:videoSelected="handleSelected($event)"></assets-videos>
      </div>
    </b-modal>

  </div>
</template>

<script>
import axios from 'axios'
import LoaderInline from '../../share/LoaderInline'
import AdConfigurationForm from '../../share/AdConfigurationForm'
import {DateTime as LuxonDateTime} from 'luxon'
import VideosCommon from './VideosCommon'
import AddArtistsModal from './AddArtistsModal'
import VideosImageUploader from "./VideosImageUploader"
import AssetsVideos from '../assets/videos/AssetsVideos'

export default {
  name: 'VideosForm',
  components: {
    AssetsVideos,
    VideosImageUploader,
    LoaderInline,
    AdConfigurationForm,
    AddArtistsModal
  },
  data () {
    return {
      maxCharacterLength: 35,
      minDatetime: LuxonDateTime.local().startOf('day').toISO(),
      formData: new FormData(),
      locations: [],
      videoPrimaryCategories: [],
      videoSecondaryCategories: [],
      videoOriginType: [],
      genres: [],
      tagList: [],
      imageSizes: VideosCommon.getImageSizesState(),
      player: {
        portraitOptions: {
          language: 'en',
          responsive: true,
          sources: [{
            type: 'application/x-mpegURL',
            src: this.video.file.portrait.m3u8 || null
          }],
          fluid: true
        },
        landscapeOptions: {
          language: 'en',
          responsive: true,
          sources: [{
            type: 'application/x-mpegURL',
            src: this.video.file.landscape.m3u8 || null
          }],
          fluid: true
        }
      },
      newTag: '',
      artistImage: null,
      isLoadingImage: false,
      videoPortrait: null,
      isLoadingPortrait: false,
      videoPortraitProgress: 0,
      videoLandscape: null,
      videoLandscapeProgress: 0,
      isLoadingLandscape: false,
      portraitInterval: null,
      landscapeInterval: null,
      videoFileNamePortrait: null,
      videoFileNameLandscape: null,
      videoFileName: null,
      uploadPercentage: 0,
      card: {
        file1000: null,
        html: null,
        '1000x1100': null,
        placeholder1000: 'Homepage animated card here...',
        width1000: 1000,
        height1000: 1100,
        state: false
      },
      currentOrientation: '',
      currentMediastreamId: '',
      showOptions:[]
    }
  },
  computed: {
    isNewVideo: function () {
      if(this.video._id) return false
      else return true
    },
    can_publish: function () {
      return localStorage.getItem('videoPermission') !== ''
    },
    videoPortraitState: function () {
      return this.video.file.portrait && this.video.file.portrait.m3u8 && this.video.file.portrait.m3u8 !== ''
    },
    videoLandscapeState: function () {
      return this.video.file.landscape && this.video.file.landscape.m3u8 && this.video.file.landscape.m3u8 !== ''
    }
  },
  props: {
    video: {
      type: Object,
      required: true
    }
  },
  mounted () {
    axios.all([this.getCategories(), this.getLocations(), this.getGenres(), this.getOrigins(), this.getShows()])
      .then(
        axios.spread((categories, locations, genres, origins, shows) => {
          this.videoPrimaryCategories = categories.data.map(cat => {
            return {
              text: cat.title,
              value: cat._id
            }
          })

          this.videoSecondaryCategories = categories.data.map(cat => {
            return {
              text: cat.title,
              value: cat._id
            }
          })

          this.locations = locations.data.map(loc => {
            return {
              text: loc.regionName,
              value: loc._id
            }
          })

          this.videoOriginType = origins.stations.map( st =>{
            return {
              text: `${st.title}(${st.city})`,
              value: st.callSign
            }
          } )

          this.videoOriginType.unshift({
              text: 'Original',
              value: 'original'
            })

          if(shows){
            this.showOptions = shows.data.map(show => {
              return {
                text: show.meta.titleEnglish,
                value: show._id
              }
            })

            this.showOptions.unshift({
              text:'Not Assigned',
              value:''
            })
          }
          // if(this.video.show_id){
          //   this.showSelection = 
          // }
          
          
          if (genres.data && genres.data[0]) {
            this.genres = genres.data[0].playlists.map(genre => {
              return {
                text: genre.playlist.title,
                value: genre.playlist._id
              }
            }).sort((a, b) => a.text.localeCompare(b.text))
          }

          for (let imageSize of this.imageSizes) {
            const videoImagesForKey = this.video.images[imageSize.videoKey]
            if (videoImagesForKey && Object.keys(videoImagesForKey).length > 0) {
              imageSize.valid = true
            }
          }
        })
      )
  },
  methods: {
    getOrigins () {
      return this.$http.get(`/videos/origins`)
    },
    getCategories () {
      return this.$http.get(`/videos/categories`)
    },
    getLocations () {
      return this.$http.get('/videos/locations')
    },
    getGenres () {
      return this.$http.get('/videos/genres')
    },
    getShows () {
      return this.$http.get('/videos/shows')
    },
    generateSlug () {
      if (!this.video.title.en || this.video.title.en === '') {
        this.$notify({
          group: 'notifications',
          title: 'Title English is required in order to generate an slug',
          text: '',
          type: 'error'
        })
        return
      }
      this.$http.post('/videos/slug', {
        string: this.video.title.en,
        id: this.video._id
      }).then((response) => {
        if (response.is_unique) {
          this.$notify({
            group: 'notifications',
            title: 'Unique slug generated successfully',
            text: '',
            type: 'success'
          })
          this.video.slug = response.slug
        } else {
          this.video.slug = ''
          this.$notify({
            group: 'notifications',
            title: 'Another video already has the same generated slug, please write your own',
            text: '',
            type: 'error'
          })
        }
      })
    },
    searchTag (event) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (event.target.value === '') {
          this.tagList = []
          return
        }
        this.$http.get(`/videos/tags?q=${event.target.value}`)
          .then((res) => {
            if (res.data.length === 0) {
              this.isLoading = false
              this.tagList = []
              return
            }

            this.tagList = res.data
            this.showTagListResult = true
          })
          .catch((err) => {
            console.log(err)
          })
      }, 250)
    },
    addTag (tag) {
      // Selected a pre-existing tag
      if (tag && tag._id) {
        this.video.tags.push(tag)
        return
      }

      // Clicked "add new tag" on a tag that exists already
      if (this.newTag && this.tagList.length > 0) {
        for (const tagItem of this.tagList) {
          if (tagItem.text === this.newTag) {
            this.video.tags.push({
              _id: tagItem.id,
              text: tagItem.text
            })
            return
          }
        }
      }

      // Add a new tag that never existed
      const params = {
        tag: {
          text: this.newTag
        }
      }

      const displayError = (e) => {
        console.log(e)
        this.$notify({
          group: 'notifications',
          title: 'Sorry, something went wrong when adding a tag :(',
          text: '',
          type: 'error'
        })
      }

      this.$http.post('/videos/tags', params).then((res) => {
        if (res.success) {
          this.$notify({
            group: 'notifications',
            title: `Tag "${params.tag.text}" created successfully :)`,
            text: '',
            type: 'success'
          })

          this.video.tags.push({
            _id: res.data.id,
            text: params.tag.text
          })
        } else {
          displayError(res)
        }
      }).catch((e) => {
        displayError(e)
      }).finally(() => {
        this.newTag = ''
      })
    },
    removeTag (index) {
      this.video.tags.splice(index, 1)
    },
    downloadOriginalCMEVideo (originalURL) {
      const url = originalURL.replace(/^.*\/video-incoming\//g, 'https://manifest.lamusica.com/cms-incoming/')
      window.open(url, 'blank')
    },
    downloadPortraitVideo () {
      this.downloadOriginalCMEVideo(this.video.file.portrait.ingestLocation)
    },
    downloadLandscapeVideo () {
      this.downloadOriginalCMEVideo(this.video.file.landscape.ingestLocation)
    },
    uploadVideoCard (event, video) {
      let input = event.target
      this.formData = new FormData()

      if (input.files && input.files[0]) {
        if (/\.(mp4)$/i.test(input.files[0].name)) {
          let fileSize = input.files[0].size / 1024 / 1024

          if (fileSize > 3) {
            this.$notify({
              group: 'notifications',
              title: 'Video File size exceeds 3 MB :)',
              text: '',
              type: 'error'
            })

            return
          }

          this.formData.append('file', input.files[0])

          this.$http.post('/videos/card', this.formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
              if (res.success) {
                const fileURL = res.data[0]
                this.card['1000x1100'] = fileURL
                this.card.html = `<video controls><source src="${fileURL}" type="video/mp4"></video>`
                this.card.state = true
                this.video.videoCardUrl = fileURL
              } else {
                this.$notify({
                  group: 'notifications',
                  title: 'Video file is required :)',
                  text: '',
                  type: 'error'
                })
                this.card.state = false
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          this.$notify({
            group: 'notifications',
            title: 'Video should be mp4 format :(',
            text: '',
            type: 'error'
          })
          this.card.state = false
        }
      }
    },
    removeVideoCard () {
      this.video.videoCardUrl = null
      this.card.html = null
      this.card.file1000 = null
      this.card['1000x1100'] = null
      this.card.placeholder1000 = 'Homepage animated card here...'
      this.card.state = false
      this.$refs.videoInput.reset()
    },
    hideTagListResult () {
      setTimeout(() => {
        this.showTagListResult = false
      }, 200)
    },
    clearFormData (videoType) {
      this.formData = new FormData()
      if (videoType === 'portrait') {
        this.isLoadingPortrait = false
        this.videoFileNamePortrait = null
      }
      if (videoType === 'landscape') {
        this.isLoadingLandscape = false
        this.videoFileNameLandscape = null
      }

      this.isLoadingImage = false
    },
    showAssetVideosModal (orientation, mediastreamId) {
      this.currentOrientation = orientation
      this.currentMediastreamId = mediastreamId
      this.$refs.assetVideosModal.show()
    },
    closeAssetVideosModal () {
      this.currentOrientation = ''
      this.$refs.assetVideosModal.hide()
    },
    handleSelected (value) {
      const hlsUrl = `${value.file_hls_url}?${new Date().getTime()}`

      this.video.isEncoding[this.currentOrientation] = false
      this.video.file[this.currentOrientation].ingestLocation = value.ingest_url
      this.video.file[this.currentOrientation].m3u8 = hlsUrl

      if (this.currentOrientation === 'landscape') {
        this.player.landscapeOptions.sources[0].src = hlsUrl
      } else {
        this.player.portraitOptions.sources[0].src = hlsUrl
      }

      this.closeAssetVideosModal()
    }
  }
}
</script>

<style lang="scss">
.video-js {
  width: 100%;
}

.vdp-datepicker__calendar {
  width: 100%;
}

.calendar-wrapper > div {
  padding: 10px;
}

.custom-control-inline {
  min-width: 24%;
  margin-right: 1%;
}

.videos-edit-component .custom-file-label {
  height: 60px;
}

.videos-edit-component .custom-file-label::after {
  display: none;
}

.videos-edit-component .custom-file input {
  height: 60px !important;
}

.videos-edit-component .custom-file input:hover {
  cursor: pointer;
}

.videos-edit-component .custom-file {
  height: 60px;
}

.videos-edit-component .required {
  color: #f2494a;
  font-size: 10px;
}

.videos-edit-component .required {
  color: #f2494a
}

.video-holder-card .card-image video {
  width: 100%;
  margin-bottom: 10px;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.videoEncodingMessage {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #f2494a;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  height: 175px;
}

.videoEncodingMessage.failed {
  height: auto;
}

.videoEncodingMessage .text {
  margin: 20px auto;
  color: #f2494a;
}
.page-header {
  padding: 30px 15px 20px;
}

.typeahead-wrapper {
  align-items: center;
}

.controls-wrapper ul {
  margin-bottom: 0 !important;
}

.status-wrapper {
  display: flex;
  align-items: center;
}

.switch {
  width: 53px;
}

.status-wrapper .video-labels {
  margin-right: 10px;
  padding: 0;
}

.video-labels-left{
  font-weight: bold;
  font-size: 14px;
  padding-right: 10px;
}

.videos-edit-component{
  display: block;
  width: 100%;
}

.videos-edit-component .header .title {
  margin-bottom: 0;
}

.videos-all-list .bootstrap-form small {
  margin-top: -3px;
  margin-bottom: 3px;
  font-size: 13px;
}

.input-group-text {
  height: 45px;
  font-size: 14px;
  font-weight: bold;
}

.btn-outline-success {
  font-size: 14px;
  text-align: center;
  background: #f2494a;
  color: #fff;
  text-transform: uppercase;
}

textarea {
  padding: 10px;
}

.geographic-targeting small{
  margin-bottom: 10px;
}

.tags-btn {
  width: auto;
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
}

.video-validations {
  list-style-type: none;
  margin: 10px 0;
}

.video-holder-card {
  max-width: 300px;
  margin-top: 10px;
}

.video-labels {
  padding: 10px 0;
  width: 100%;
  display: block;
  font-size: 14px;
  font-weight: bold;
}

.video-labels.asset-download {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.video-labels i {
  transition: all .2s;
  cursor: pointer;
  color: #8e8e8e;
}

.video-labels i:hover {
  color: #2c3e50;
}

.inputDescription {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.video-player {
  margin-top: 20px;
  text-align: center !important;
}

.loading {
  background-color: #fff;
  position: relative;
  margin-top: 20px;
}

.loadingPortrait {
  height: 700px;
}

.loadingLandscape {
  height: 400px;
}

#radiosGroup {
  width: 100%;
}

.subtitleDescription {
  width: 100%;
  margin-bottom: 10px;
}

ul.searchTagsResults {
  z-index: 3;
  position: absolute;
  top: 46px;
  /* right: 85px; */
  left: 5px;
  min-height: 40px;
  max-height: 200px;
  background-color: #fff;
  border: 1px solid #f7f7f7;
  list-style: none;
  overflow: scroll;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.175);
  box-shadow: 0 6px 12px rgba(0,0,0,0.175);
}

  ul.searchTagsResults li {
    padding: 10px 20px;
    cursor: pointer;
  }

  ul.searchTagsResults li:hover {
    color: #fff;
    background-color: #f45354;
  }

  .shadowHeader {
    -webkit-box-shadow: 0 8px 6px -6px rgba(0,0,0,0.19);
    -moz-box-shadow: 0 8px 6px -6px rgba(0,0,0,0.19);
    box-shadow: 0 8px 6px -6px rgba(0,0,0,0.19);
  }

  .loading-images {
    position: relative;
    height: 200px;
  }


  .inline-checkbox {
    display: inline;
    width: auto;
    height: auto !important;
  }

  .content-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .content-wrapper .progress-status {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #dc3545;
  }

  .content-wrapper .progress-status.complete {
    color: #7eaf12;
  }

  .content-wrapper .progress-status span {
    font-size: 15px;
  }

  .loading.isLoadingImage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    margin-top: 0;
  }

  @media (max-width: 768px) {
    .videos-edit-component .container {
      padding: 0;
    }

    .slug-creation .input-group-prepend{
      display: none;
    }

    .input-group-append button{
      font-size:12px;
    }

    .images-wrapper img {
      margin-bottom: 5px;
      max-width: 100%;
    }
  }

  .download-video {
    margin-top: 10px;
  }
</style>
