<template>
    <div>
      <div class="page-header">
        <div class="row align-items-center header">
          <div class="col-9">
            <div class="title">
              <router-link to="/admin/shows/episodes/">Episodes</router-link>/
              <span>New</span>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content">
        <div class="col-12">
          <div class="video-labels">Show Association</div>
          <p>Select a published show to which this episode belongs. If you select a wrong show, delete the episode and start over.</p>
          <b-form-select v-model="episode.show_id" :options="showOptions"></b-form-select>
          <b-btn :disabled="episode.show_id===''" class="my-2" @click="createShowEpisode">Create Episode</b-btn>
        </div>
      </div>
    </div>
</template>

<script>

export default {
    name:'ShowEpisodesNew',
    mounted(){
      this.getShows()
    },
    data(){
      return{
        showOptions:[],
        episode: {
          title: {
              en: '',
              es: ''
          },
          description:{
              en: '',
              es: ''
          },
          video:{},
          images:{
              landscape:'',
              portrait:'',
              square:''
          },
          type:'show-episode',
          show_id:'',
          disable_ads:false,
          published:false,
        },
      }
    },
    methods:{
      async createShowEpisode(){
        const showEpisodeRes = await this.$http.post('/show-episodes',  {scene: this.episode},)
        if(showEpisodeRes.success){
          const episodeId = showEpisodeRes.data.insertedId
          this.$router.push(`/admin/shows/episodes/edit/${episodeId}`)
        }
      },
      async getShows(){
        const showRes = await this.$http.get('/shows', {params: {limit: 200 }})
        this.showOptions = showRes.data.items.map( s => ({
          text: s.meta.titleEnglish,
          value: s._id
        }))
      }
    }
}
</script>

<style scoped>
.video-labels {
  padding: 10px 0;
  width: 100%;
  display: block;
  font-size: 14px;
  font-weight: bold;
}
</style>