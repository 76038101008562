class MomentValidations{
    
    metadataValidationForPublishedMoment(momentMetadata){
        return momentMetadata.title.en !== '' && momentMetadata.title.es !== '' && momentMetadata.slug !== '' &&
        momentMetadata.description.en !== '' && momentMetadata.description.es !== '' &&
        momentMetadata.image.url !== '' && momentMetadata.image.id !== '' &&
        momentMetadata.video.url !== '' && momentMetadata.video.id !== ''
    }

    metadataValidationForUnpublishedMoment(momentMetadata){
        return momentMetadata.title.en !== '' && momentMetadata.title.es !== '' && momentMetadata.slug !== ''
    }
}

module.exports = new MomentValidations()